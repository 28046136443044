const localization: any = {
  en: {
    TOP_BAR: {
      MY_CONVERSATIONS: 'My Conversations',
      VISITOR: 'Visitor'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'You currently have no widgets open.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'You currently have no conversations.'
    },
    NOTIFICATIONS: {
      VPN_DISCONNECTED_MESSAGE : 'You have been disconnected from VPN and as a result your active conversation has also been disconnected. You have been moved to the Away status of “PC Recovery“, please reconnect to VPN and set yourself to “Available” again before taking any new conversation or making outgoing call.',
      BLOCK_MANUAL_STATUS_CHANGE : 'You have been disconnected from VPN. Please reconnect to VPN and update your status to “Available” or “Outbound” before continuing to take new conversations or making outbound calls.',
      AUTHENTICATION_ERROR_TITLE: "Unable to Authenticate",
      AUTHENTICATION_ERROR_BODY: "This may be due Firefox Enhanced Protection being enabled or an issue communicating with Okta. Visit the troubleshooting wiki for more information: https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      RELEASE_NOTES : "Release Notes",
      WHAT_NEW : "What’s new:",
      TRANSCRIPT_COPIED : "Transcript copied to clipboard"
    }
  },
  ja: {
    TOP_BAR: {
      MY_CONVERSATIONS: '自分の会話',
      VISITOR: '訪問者'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: '現在、開いているウィジェットはありません。'
    },
    CCP_PANEL: {
      EMPTY_CHAT: '現在、会話はありません。'
    },
    NOTIFICATIONS: {
      VPN_DISCONNECTED_MESSAGE : 'VPN との接続が切断されたため、アクティブな会話も切断されました。「PC リカバリ」の「離席中」ステータスになりました。新しい会話や電話をかける前に、VPN に再接続し、再度「利用可能」に設定してください。',
      BLOCK_MANUAL_STATUS_CHANGE : 'VPN との接続が切断されました。新しい会話やアウトバウンドコールを続ける前に、VPN に再接続し、ステータスを「利用可能」または「アウトバウンド」に更新してください。',
      AUTHENTICATION_ERROR_TITLE: "認証できません",
      AUTHENTICATION_ERROR_BODY: "これは、Firefoxの強化型セキュリティ機能が有効になっているか、Oktaとの通信に問題があることが原因である可能性があります。詳細については、トラブルシューティングWikiをご覧ください。https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      RELEASE_NOTES : "リリースノート-バージョン",
      WHAT_NEW : "新機能:",
      TRANSCRIPT_COPIED : "トランスクリプトがクリップボードにコピーされました",
    }
  },
  fr: {
    TOP_BAR: {
      MY_CONVERSATIONS: 'Mes conversations',
      VISITOR: 'Visiteur'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'Vous n’avez actuellement aucun widget ouvert.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'Vous n’avez actuellement aucune conversation.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "Notes de mise à jour",
      WHAT_NEW : "Quoi de neuf :",
      VPN_DISCONNECTED_MESSAGE : 'Vous avez été déconnecté du VPN et, par conséquent, votre conversation active a également été déconnectée. Vous avez été redirigé vers le statut Absent de « PC Recovery ». Veuillez vous reconnecter au VPN et vous remettre sur « Disponible » avant de prendre une nouvelle conversation ou de passer des appels sortants.',
      BLOCK_MANUAL_STATUS_CHANGE : 'Vous avez été déconnecté du VPN. Veuillez vous reconnecter au VPN et mettre à jour votre statut sur « Disponible » ou « Sortant » avant de continuer à prendre de nouvelles conversations ou à passer des appels sortants.',
      AUTHENTICATION_ERROR_TITLE: "Impossible de s'authentifier",
      AUTHENTICATION_ERROR_BODY: "Cela est peut-être dû à l'activation de la protection améliorée de Firefox ou à un problème de communication avec Okta. Consultez le wiki de résolution des problèmes pour plus d'informations : https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "Transcription copiée dans le presse-papiers",
    }
  },
  de: {
    TOP_BAR: {
      MY_CONVERSATIONS: 'Meine Unterhaltungen',
      VISITOR: 'Besucher'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'Sie haben derzeit keine Widgets geöffnet.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'Sie haben derzeit keine Unterhaltungen.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "Versionshinweise",
      WHAT_NEW : "Was ist neu:",
      VPN_DISCONNECTED_MESSAGE : 'Sie wurden vom VPN getrennt und infolgedessen wurde auch Ihre aktive Konversation unterbrochen. Sie wurden in den Status „Abwesend“ von „PC-Wiederherstellung“ versetzt. Bitte stellen Sie erneut eine Verbindung zum VPN her und stellen Sie sich erneut auf „Verfügbar“ ein, bevor Sie ein neues Gespräch aufnehmen oder ausgehende Anrufe tätigen.',
      BLOCK_MANUAL_STATUS_CHANGE : 'Sie wurden vom VPN getrennt. Bitte stellen Sie erneut eine Verbindung zum VPN her und aktualisieren Sie Ihren Status auf „Verfügbar“ oder „Ausgehend“, bevor Sie weitere Konversationen annehmen oder ausgehende Anrufe tätigen.',
      AUTHENTICATION_ERROR_TITLE: "Authentifizierung nicht möglich",
      AUTHENTICATION_ERROR_BODY: "Das kann daran liegen, dass Firefox Enhanced Protection aktiviert ist oder dass es ein Problem bei der Kommunikation mit Okta gibt. Besuchen Sie die Wiki zur Problembehandlung für weitere Informationen: https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "Transkript in die Zwischenablage kopiert",
    }
  },
  es: {
    TOP_BAR: {
      MY_CONVERSATIONS: 'Mis conversaciones',
      VISITOR: 'Visitante'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'Actualmente no tiene ningún widget abierto.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'Actualmente no tiene ninguna conversación.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "Notas de lanzamiento",
      WHAT_NEW : "Qué hay de nuevo:",
      VPN_DISCONNECTED_MESSAGE : 'Se le ha desconectado de la VPN y, como resultado, su conversación activa también se ha desconectado. Ha pasado al estado Ausente de «Recuperación de PC». Vuelva a conectarse a la VPN y vuelva a configurarse como «Disponible» antes de iniciar una nueva conversación o realizar llamadas salientes.',
      BLOCK_MANUAL_STATUS_CHANGE : 'Se le ha desconectado de la VPN. Vuelva a conectarse a la VPN y actualice su estado a «Disponible» o «Saliente» antes de continuar manteniendo nuevas conversaciones o realizar llamadas salientes.',
      AUTHENTICATION_ERROR_TITLE: "No se puede autenticar",
      AUTHENTICATION_ERROR_BODY: "Esto puede deberse a que la Protección mejorada de Firefox está habilitada o a un problema de comunicación con Okta. Visite la wiki de solución de problemas para obtener más información: https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "Transcripción copiada al portapapeles",
    }
  },
  it: {
    TOP_BAR: {
      MY_CONVERSATIONS: 'Le mie conversazioni',
      VISITOR: 'Visitatore'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'Al momento non ci sono widget aperti.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'Al momento non ci sono conversazioni.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "Note di rilascio",
      WHAT_NEW : "Cosa c'è di nuovo:",
      VPN_DISCONNECTED_MESSAGE : 'Sei stato disconnesso dalla VPN e di conseguenza anche la tua conversazione attiva è stata disconnessa. Sei stato spostato nello stato Assente di «PC Recovery», riconnettiti alla VPN e imposta nuovamente su «Disponibile» prima di iniziare una nuova conversazione o effettuare chiamate in uscita.',
      BLOCK_MANUAL_STATUS_CHANGE : 'Sei stato disconnesso dalla VPN. Riconnettiti alla VPN e aggiorna il tuo stato su «Disponibile» o «In uscita» prima di continuare a rispondere a nuove conversazioni o effettuare chiamate in uscita.',
      AUTHENTICATION_ERROR_TITLE: "No se puede autenticar",
      AUTHENTICATION_ERROR_BODY: "Esto puede deberse a que la Protección Mejorada de Firefox está activada o a un problema de comunicación con Okta. Visita la wiki de solución de problemas para obtener más información: https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "Trascrizione copiata negli appunti",
    }
  },
  'pt-BR': {
    TOP_BAR: {
      MY_CONVERSATIONS: 'Minhas Conversas',
      VISITOR: 'Visitante'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: 'Sem dispositivos abertos no momento.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: 'Sem conversas no momento.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "Notas de lançamento",
      WHAT_NEW : "O que há de novo:",
      VPN_DISCONNECTED_MESSAGE : 'Você foi desconectado da VPN e, como resultado, sua conversa ativa também foi desconectada. Você foi movido para o status Ausente de “Recuperação de PC”. Reconecte-se à VPN e configure-se para “Disponível” novamente antes de iniciar qualquer nova conversa ou fazer chamadas de saída.',
      BLOCK_MANUAL_STATUS_CHANGE : 'Você foi desconectado da VPN. Reconecte-se à VPN e atualize seu status para “Disponível” ou “De saída” antes de continuar fazendo novas conversas ou fazendo chamadas externas.',
      AUTHENTICATION_ERROR_TITLE: "Não é possível autenticar",
      AUTHENTICATION_ERROR_BODY: "Isso pode ser devido à ativação do Firefox Enhanced Protection ou a um problema de comunicação com o Okta. Visite o wiki de solução de problemas para obter mais informações: https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "Transcrição copiada para a área de transferência",
    }
  },
  ko: {
    TOP_BAR: {
      MY_CONVERSATIONS: '내 대화',
      VISITOR: '방문자'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: '현재 열려 있는 위젯이 없습니다.'
    },
    CCP_PANEL: {
      EMPTY_CHAT: '현재 대화가 없습니다.'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "릴리스 노트 - 버전",
      WHAT_NEW : "새로운 기능:",
      VPN_DISCONNECTED_MESSAGE : 'VPN과의 연결이 끊어졌으며 그 결과 활성 대화도 끊겼습니다.“PC Recovery”의 자리 비움 상태로 전환되었습니다. 새로운 대화를 하거나 전화를 걸기 전에 VPN에 다시 연결하고 “사용 가능”으로 다시 설정하십시오.',
      BLOCK_MANUAL_STATUS_CHANGE : 'VPN 연결이 끊겼습니다.새 대화를 계속하거나 아웃바운드 전화를 걸기 전에 VPN에 다시 연결하고 상태를 “사용 가능” 또는 “아웃바운드”로 업데이트하세요.',
      AUTHENTICATION_ERROR_TITLE: "인증할 수 없음",
      AUTHENTICATION_ERROR_BODY: "이는 파이어폭스 강화보호 기능이 활성화되었거나 Okta와의 통신 문제 때문일 수 있습니다. 자세한 내용은 문제 해결 위키를 방문하십시오. https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "성적 증명서를 클립보드에 복사해"
    }
  },
  'zh-TW': {
    TOP_BAR: {
      MY_CONVERSATIONS: '我的對話',
      VISITOR: '訪客'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: '您目前沒有任何已開啟的小工具。'
    },
    CCP_PANEL: {
      EMPTY_CHAT: '您目前沒有任何對話。'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "版本說明-版本",
      WHAT_NEW : "什麼是新的：",
      VPN_DISCONNECTED_MESSAGE : '您已中斷與 VPN 的連線，因此您的作用中對話也已中斷連線。您已被移至「PC 恢復」的「離開」狀態，請重新連接到 VPN 並再次將自己設置為「可用」，然後再次進行任何新對話或撥出電話。',
      BLOCK_MANUAL_STATUS_CHANGE : '您已中斷與 VPN 的連線。在繼續進行新對話或撥出電話之前，請重新連接 VPN 並將狀態更新為「可用」或「出站」。',
      AUTHENTICATION_ERROR_TITLE: "無法驗證",
      AUTHENTICATION_ERROR_BODY: "這可能是因為 Firefox 增強型保護功能已啟用，或是與 Okta 通訊有問題。如需詳細資訊，請造訪疑難排解 Wiki：https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "成績單已複製到剪貼簿"
    }
  },
  'zh-CN': {
    TOP_BAR: {
      MY_CONVERSATIONS: '我的对话',
      VISITOR: '访客'
    },
    WIDGET_CONTAINER: {
      EMPTY_WIDGET: '您目前没有打开任何小组件。'
    },
    CCP_PANEL: {
      EMPTY_CHAT: '您目前没有对话。'
    },
    NOTIFICATIONS: {
      RELEASE_NOTES : "发行说明-版本",
      WHAT_NEW : "新增内容：",
      VPN_DISCONNECTED_MESSAGE : '您已与 VPN 断开连接，因此您的活跃对话也已断开。你已移至 “离开” 状态为 “PC Recovery”，在进行任何新的对话或拨出电话之前，请重新连接到 VPN 并再次将自己设置为 “可用”。',
      BLOCK_MANUAL_STATUS_CHANGE : '您已与 VPN 断开连接。在继续进行新对话或拨打外线电话之前，请重新连接到 VPN 并将您的状态更新为 “可用” 或 “出站”。',
      AUTHENTICATION_ERROR_TITLE: "无法进行身份验证",
      AUTHENTICATION_ERROR_BODY: "这可能是由于启用了 Firefox 增强保护或与 Okta 通信出现问题。有关更多信息，请访问故障排除 wiki：https://wiki.corp.adobe.com/display/CXTS/OAC+Agent+Console+Issues",
      TRANSCRIPT_COPIED : "成绩单已复制到剪贴板"
    }
  }
}
export default localization
import { SET_PDC_MESSAGE } from '../constants/pdcConstants'

interface defaultStateInterface {
  selectedMessage: string
}

const defaultState: defaultStateInterface = {
  selectedMessage: ''
}

const pdcReducer = (state: defaultStateInterface = defaultState, action: any) => {
  switch (action.type) {
    case SET_PDC_MESSAGE:
      return { selectedMessage: action.payload }
    default:
      return state
  }
}

export default pdcReducer

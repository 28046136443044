/* eslint-disable import/prefer-default-export */
import { OPEN_PAGE } from '../constants/navBarConstants'

export const triggerNavBar = (navBarToggles: any, navBarEventData: any) => (dispatch: any) => {
  navBarToggles.chatToggle = false
  navBarToggles.historyToggle = false
  let header = ''
  if ((navBarEventData.chat ?? false) === true) {
    navBarToggles.chatToggle = true
    header = 'Test Customer'
  } else if ((navBarEventData.history ?? false) === true) {
    navBarToggles.historyToggle = true
    header = 'history'
  } else {
    navBarToggles.chatToggle = true
    header = 'Test Customer'
  }
  dispatch({ type: OPEN_PAGE, payload: { navBarToggles, header } })
}

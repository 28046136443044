export const CHANGE_MODE = 'CHANGE_MODE'
export const CHANGE_SOUND = 'CHANGE_SOUND'
export const SET_AGENT_DETAILS = 'SET_AGENT_DETAILS'
export const SET_PREFERENCES = 'SET_PREFERENCES'
export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'
export const CHANGE_VPN_STATUS = 'CHANGE_VPN_STATUS'
export const UPDATE_LAST_AGENT_STATUS_ON_VPN = 'UPDATE_LAST_AGENT_STATUS_ON_VPN'
export const UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS = 'UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS'
export const SET_RELEASE_NOTES = 'SET_RELEASE_NOTES'
export const RINGER_DEVICE_DETAILS = 'RINGER_DEVICE_DETAILS'
export const SHOW_LOADER_ON_OAC = 'SHOW_LOADER_ON_OAC'


/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Profiler } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LibraryModule as EcmLibrary } from 'oc-ecm-module-client'
import { useContextData } from '../../Hooks'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import 'oc-ecm-module-client/dist/index.css'
import './enterpriseCaseManagement.scss'
import { CHANGE_CUSTOMER, CHANGE_CUSTOMER_CONTACT  } from '../../../../redux/constants/changeCustomerConstants'
import { isZendeskDecommissioned } from '../../../../utils/utils'
declare const window: any

interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
}

let currentConversation: any = ''

const EnterpriseCaseManagement = () => {
  const dispatch = useDispatch();

  const ecmProps = useContextData()

  function callBackEventListener(eventType: string, eventData: Record<string, any>): void {
    if (eventType === 'activeCustomer') {
      console.log('ECM Module: Dispatching change customer action with payload', eventData)
      dispatch({ type: CHANGE_CUSTOMER, payload: eventData })
      dispatch({ type: CHANGE_CUSTOMER_CONTACT, payload: { data: eventData, contactId: ecmProps?.ocPlatformData?.chatInfo?.currentContactId } })
    }
    else if (eventType === 'CHANGE_CUSTOMER_TOGGLE') {
      dispatch({ type: 'CHANGE_CUSTOMER_TOGGLE'})
    }
  }
  const [newConversationLoader, setNewConversationLoader] = useState(true)

  const updateCOnversationId = async () => {
    const getGenericTimeoutPromise = (timeout: any) =>
      new Promise((resolve) => {
        setTimeout(resolve, timeout, {
          data: {
            success: true
          }
        })
      })
    setNewConversationLoader(true)
    await getGenericTimeoutPromise(0)
    currentConversation = ecmProps?.ocPlatformData?.chatInfo?.contactId
    setNewConversationLoader(false)
  }

  useEffect(() => {
    if (ecmProps?.ocPlatformData?.chatInfo?.contactId !== ecmProps?.ocPlatformData?.chatInfo?.currentContactId) {
      updateCOnversationId()
    }
  }, [ecmProps?.ocPlatformData?.chatInfo?.currentContactId])

  useEffect(() => {
    if (ecmProps?.ocPlatformData?.chatInfo?.contactId) updateCOnversationId()
  }, [ecmProps?.ocPlatformData?.chatInfo?.contactId])

  const { darkMode: globalDarkMode, language: globalLanguage } = useSelector((state: State) => state.preferences)
  const [darkMode, setDarkMode] = useState(globalDarkMode)
  const [language, setLanguage] = useState(globalLanguage)

  useEffect(() => {
    setDarkMode(globalDarkMode)
    setLanguage(globalLanguage)
  }, [globalDarkMode, globalLanguage])

  const { jcAuthData: { currentQueue = '' } = {} } = ecmProps
  const startWith = currentQueue.substring(0, 4)
  const isECMChat =   (startWith === 'ENT-' || ( isZendeskDecommissioned() && startWith === 'Sign'))

  return (
    <div className='enterprise-case-management'>
      {newConversationLoader ? (
        <p />
      ) : (
        isECMChat &&
        (window.enable_widget_analytics ? (
          <Profiler id='ECM' onRender={onRenderCB}>
            <EcmLibrary contextData={ecmProps} callBackEvent={callBackEventListener} />
          </Profiler>
        ) : (
          <EcmLibrary contextData={ecmProps} callBackEvent={callBackEventListener} />
        ))
      )}
    </div>
  )
}

export default EnterpriseCaseManagement

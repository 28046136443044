import { apiClient } from './axiosSettings'
import getAuthHeaders from './getAuthHeaders'
import env_variables from '../../../config/env_variables'

export async function fetchOngoingConversations(agentId: string) {
  try {
    const apiUrl = `${env_variables.CCP_API_END_POINT}/api/histories/agent`
    const ongoingConversationResponse = await apiClient.get(`${apiUrl}?agentId=${agentId}`, { headers: getAuthHeaders() })
    return ongoingConversationResponse
  } catch (e) {
    console.log('error')
  }
}

export async function fetchCsatForAgent(agentId: string) {
  try {
    const apiUrl = `${env_variables.CCP_API_END_POINT}/api/histories/agent`
    const csatData = await apiClient.get(`${apiUrl}?agentId=${agentId}&csat=true`, { headers: getAuthHeaders() })
    return csatData
  } catch (e) {
    console.log('error')
  }
}

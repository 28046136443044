import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { LibraryModule as ActInactChat } from 'oc-active-conversation-module-client'
import { ContactRecord } from '../../../ContactCenter/Models'
import { setCurrentConversation, setCurrentAC, setOutBoundCall } from '../../../../redux/actions/contactCenterActions'
import { SET_FILTERS, SET_SCROLL_INTO_VIEW } from '../../../../redux/constants/flyoutConstants'
import { sendWidgetAnalyticEvent } from '../../../../helpers/widget-performance-analytics'
import { sendChatEvents } from '../../../../helpers/analytics-helpers'
import 'oc-active-conversation-module-client/dist/index.css'
import './FlyoutPanel.scss'

interface State {
  contactCenter: {
    conversations: ContactRecord[]
    currentConversation: any
    conversationsAC: ContactRecord[]
    currentConversationAC: any
    currentView: string
  }
  preferences: {
    darkMode: boolean
    language: string
  }
  flyout: {
    filterStates: any
    scroll: boolean
  }
}
const FlyoutPanel = () => {
  const dispatch = useDispatch()
  const { conversations, currentConversation, conversationsAC, currentConversationAC, currentView } = useSelector((state: State) => state.contactCenter)
  const { darkMode, language } = useSelector((state: State) => state.preferences)
  const { filterStates, scroll } = useSelector((state: State) => state.flyout)
  const SetCurrentConversation = (c: ContactRecord) => {
    if (currentView === 'AC_VIEW') {
      dispatch(setCurrentAC(c))
    } else {
      dispatch(setCurrentConversation(c))
    }
  }
  const [lastUpdated, setLastUpdated] = useState('')
  React.useEffect(() => {
    setLastUpdated(new Date().toISOString())
  }, [conversations, conversationsAC])

  const emptyCurrentConversation = () => {
    dispatch({
      type: 'SETCURRENTCONVERSATION',
      payload: {
        ocPlatformData: {
          chatInfo: {
            initialContactId: '',
            currentContactId: '',
            status: ''
          },
          chatTranscript: []
        }
      }
    })
    dispatch({
      type: 'SETCURRENT_AC',
      payload: {
        ocPlatformData: {
          chatInfo: {
            initialContactId: '',
            currentContactId: '',
            status: ''
          },
          chatTranscript: []
        }
      }
    })
  }

  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'openChat') {
      dispatch({ type: "CLICK_PHONE_NUMBER", payload: 0 })
      SetCurrentConversation(eventData.id)
      // enable the widget performance analytics
      sendWidgetAnalyticEvent()
      // send chat open event to dunamis
      sendChatEvents({
        value: eventData?.id?.ocPlatformData?.chatInfo?.status || 'NA'
      })
    }
    if (eventType === 'setFilter') {
      dispatch({ type: SET_FILTERS, payload: eventData })
      if (currConv && eventData.filter === currConv.ocPlatformData.chatInfo.status.toLowerCase() && !eventData.selected) {
        emptyCurrentConversation();
      }
    }
    if (eventType === 'setScroll') {
      dispatch({ type: SET_SCROLL_INTO_VIEW, payload: false })
    }

    if (eventType === 'outbound-call') {
      dispatch(setOutBoundCall(eventData.outbound || false));
      if (eventData.outbound) emptyCurrentConversation();
      dispatch({ type: "CLICK_PHONE_NUMBER", payload: 0 })
    }
  }

  const currConv = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const convs = currentView === 'AC_VIEW' ? conversationsAC || [] : conversations || []
  const json = {
    lang: language,
    darkMode,
    filterStates,
    payload: { currentConversation: currConv, conversations: convs },
    lastUpdated,
    scroll
  }

  try {
    return <ActInactChat contextData={json} callBackEvent={callBackEventListener} />
  } catch (error) {
    console.log(error)
    return <div />
  }
}

export default FlyoutPanel

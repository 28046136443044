import React, { useState, useEffect } from 'react'
import './Offline.scss'
import { Button, Content, Dialog, Flex, Footer, Text } from '@adobe/react-spectrum'
import { useDispatch } from 'react-redux'
import localization from './lang/localization'

interface IProps {
  language: string
  alertData: { isOpen: boolean; type: string; msgText: string }
}

const Offline = (props: IProps) => {
  const { language, alertData } = props
  const lang: any = language || 'en'
  const dispatch = useDispatch()
  const [msgText, setMsgText] = useState()

  useEffect(() => {
    setMsgText(alertData.msgText ? alertData.msgText : localization[lang][alertData.type].HEADING)
  }, [])

  const closeDialog = () => {
    dispatch({ type: 'SETOFFLINEINDICATOR', payload: { isOpen: false } })
  }

  return (
    <>
      <Dialog size='S' UNSAFE_className='offline-popover' width={480}>
        <Content>
          <Flex justifyContent='space-between'>
            <Text>{msgText}</Text>
          </Flex>
          <Footer UNSAFE_className='offline-button-group'>
            <Button variant='cta' onPress={closeDialog}>
              {localization[lang][alertData.type].ACTION}
            </Button>
          </Footer>
        </Content>
      </Dialog>
    </>
  )
}

export default Offline

/* eslint-disable import/prefer-default-export */
import { apiClient } from './axiosSettings'
import env_variables from '../../../config/env_variables'
import getAuthHeaders from './getAuthHeaders'
import axios from 'axios'
import { FEATURE_FLAG_AA_Q } from '../Constants'

export async function fetchTranscripts(contactId: string) {
  try {
    const apiUrl = `${env_variables.CCP_API_END_POINT}/api/transcripts`
    const transcripts = await apiClient.get(`${apiUrl}/${contactId}?mode=short`, { headers: getAuthHeaders() })
    return transcripts
  } catch (e) {
    console.log('error')
  }
}

export async function fetchTranslationTranscripts(reqBody: any) {
  try {
    const translateChat: any = await apiClient.post(`${env_variables.CCP_API_END_POINT}/api/translate/chat`, reqBody,  { headers: getAuthHeaders() })
    const text = translateChat?.data?.data?.texts[0]?.text || ''
    return text
  } catch (e) {
    console.log('error')
  }
}

export async function fetchConversationSummary(conversation: Array<any>, params: any, questionsEnabled?: boolean) {
  try {
    const {
      ldap, 
      path,
      contactId,
      queue, 
      bullets, 
      final,
      language
    } = params
    const apiUrl = `${env_variables.GENAI_MIDDLEWARE_URL}/conversation/summary?advanced=true&ContentId=${contactId}&AgentLdap=${ldap}&Queue=${queue}&Language=${language}&TransferCategory=${path}&UseCase=conversation summary&lines=one${bullets ? `&bullets=${bullets}` : ''}${final ? '&final=true' : ''}${questionsEnabled ? `&${FEATURE_FLAG_AA_Q}=true` : ''}`
    const headers =  { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.post(apiUrl, { transcript: conversation}, { headers })
    return response?.data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
  }
}
export async function updateConversationSummaryFeedbackAPI(params: any, contactId: any) {
  try {

    const apiUrl = `${env_variables.GENAI_MIDDLEWARE_URL}/feedback/summary?UseCase=conversation summary&Surface=oac-custom-panel&ContentId=${contactId}`
  
    const headers =  { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.put(apiUrl, params, { headers })
    return response?.data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
    return {}
  }
}
export async function getClosedConversationSummaryAPI(params: any) {
  try {
    const { conversationId } = params
    const apiUrl = `${env_variables.GENAI_MIDDLEWARE_URL}/feedback/finalsummary?ContentId=${conversationId}`
    const headers =  { 'x-api-key': 'oac-custom-panel', Authorization: localStorage.getItem('customPanelAccessToken').replaceAll('"', '') ?? '' }
    const response = await axios.get(apiUrl, { headers })
    return response?.data
  } catch (e) {
    console.log('Error querying conversation summary. Error: ', e)
    return {}
  }
}

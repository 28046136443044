/* eslint-disable class-methods-use-this */

class AnalyticUserSingleton {

  setUserAnalyticsSession() {
    if (sessionStorage.getItem('analytic_session') === null) {
      sessionStorage.setItem('analytic_session', this.uuid())
    }
  }

  clearUserAnalyticsSession() {
    sessionStorage.removeItem('analytic_session')
  }

  setAndGetAnalyticsSession() {
    this.setUserAnalyticsSession();
    return sessionStorage.getItem('analytic_session');
  }

  uuid() {
    let dt = new Date().getTime()
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (dt + Math.random() * 16) % 16 | 0
      dt = Math.floor(dt / 16)
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16)
    })
    return uuid
  }

  get sessionId() {
    return sessionStorage.getItem('analytic_session') || this.setAndGetAnalyticsSession();
  }
}

const analyticUser = new AnalyticUserSingleton();

export default Object.freeze(analyticUser);

import { apiClient } from './axiosSettings'
import getAuthHeaders from './getAuthHeaders'
import env_variables from '../../../config/env_variables'

export async function fetchAgentFeatureFlag(agentId: string) {
  try {
    const apiUrl = `${env_variables.CCP_API_END_POINT}/api/v1/agent/getTags/`
    const agentFeatureFlagResponse = await apiClient.get(`${apiUrl}?agentId=${agentId}`, { headers: getAuthHeaders() })

    return agentFeatureFlagResponse?.data;
  } catch (e) {
    console.log(e);
  }
}
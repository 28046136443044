const localization: any = {
    en: {
        "NEW_NOTIFICATION_TITLE": "New Incoming Conversation",
        "TRANSFER_NOTIFICATION_TITLE": "The conversation was successfully transferred to the",
        "QUEUE": "Queue",
        "MANAGER": "Manager",
        "SPECIALIST": "Specialist",
        "CONVERSATION_CLOSED": "Conversation Closed",
        "STATUS_CHANGE_FAILED": "Failed to change status, please make sure there are no active conversations",
        "LOGOUT_ALERT": "Please close all your active conversations prior to logout",
        "TRANSFER_FAILED_QUEUE": "Transfer failed! Please make sure agents in the queue are online",
        "TRANSFER_FAILED_AGENT": "Transfer failed! Please make sure agent is online.",
        "TRANSFER_FAILED": "Transfer failed! Please try after some time",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "New Incoming Conversation",
            "NEW_CONVERSATION_MESSAGE": "Click on this notification to navigate to the Omnichannel Agent Console",
            "NEW_MESSAGE_TITLE": "New Message Received",
            "VISITOR": "Visitor"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Call Missed",
            "CALL_MISSED_SUBTITLE":"You have missed this call. You should now close the Contact.",
            "CLOSE_CONTACT":"Close Contact",
            "CUSTOMER_NAME":"Customer Name",
            "ORIGIN":"Origin",
            "QUEUE_JOURNEY":"Queue Journey",
            "INTENT":"Intent",
            "SUB_INTENT":"Sub-intent",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Incoming Call",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Previously Disconnected Customer",
            "INCOMING_COLD_TRANSFER":"Incoming Cold Transfer",
            "INCOMING_WARM_TRANSFER":"Incoming Warm Transfer",
            "INCOMING_CALL_SUBTITLE":"You have a new incoming call.",
            "ACCEPT":"Accept",
            "REJECT":"Reject",
            "REJECT_CONFIRMATION":"Are you sure you want to reject this call?",
            "CALL_REJECTED":"Call Rejected",
            "AFTER_REJECT":"You have rejected this call. You should now close the Contact.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "Contact ID",
            "CONVERSATION_ID": "Conversation ID",
            "PHONE_NUMBER": "Phone Number"
        },
        "CONNECTION_LOST": "Your network connection has been lost, please check your connectivity settings.",
        "TOASTS":{
            "CONNECTION_LOST" : "Websocket connection error. Check your network connection and refresh the page if problems persist.",
            "CONNECTION_GAIN" : "Websocket connection re-established.   Refresh the page if problems persist.",
            "CHAT_CLOSED_BY_CUSTOMER": "Conversation [Conversation ID] was closed by the customer"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout message",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout title",
            "connect-softphone-error-invalid-number-message": "We are unable to complete the call as dialed. Try again, or contact your administrator.",
            "connect-softphone-error-invalid-number-title": "Invalid number",
            "connect-softphone-error-invalid-outbound-configuration-message": "Before you can place an outbound call, you must associate a phone number with this queue. Contact your administrator for further assistance.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Invalid outbound configuration",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "Failed in establishing connection with media endpoint. Please check your network connection, contact your administrator.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Media endpoint connection check failed",
            "connect-softphone-error-microphone-not-shared-message": "Your browser does not have access to the microphone",
            "connect-softphone-error-microphone-not-shared-title": "Microphone is not accessible",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "You've exceeded the maximum allowed limit of CCP windows open. Close the window with the excess CCP and refresh the page to continue working.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Multiple CCPs open",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error msg",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "Failed to establish softphone connection. Try again or contact your administrator.",
            "connect-softphone-error-signalling-connection-failure-title": "Softphone call failed",
            "connect-softphone-error-signalling-handshake-failure-message": "Failed to establish softphone connection. Try again or contact your administrator.",
            "connect-softphone-error-signalling-handshake-failure-title": "Softphone call failed",
            "connect-softphone-error-unsupported-browser-message": "Some functionality may not be available",
            "connect-softphone-error-unsupported-browser-title": "This browser is not supported",
            "connect-softphone-error-unsupported-country-message": "Only #{countries} numbers are valid for outbound calls. Contact your administrator for further assistance.",
            "connect-softphone-error-unsupported-country-title": "Unsupported country",
            "connect-softphone-error-webrtc-error-message": "Call failed due to a browser-side WebRTC issue.",
            "connect-softphone-error-webrtc-error-title": "WebRTC issue"
          }
          
          
    },
    ja: {
        "NEW_NOTIFICATION_TITLE": "新しい会話の受信",
        "TRANSFER_NOTIFICATION_TITLE": "会話は正常に転送されました",
        "QUEUE": "キュー",
        "MANAGER": "マネージャー",
        "SPECIALIST": "スペシャリスト",
        "CONVERSATION_CLOSED": "会話が終了しました",
        "STATUS_CHANGE_FAILED": "ステータスを変更できませんでした。現在会話が行われていないかを確認してください。",
        "LOGOUT_ALERT": "アクティブな会話をすべて終了してからログアウトしてください",
        "TRANSFER_FAILED_QUEUE": "転送できませんでした。該当キューの担当者がオンラインになっていることを確認してください。",
        "TRANSFER_FAILED_AGENT": "転送できませんでした。担当者がオンラインになっていることを確認してください。",
        "TRANSFER_FAILED": "転送できませんでした。しばらくしてからやり直してください。",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "新しく着信した通話",
            "NEW_CONVERSATION_MESSAGE": "この通知をクリックして、OmniChannel エージェントコンソールに遷移します",
            "NEW_MESSAGE_TITLE": "新しいメッセージを受信しました",
            "VISITOR": "訪問者"
        },
        "TELEPHONY":{
            "CALL_MISSED":"落としたコール",
            "CALL_MISSED_SUBTITLE":"このコールを落としました。問い合わせはクローズしてください。",
            "CLOSE_CONTACT":"問い合わせをクローズする",
            "CUSTOMER_NAME":"お客様の名前",
            "ORIGIN":"原点",
            "QUEUE_JOURNEY":"キューの足跡",
            "INTENT":"目的",
            "SUB_INTENT":"副目的",
            "COUNTRY":"Country",
            "INCOMING_CALL":"着信コール",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "以前に接続を解除した顧客",
            "INCOMING_WARM_TRANSFER":"着信ウォームトランスファー",
            "INCOMING_COLD_TRANSFER":"着信コールドトランスファー",
            "INCOMING_CALL_SUBTITLE":"新しい着信があります。",
            "ACCEPT":"承認",
            "REJECT":"拒否",
            "REJECT_CONFIRMATION":"このコールを拒否してもよろしいですか？",
            "CALL_REJECTED":"拒否されたコール",
            "AFTER_REJECT":"このコールを拒否しました。問い合わせはクローズしてください。",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "コンタクト ID",
            "CONVERSATION_ID": "会話 ID",
            "PHONE_NUMBER": "電話番号"
        },
        "CONNECTION_LOST": "ネットワーク接続が切れたので、接続設定を確認してください。",
        "TOASTS":{
            "CONNECTION_LOST" : "Websocket接続エラーです。ネットワーク接続を確認し、問題が解決しない場合はページを更新してください。",
            "CONNECTION_GAIN" : "Websocket接続が再確立されました。問題が解決しない場合は、ページを更新してください。",
            "CHAT_CLOSED_BY_CUSTOMER": "会話 [Conversation ID] はお客様によって終了されました"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout メッセージ",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout 件名",
            "connect-softphone-error-invalid-number-message": "ダイヤルされた番号では通話を完了できません。再度お試しになるか、管理者にお問い合わせください。",
            "connect-softphone-error-invalid-number-title": "無効な数値",
            "connect-softphone-error-invalid-outbound-configuration-message": "通話を発信する前に、電話番号をこのキューに関連付ける必要があります。管理者にお問い合わせください。",
            "connect-softphone-error-invalid-outbound-configuration-title": "無効なアウトバウンド設定",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "メディアエンドポイントとの接続の確立に失敗しました。ネットワーク接続を確認して、管理者に連絡してください。",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "メディアエンドポイントの接続チェックに失敗しました",
            "connect-softphone-error-microphone-not-shared-message": "ご使用のブラウザにはマイクへのアクセス権限がありません。",
            "connect-softphone-error-microphone-not-shared-title": "マイクにアクセスできません",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "開いている CCP ウィンドウの数が最大許容限度を超えました。余分な CCP ウィンドウを閉じ、ページを更新して作業を続けてください。",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "複数の CCP が開いています",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error メッセージ",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "ソフトフォン接続の確立に失敗しました。再度お試しになるか、管理者にお問い合わせください。",
            "connect-softphone-error-signalling-connection-failure-title": "ソフトフォン通話が失敗しました",
            "connect-softphone-error-signalling-handshake-failure-message": "ソフトフォン接続の確立に失敗しました。再度お試しになるか、管理者にお問い合わせください。",
            "connect-softphone-error-signalling-handshake-failure-title": "ソフトフォン通話が失敗しました",
            "connect-softphone-error-unsupported-browser-message": "一部の機能は使用できない可能性があります。",
            "connect-softphone-error-unsupported-browser-title": "このブラウザはサポートされていません",
            "connect-softphone-error-unsupported-country-message": "アウトバウンドコールでは、{countries} の番号のみが有効です。管理者にお問い合わせください。",
            "connect-softphone-error-unsupported-country-title": "サポートされていない国",
            "connect-softphone-error-webrtc-error-message": "ブラウザ側の WebRTC の問題で、通話に失敗しました。",
            "connect-softphone-error-webrtc-error-title": "WebRTC の問題"
          }
          
          
    },
    fr: {
        "MANAGER": "Manager",
        "NEW_NOTIFICATION_TITLE": "Nouvelle conversation entrante",
        "LOGOUT_ALERT": "Veuillez fermer toutes vos conversations actives avant de vous déconnecter",
        "QUEUE": "File d’attente",
        "SPECIALIST": "Spécialiste",
        "TRANSFER_NOTIFICATION_TITLE": "La conversation a été transférée vers le",
        "CONVERSATION_CLOSED": "Conversation Fermée",
        "STATUS_CHANGE_FAILED": "Échec de la modification du statut : assurez-vous qu’il n’y a aucune conversation active",
        "TRANSFER_FAILED": "Échec du transfert ! Veuillez réessayer ultérieurement",
        "TRANSFER_FAILED_AGENT": "Échec du transfert ! Assurez-vous que l’agent est en ligne.",
        "TRANSFER_FAILED_QUEUE": "Échec du transfert ! Assurez-vous que les agents dans la file d’attente sont en ligne",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "Nouvelle conversation entrante",
            "NEW_CONVERSATION_MESSAGE": "Cliquez sur cette notification pour accéder à la console agent OAC",
            "NEW_MESSAGE_TITLE": "Nouveau message reçu",
            "VISITOR": "Visiteur"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Appel manqué",
            "CALL_MISSED_SUBTITLE":"Vous avez manqué cet appel. Vous devez maintenant fermer le contact.",
            "CLOSE_CONTACT":"Fermer le contact",
            "CUSTOMER_NAME":"Nom du client",
            "ORIGIN":"Origine",
            "QUEUE_JOURNEY":"Parcours de file d’attente",
            "INTENT":"Intention",
            "SUB_INTENT":"Sous-intention",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Appel entrant",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Client précédemment déconnecté",
            "INCOMING_WARM_TRANSFER":"Transfert d'appel assisté entrant",
            "INCOMING_COLD_TRANSFER":"Transfert d'appel non -assisté entrant",
            "INCOMING_CALL_SUBTITLE":"Vous avez un nouvel appel entrant.",
            "ACCEPT":"Accepter",
            "REJECT":"Rejeter",
            "REJECT_CONFIRMATION":"Voulez-vous vraiment rejeter cet appel ?",
            "CALL_REJECTED":"Appel rejeté",
            "AFTER_REJECT":"Vous avez rejeté cet appel. Vous devez maintenant fermer le contact.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "ID de contact",
            "CONVERSATION_ID": "Identifiant de conversation",
            "PHONE_NUMBER": "Gérer le dossier"
        },
        "CONNECTION_LOST": "Votre connexion au réseau a été perdue, veuillez vérifier vos paramètres de connectivité",
        "TOASTS":{
            "CONNECTION_LOST" : "Erreur de connexion au Websocket. Vérifiez votre connexion réseau et actualisez la page si les problèmes persistent.",
            "CONNECTION_GAIN" : "La connexion Websocket a été rétablie. Actualisez la page si les problèmes persistent.",
            "CHAT_CLOSED_BY_CUSTOMER": "La conversation [Conversation ID] a été fermée par le client"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "message ice_collection_timeout",
            "connect-softphone-error-ice-collection-timeout-title": "titre ice_collection_timeout",
            "connect-softphone-error-invalid-number-message": "Nous ne pouvons pas effectuer l’appel tel qu’il a été composé. Veuillez réessayer ou contacter votre administrateur.",
            "connect-softphone-error-invalid-number-title": "Numéro non valide",
            "connect-softphone-error-invalid-outbound-configuration-message": "Pour pouvoir effectuer un appel sortant, vous devez associer un numéro de téléphone à cette file d’attente. Contactez votre administrateur pour obtenir de l’aide.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Configuration sortante non valide",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "Échec de l'établissement de la connexion avec le point de terminaison du média. Veuillez vérifier votre connexion réseau ou contacter votre administrateur.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Échec de la vérification de la connexion du point de terminaison du média",
            "connect-softphone-error-microphone-not-shared-message": "Votre navigateur n’a pas accès au microphone.",
            "connect-softphone-error-microphone-not-shared-title": "Le microphone n’est pas accessible.",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "Vous avez dépassé la limite maximale autorisée pour les fenêtres CCP ouvertes. Fermez la fenêtre ayant le CCP excédentaire et actualisez la page pour continuer à travailler.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Plusieurs CCP ouverts",
            "connect-softphone-error-realtime-communication-error-message": "message realtime_communication_error",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "Impossible d’établir une connexion avec le logiciel de téléphonie sur Internet. Veuillez réessayer ou contactez votre administrateur.",
            "connect-softphone-error-signalling-connection-failure-title": "Échec de l’appel du logiciel de téléphonie sur Internet",
            "connect-softphone-error-signalling-handshake-failure-message": "Impossible d’établir une connexion avec le logiciel de téléphonie sur Internet. Veuillez réessayer ou contactez votre administrateur.",
            "connect-softphone-error-signalling-handshake-failure-title": "Échec de l’appel du logiciel de téléphonie sur Internet",
            "connect-softphone-error-unsupported-browser-message": "Il est possible que certaines fonctionnalités ne soient pas disponibles.",
            "connect-softphone-error-unsupported-browser-title": "Ce navigateur n’est pas pris en charge.",
            "connect-softphone-error-unsupported-country-message": "Seuls les numéros ayant pour indicatifs de pays #{countries} sont valides pour les appels sortants. Contactez votre administrateur pour obtenir de l’aide.",
            "connect-softphone-error-unsupported-country-title": "Pays non pris en charge",
            "connect-softphone-error-webrtc-error-message": "Échec de l’appel en raison d’un problème WebRTC côté navigateur.",
            "connect-softphone-error-webrtc-error-title": "Problème WebRTC"
          }
          
          
    },
    de: {
        "MANAGER": "Manager",
        "NEW_NOTIFICATION_TITLE": "Neue eingehende Unterhaltung",
        "LOGOUT_ALERT": "Bitte schließen Sie alle aktiven Unterhaltungen, bevor Sie sich abmelden",
        "QUEUE": "Warteschlange",
        "SPECIALIST": "Spezialist",
        "TRANSFER_NOTIFICATION_TITLE": "Die Unterhaltung wurde erfolgreich übertragen an:",
        "CONVERSATION_CLOSED": "Unterhaltung Geschlossen",
        "STATUS_CHANGE_FAILED": "Statusänderung fehlgeschlagen; bitte stellen Sie sicher, dass es keine aktiven Unterhaltungen gibt",
        "TRANSFER_FAILED": "Übertragung fehlgeschlagen. Versuchen Sie es später erneut.",
        "TRANSFER_FAILED_AGENT": "Übertragung fehlgeschlagen. Bitte vergewissern Sie sich, dass der Mitarbeiter online ist.",
        "TRANSFER_FAILED_QUEUE": "Übertragung fehlgeschlagen. Bitte stellen Sie sicher, dass die Mitarbeiter in der Warteschlange online sind",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "Neue eingehende Unterhaltung",
            "NEW_CONVERSATION_MESSAGE": "Klicken Sie auf diese Benachrichtigung, um die OmniChannel Agenten-Konsole aufzurufen",
            "NEW_MESSAGE_TITLE": "Neue Nachricht erhalten",
            "VISITOR": "Besucher"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Anruf verpasst",
            "CALL_MISSED_SUBTITLE":"Sie haben diesen Anruf verpasst. Sie müssen jetzt den Kontakt schließen.",
            "CLOSE_CONTACT":"Kontakt schließen",
            "CUSTOMER_NAME":"Name des Kunden",
            "ORIGIN":"Ursprung",
            "QUEUE_JOURNEY":"Warteschlange",
            "INTENT":"Zweck",
            "SUB_INTENT":"Unterintention",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Eingehender Anruf",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Kunde, der zuvor keine Verbindung hergestellt hat",
            "INCOMING_WARM_TRANSFER":"Eingehende warme Übertragung",
            "INCOMING_COLD_TRANSFER":"Eingehende Kaltübertragung",
            "INCOMING_CALL_SUBTITLE":"Sie haben einen neuen eingehender Annuf.",
            "ACCEPT":"Akzeptieren",
            "REJECT":"Ablehnen",
            "REJECT_CONFIRMATION":"Möchten Sie den Anruf wirklich Ablehnen?",
            "CALL_REJECTED":"Anruf abgelehnt",
            "AFTER_REJECT":"Sie haben diesen Anruf abgelehnt. Sie müssen jetzt den Kontakt schließen.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "Kontakt-ID",
            "CONVERSATION_ID": "Unterhaltungs-ID",
            "PHONE_NUMBER": "Fall verwalten"
        },
        "CONNECTION_LOST": "Ihre Netzwerkverbindung wurde unterbrochen, bitte überprüfen Sie Ihre Verbindungseinstellungen.",
        "TOASTS":{
            "CONNECTION_LOST" : "Websocket-Verbindungsfehler. Überprüfen Sie Ihre Netzwerkverbindung und aktualisieren Sie die Seite, falls die Probleme weiterhin bestehen.",
            "CONNECTION_GAIN" : "Die Websocket-Verbindung wurde wiederhergestellt. Aktualisieren Sie die Seite, falls die Probleme weiterhin bestehen.",
            "CHAT_CLOSED_BY_CUSTOMER": "Die Unterhaltung [Conversation ID] wurde vom Kunden geschlossen "
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout-Nachricht",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout-Titel",
            "connect-softphone-error-invalid-number-message": "Wir können den Anruf nicht wie gewählt abschließen. Versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.",
            "connect-softphone-error-invalid-number-title": "Ungültige Nummer",
            "connect-softphone-error-invalid-outbound-configuration-message": "Um einen ausgehenden Anruf zu tätigen, müssen Sie dieser Warteschlange eine Telefonnummer zuordnen. Wenden Sie sich an Ihren Administrator, um weitere Unterstützung zu erhalten.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Ungültige Konfiguration für ausgehende Verbindungen",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "Verbindung zum Medienendpunkt konnte nicht hergestellt werden. Bitte überprüfen Sie Ihre Netzwerkverbindung und wenden Sie sich an Ihren Administrator.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Verbindungsprüfung des Medienendpunkts fehlgeschlagen",
            "connect-softphone-error-microphone-not-shared-message": "Ihr Browser hat keinen Zugriff auf das Mikrofon.",
            "connect-softphone-error-microphone-not-shared-title": "Kein Zugriff auf das Mikrofon",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "Sie haben das maximal zulässige Limit für geöffnete CCP-Fenster überschritten. Schließen Sie das Fenster mit dem überschüssigen CCP und aktualisieren Sie die Seite, um weiterzuarbeiten.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Mehrere CCPs geöffnet",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error-Nachricht",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "Fehler bei der Herstellung einer Softphone-Verbindung. Versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.",
            "connect-softphone-error-signalling-connection-failure-title": "Fehler bei Softphone-Anruf",
            "connect-softphone-error-signalling-handshake-failure-message": "Fehler bei der Herstellung einer Softphone-Verbindung. Versuchen Sie es erneut oder wenden Sie sich an Ihren Administrator.",
            "connect-softphone-error-signalling-handshake-failure-title": "Fehler bei Softphone-Anruf",
            "connect-softphone-error-unsupported-browser-message": "Funktionalität ggf. teilweise nicht verfügbar.",
            "connect-softphone-error-unsupported-browser-title": "Dieser Browser wird nicht unterstützt",
            "connect-softphone-error-unsupported-country-message": "Nur {countries}-Nummern sind für ausgehende Anrufe gültig. Wenden Sie sich an Ihren Administrator, um weitere Unterstützung zu erhalten.",
            "connect-softphone-error-unsupported-country-title": "Nicht unterstütztes Land",
            "connect-softphone-error-webrtc-error-message": "Anruffehler aufgrund eines browserseitigen WebRTC-Problems.",
            "connect-softphone-error-webrtc-error-title": "WebRTC-Problem"
          }
          
          
    },
    es: {
        "MANAGER": "Responsable",
        "NEW_NOTIFICATION_TITLE": "Nueva conversación entrante",
        "LOGOUT_ALERT": "Por favor, cierre todas las conversaciones activas antes de cerrar la sesión",
        "QUEUE": "Cola",
        "SPECIALIST": "Especialista",
        "TRANSFER_NOTIFICATION_TITLE": "La conversación se ha transferido correctamente a",
        "CONVERSATION_CLOSED": "Conversación Cerrada",
        "STATUS_CHANGE_FAILED": "No se pudo cambiar el estado. Por favor, asegúrese de que no haya conversaciones activas.",
        "TRANSFER_FAILED": "Error de transferencia. Por favor vuelva a intentarlo más tarde.",
        "TRANSFER_FAILED_AGENT": "Error de transferencia. Por favor asegúrese de que el agente esté en línea.",
        "TRANSFER_FAILED_QUEUE": "Error de transferencia. Por favor asegúrese de que los agentes en la cola están en línea.",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "Nueva conversación entrante",
            "NEW_CONVERSATION_MESSAGE": "Haga clic en esta notificación para navegar a la Consola de Agente Omnichannel",
            "NEW_MESSAGE_TITLE": "Nuevo mensaje recibido",
            "VISITOR": "Visitante"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Llamada perdida",
            "CALL_MISSED_SUBTITLE":"Ha perdido esta llamada. Ahora debe cerrar el Contacto.",
            "CLOSE_CONTACT":"Cerrar contacto",
            "CUSTOMER_NAME":"Nombre del cliente",
            "ORIGIN":"Origen",
            "QUEUE_JOURNEY":"Recorrido de la cola",
            "INTENT":"Propósito",
            "SUB_INTENT":"Propósito secundario",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Llamada entrante",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Cliente previamente desconectado",
            "INCOMING_WARM_TRANSFER":"Transferencia Caliente Entrante",
            "INCOMING_COLD_TRANSFER":"Transferencia Fría Entrante",
            "INCOMING_CALL_SUBTITLE":"Tiene una nueva llamada entrante",
            "ACCEPT":"Aceptar",
            "REJECT":"Rechazar",
            "REJECT_CONFIRMATION":"¿Confirma que desea rechazar esta llamada?",
            "CALL_REJECTED":"Llamada rechazada",
            "AFTER_REJECT":"Ha rechazado esta llamada. Ahora debe cerrar el Contacto.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "ID de contacto",
            "CONVERSATION_ID": "ID de conversación",
            "PHONE_NUMBER": "Administrar caso"
        },
        "CONNECTION_LOST": "Su conexión de red se ha perdido, por favor, compruebe la configuración de su conectividad.",
        "TOASTS":{
            "CONNECTION_LOST" : "Error de conexión a Websocket. Compruebe su conexión de red y actualice la página si los problemas persisten.",
            "CONNECTION_GAIN" : "Se restableció la conexión Websocket. Actualiza la página si los problemas persisten.",
            "CHAT_CLOSED_BY_CUSTOMER": "Conversación [Conversation ID] cerrada por cliente"
        },
        "SOFTPHONE_ERROR": {
            "connect-softphone-error-ice-collection-timeout-message": "mensaje ice_collection_timeout",
            "connect-softphone-error-ice-collection-timeout-title": "título ice_collection_timeout",
            "connect-softphone-error-invalid-number-message": "No se puede realizar la llamada con el número marcado. Inténtelo de nuevo o póngase en contacto con el administrador.",
            "connect-softphone-error-invalid-number-title": "Número no válido",
            "connect-softphone-error-invalid-outbound-configuration-message": "Para poder hacer una llamada saliente, debe asociar un número de teléfono a esta cola. Póngase en contacto con su administrador para obtener ayuda adicional.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Configuración de salida no válida",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "No se pudo establecer la conexión con el punto de conexión multimedia. Compruebe la conexión de red y póngase en contacto con el administrador.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Error al comprobar la conexión del punto de conexión multimedia",
            "connect-softphone-error-microphone-not-shared-message": "Su navegador no tiene acceso al micrófono",
            "connect-softphone-error-microphone-not-shared-title": "El micrófono no está accesible",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "Ha superado el límite máximo permitido de ventanas de CCP abiertas. Cierre la ventana con el CCP excedente y actualice la página para seguir trabajando.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Varias CCP abiertas",
            "connect-softphone-error-realtime-communication-error-message": "mensaje realtime_communication_error",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "No se pudo establecer la conexión con el teléfono virtual. Inténtelo de nuevo o póngase en contacto con el administrador.",
            "connect-softphone-error-signalling-connection-failure-title": "No se pudo realizar la llamada del teléfono virtual",
            "connect-softphone-error-signalling-handshake-failure-message": "No se pudo establecer la conexión con el teléfono virtual. Inténtelo de nuevo o póngase en contacto con el administrador.",
            "connect-softphone-error-signalling-handshake-failure-title": "No se pudo realizar la llamada del teléfono virtual",
            "connect-softphone-error-unsupported-browser-message": "Es posible que algunas funcionalidades no estén disponibles",
            "connect-softphone-error-unsupported-browser-title": "Este navegador no es compatible",
            "connect-softphone-error-unsupported-country-message": "Solo los números de #{countries} son válidos para las llamadas salientes. Póngase en contacto con el administrador para obtener ayuda adicional.",
            "connect-softphone-error-unsupported-country-title": "País no admitido",
            "connect-softphone-error-webrtc-error-message": "No se pudo realizar la llamada debido a un problema de WebRTC en el navegador.",
            "connect-softphone-error-webrtc-error-title": "Problema de WebRTC"
          }
          
          
    },
    it: {
        "MANAGER": "Manager",
        "NEW_NOTIFICATION_TITLE": "Nuova conversazione in entrata",
        "LOGOUT_ALERT": "Per favore, chiudi tutte le conversazioni attive prima di effettuare il logout",
        "QUEUE": "Coda",
        "SPECIALIST": "Esperto",
        "TRANSFER_NOTIFICATION_TITLE": "La conversazione è stata trasferita correttamente al",
        "CONVERSATION_CLOSED": "Conversazione Chiusa",
        "STATUS_CHANGE_FAILED": "Modifica dello stato non riuscita, verificare che non siano presenti conversazioni attive",
        "TRANSFER_FAILED": "Trasferimento non riuscito. Riprova più tardi.",
        "TRANSFER_FAILED_AGENT": "Trasferimento non riuscito. Assicurati che l'agente sia online.",
        "TRANSFER_FAILED_QUEUE": "Trasferimento non riuscito. Assicurati che i tecnici in coda siano online",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "Nuova conversazione in entrata",
            "NEW_CONVERSATION_MESSAGE": "Fai clic su questa notifica per accedere alla console agente Omnichannel",
            "NEW_MESSAGE_TITLE": "Nuovo messaggio ricevuto",
            "VISITOR": "Visitatore"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Chiamata persa",
            "CALL_MISSED_SUBTITLE":"Hai perso questa chiamata. A questo punto è necessario chiudere il contatto.",
            "CLOSE_CONTACT":"Chiudi contatto",
            "CUSTOMER_NAME":"Nome del cliente",
            "ORIGIN":"Origine",
            "QUEUE_JOURNEY":"Percorso coda",
            "INTENT":"Finalità",
            "SUB_INTENT":"Finalità secondaria",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Chiamata in arrivo",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Cliente precedentemente disconnesso",
            "INCOMING_WARM_TRANSFER":"Trasferimento in entrata",
            "INCOMING_COLD_TRANSFER":"Trasferimento freddo in entrata",
            "INCOMING_CALL_SUBTITLE":"Hai una nuova chiamata in arrivo.",
            "ACCEPT":"Accetta",
            "REJECT":"Rifiuta",
            "REJECT_CONFIRMATION":"Rifiutare questa chiamata?",
            "CALL_REJECTED":"Chiamata rifiutata",
            "AFTER_REJECT":"Hai rifiutato questa chiamata. A questo punto è necessario chiudere il contatto.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "ID contatto",
            "CONVERSATION_ID": "ID conversazione",
            "PHONE_NUMBER": "Gestisci caso"
        },
        "CONNECTION_LOST": "La tua connessione di rete é stata interrotta. Per favore, controlla le tue impostazioni di connessione",
        "TOASTS":{
            "CONNECTION_LOST" : "Errore di connessione Websocket. Controlli la sua connessione di rete e aggiorni la pagina se i problemi persistono.",
            "CONNECTION_GAIN" : "Connessione Websocket ristabilita. Aggiorna la pagina se i problemi persistono.",
            "CHAT_CLOSED_BY_CUSTOMER": "La conversación [ID de conversación] ha sido cerrada por el cliente"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout message",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout title",
            "connect-softphone-error-invalid-number-message": "Impossibile inoltrare la chiamata. Riprova o contatta il tuo amministratore.",
            "connect-softphone-error-invalid-number-title": "Numero non valido",
            "connect-softphone-error-invalid-outbound-configuration-message": "Prima di eseguire una chiamata in uscita, devi associare il numero di telefono a questa coda. Contatta il tuo amministratore per ricevere assistenza.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Configurazione in uscita non valida",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "Impossibile stabilire la connessione con l'endpoint multimediale. Verifica la connessione di rete, contatta l'amministratore.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Verifica della connessione dell'endpoint multimediale non riuscita",
            "connect-softphone-error-microphone-not-shared-message": "Il browser non può accedere al microfono",
            "connect-softphone-error-microphone-not-shared-title": "Il microfono non è accessibile",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "Hai superato il limite massimo consentito di finestre CCP aperte. Chiudi la finestra con il CCP in eccesso e aggiorna la pagina per continuare a lavorare.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Più CCP aperte",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error msg",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "Impossibile stabilire la connessione con il softphone. Riprova o contatta il tuo amministratore.",
            "connect-softphone-error-signalling-connection-failure-title": "Chiamata dal softphone non riuscita",
            "connect-softphone-error-signalling-handshake-failure-message": "Impossibile stabilire la connessione con il softphone. Riprova o contatta il tuo amministratore.",
            "connect-softphone-error-signalling-handshake-failure-title": "Chiamata dal softphone non riuscita",
            "connect-softphone-error-unsupported-browser-message": "Alcune funzionalità potrebbero non essere disponibili",
            "connect-softphone-error-unsupported-browser-title": "Questo browser non è supportato",
            "connect-softphone-error-unsupported-country-message": "Per le chiamate in uscita sono validi solo i numeri {countries}. Contatta il tuo amministratore per ricevere assistenza.",
            "connect-softphone-error-unsupported-country-title": "Paese non supportato",
            "connect-softphone-error-webrtc-error-message": "Chiamata non riuscita a causa di un problema WebRTC lato browser.",
            "connect-softphone-error-webrtc-error-title": "Problema WebRTC"
          }
          
          
    },
    'pt-BR': {
        "MANAGER": "Gerenciador",
        "NEW_NOTIFICATION_TITLE": "Recebendo nova conversa",
        "LOGOUT_ALERT": "Encerre todas as suas conversas antes de sair",
        "QUEUE": "Fila",
        "SPECIALIST": "Especialista",
        "TRANSFER_NOTIFICATION_TITLE": "Conversa transferida com sucesso para",
        "CONVERSATION_CLOSED": "Conversa Encerrada",
        "STATUS_CHANGE_FAILED": "Falha na mudança de status, verifique se não há conversas ativas",
        "TRANSFER_FAILED": "Transferência falhou! Tente daqui a um tempo",
        "TRANSFER_FAILED_AGENT": "Transferência falhou! Verifique se o agente está online.",
        "TRANSFER_FAILED_QUEUE": "Transferência falhou! Verifique se os agentes na fila estão online",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "Recebendo nova conversa",
            "NEW_CONVERSATION_MESSAGE": "Clique nesta notificação para navegar para o Console do Agente Omnichannel",
            "NEW_MESSAGE_TITLE": "Nova mensagem recebida",
            "VISITOR": "Visitante"
        },
        "TELEPHONY":{
            "CALL_MISSED":"Chamada perdida",
            "CALL_MISSED_SUBTITLE":"Você perdeu esta chamada. Agora você deve fechar o Contato.",
            "CLOSE_CONTACT":"Fechar contato",
            "CUSTOMER_NAME":"Nome do cliente",
            "ORIGIN":"Origem",
            "QUEUE_JOURNEY":"Histórico da fila (queue)",
            "INTENT":"Intenção",
            "SUB_INTENT":"Subintenção",
            "COUNTRY":"Country",
            "INCOMING_CALL":"Chamada recebida",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "Cliente anteriormente desconectado",
            "INCOMING_WARM_TRANSFER":"Transferência de Entrada Morna",
            "INCOMING_COLD_TRANSFER":"Transferência de Entrada Fria",
            "INCOMING_CALL_SUBTITLE":"Você tem uma nova chamada recebida.",
            "ACCEPT":"Aceitar",
            "REJECT":"Rejeitar",
            "REJECT_CONFIRMATION":"Tem certeza de que deseja rejeitar essa chamada?",
            "CALL_REJECTED":"Chamada rejeitada",
            "AFTER_REJECT":"Você rejeitou esta chamada. Agora você deve fechar o Contato.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "ID do contato",
            "CONVERSATION_ID": "ID da Conversa",
            "PHONE_NUMBER": "Phone Number"
        },
        "CONNECTION_LOST": "Sua conexão de rede foi perdida, verifique suas configurações de conectividade.",
        "TOASTS":{
            "CONNECTION_LOST" : "Erro de conexão do Websocket. Verifique sua conexão de rede e atualize a página se os problemas persistirem.",
            "CONNECTION_GAIN" : "Conexão Websocket restabelecida. Atualize a página se os problemas persistirem.",
            "CHAT_CLOSED_BY_CUSTOMER": "Conversa[Conversation ID] encerrada pelo cliente"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "Mensagem ice_collection_timeout",
            "connect-softphone-error-ice-collection-timeout-title": "Título ice_collection_timeout",
            "connect-softphone-error-invalid-number-message": "Não podemos completar a chamada conforme discado. Tente novamente ou entre em contato com o administrador.",
            "connect-softphone-error-invalid-number-title": "Número inválido",
            "connect-softphone-error-invalid-outbound-configuration-message": "Antes de fazer uma chamada de saída, você deve associar um número de telefone a esta fila. Entre em contato com seu administrador para receber ajuda.",
            "connect-softphone-error-invalid-outbound-configuration-title": "Configuração de saída inválida",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "Falha ao estabelecer conexão com o endpoint de mídia. Verifique sua conexão de rede e entre em contato com o administrador.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "Falha na verificação de conexão do endpoint de mídia",
            "connect-softphone-error-microphone-not-shared-message": "Seu navegador não tem acesso ao microfone.",
            "connect-softphone-error-microphone-not-shared-title": "O microfone não está acessível",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "Você excedeu o limite máximo permitido de janelas CCP abertas. Feche a janela com o CCP em excesso e atualize a página para continuar trabalhando.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "Várias CCPs abertas",
            "connect-softphone-error-realtime-communication-error-message": "Mensagem realtime_communication_error",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "Falha ao estabelecer a conexão do telefone virtual. Tente novamente ou entre em contato com o administrador.",
            "connect-softphone-error-signalling-connection-failure-title": "Falha na chamada do telefone virtual",
            "connect-softphone-error-signalling-handshake-failure-message": "Falha ao estabelecer a conexão do telefone virtual. Tente novamente ou entre em contato com o administrador.",
            "connect-softphone-error-signalling-handshake-failure-title": "Falha na chamada do telefone virtual",
            "connect-softphone-error-unsupported-browser-message": "Algumas funcionalidades podem não estar disponíveis",
            "connect-softphone-error-unsupported-browser-title": "Este navegador não é compatível",
            "connect-softphone-error-unsupported-country-message": "Somente os números de #{countries} são válidos para chamadas de saída. Entre em contato com seu administrador para receber ajuda.",
            "connect-softphone-error-unsupported-country-title": "País sem suporte",
            "connect-softphone-error-webrtc-error-message": "Falha na chamada devido a um problema de WebRTC do lado do navegador.",
            "connect-softphone-error-webrtc-error-title": "Problema com WebRTC"
          }
          
          
    },
    'zh-TW': {
        "MANAGER": "管理員",
        "NEW_NOTIFICATION_TITLE": "即將開始的新對話",
        "LOGOUT_ALERT": "請在登出前關閉所有進行中的對話",
        "QUEUE": "佇列",
        "SPECIALIST": "專家",
        "TRANSFER_NOTIFICATION_TITLE": "對話已成功轉移至",
        "CONVERSATION_CLOSED": "對話已結束",
        "STATUS_CHANGE_FAILED": "無法變更狀態，請確保沒有作用中的對話",
        "TRANSFER_FAILED": "轉移失敗！請稍後重試",
        "TRANSFER_FAILED_AGENT": "轉移失敗！請確保專員已上線。",
        "TRANSFER_FAILED_QUEUE": "轉移失敗！請確保在佇列中的專員皆已上線",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "即將開始的新對話",
            "NEW_CONVERSATION_MESSAGE": "按一下此通知前往 Omnichannel Agent Console",
            "NEW_MESSAGE_TITLE": "收到的新訊息",
            "VISITOR": "訪客"
        },
        "TELEPHONY":{
            "CALL_MISSED":"未接來電",
            "CALL_MISSED_SUBTITLE":"您有未接來電。您現在應關閉聯絡人。",
            "CLOSE_CONTACT":"關閉聯絡人",
            "CUSTOMER_NAME":"客戶姓名",
            "ORIGIN":"來源",
            "QUEUE_JOURNEY":"佇列歷程",
            "INTENT":"意圖",
            "SUB_INTENT":"子意圖",
            "COUNTRY":"Country",
            "INCOMING_CALL":"來電",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "先前斷線的客戶",
            "INCOMING_WARM_TRANSFER":"來電轉接-Warm",
            "INCOMING_COLD_TRANSFER":"來電轉接-Cold",
            "INCOMING_CALL_SUBTITLE":"您有一通新的來電。",
            "ACCEPT":"接受",
            "REJECT":"拒絕",
            "REJECT_CONFIRMATION":"確定要拒絕此通話？",
            "CALL_REJECTED":"已拒絕通話",
            "AFTER_REJECT":"您已拒絕此通話。您現在應關閉聯絡人。",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "聯絡案例 ID",
            "CONVERSATION_ID": "對話 ID",
            "PHONE_NUMBER": "管理個案"
        },
        "CONNECTION_LOST": "您的網絡連接已斷開，請檢查您的連接設置。",
        "TOASTS":{
            "CONNECTION_LOST" : "網絡套接字連接錯誤。檢查您的網絡連接，如果問題仍然存在，請刷新頁面。",
            "CONNECTION_GAIN" : "網絡套接字連接重新建立。如果問題持續存在，請刷新頁面。",
            "CHAT_CLOSED_BY_CUSTOMER": "對話 [Conversation ID] 已被客戶關閉"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout message",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout title",
            "connect-softphone-error-invalid-number-message": "我们无法完成拨号的呼叫。请重试或联系您的管理员。",
            "connect-softphone-error-invalid-number-title": "号码无效",
            "connect-softphone-error-invalid-outbound-configuration-message": "在进行出站呼叫之前，您必须将一个电话号码与此队列关联。请联系您的管理员以获得进一步帮助。",
            "connect-softphone-error-invalid-outbound-configuration-title": "出站配置无效",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "与媒体端点建立连接失败。请检查您的网络连接，与管理员联系。",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "媒体端点连接检查失败",
            "connect-softphone-error-microphone-not-shared-message": "您的浏览器无权访问麦克风",
            "connect-softphone-error-microphone-not-shared-title": "麦克风不可访问",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "您已超出允许打开的最大 CCP 窗口数量。请关闭 CCP 超限的窗口，然后刷新页面继续工作。",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "已打开多个 CCP",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error msg",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "未能建立软电话连接。请重试，或联系您的管理员。",
            "connect-softphone-error-signalling-connection-failure-title": "软电话呼叫失败",
            "connect-softphone-error-signalling-handshake-failure-message": "未能建立软电话连接。请重试，或联系您的管理员。",
            "connect-softphone-error-signalling-handshake-failure-title": "软电话呼叫失败",
            "connect-softphone-error-unsupported-browser-message": "某些功能可能不可用",
            "connect-softphone-error-unsupported-browser-title": "不支持此浏览器",
            "connect-softphone-error-unsupported-country-message": "只有 #{countries} 号码对出站呼叫有效。请联系您的管理员以获得进一步帮助。",
            "connect-softphone-error-unsupported-country-title": "不支持的国家/地区",
            "connect-softphone-error-webrtc-error-message": "呼叫失败，因为存在浏览器端 WebRTC 问题。",
            "connect-softphone-error-webrtc-error-title": "WebRTC 问题"
          }
          
    },
    'zh-CN': {
        "MANAGER": "经理",
        "NEW_NOTIFICATION_TITLE": "即将开始的聊天",
        "LOGOUT_ALERT": "请在注销前关闭所有进行中的对话",
        "QUEUE": "队列",
        "SPECIALIST": "专家",
        "TRANSFER_NOTIFICATION_TITLE": "对话成功转接到",
        "CONVERSATION_CLOSED": "对话已关闭",
        "STATUS_CHANGE_FAILED": "无法更改状态，请确保当前没有活动的对话",
        "TRANSFER_FAILED": "转接失败！请稍后重试",
        "TRANSFER_FAILED_AGENT": "转接失败！请确保客服专员当前在线。",
        "TRANSFER_FAILED_QUEUE": "转接失败！请确保队列中的客服专员当前在线",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "即将开始的聊天",
            "NEW_CONVERSATION_MESSAGE": "点击此通知，导航到 Omnichannel Agent Console",
            "NEW_MESSAGE_TITLE": "收到新信息",
            "VISITOR": "访客"
        },
        "TELEPHONY":{
            "CALL_MISSED":"未接来电",
            "CALL_MISSED_SUBTITLE":"您已错过了本次通话。您现在应该关闭联系人。",
            "CLOSE_CONTACT":"关闭联系人",
            "CUSTOMER_NAME":"客户姓名",
            "ORIGIN":"来源",
            "QUEUE_JOURNEY":"队列旅程",
            "INTENT":"意图",
            "SUB_INTENT":"子意图",
            "COUNTRY":"Country",
            "INCOMING_CALL":"来电",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "之前已断开连接的客户",
            "INCOMING_WARM_TRANSFER":"来电转接-Warm",
            "INCOMING_COLD_TRANSFER":"来电转接-Cold",
            "INCOMING_CALL_SUBTITLE":"您有一个新来电。",
            "ACCEPT":"接受",
            "REJECT":"拒绝",
            "REJECT_CONFIRMATION":"确定要拒绝本次通话？",
            "CALL_REJECTED":"通话被拒绝",
            "AFTER_REJECT":"您已拒绝了本次通话。您现在应该关闭联系人。",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "联系人 ID",
            "CONVERSATION_ID": "对话 ID",
            "PHONE_NUMBER": "管理案例"
        },
        "CONNECTION_LOST": "您的网络连接已断开，请检查您的连接设置。",
        "TOASTS":{
            "CONNECTION_LOST" : "Websocket 连接错误。检查您的网络连接，如果问题仍然存在，请刷新页面。",
            "CONNECTION_GAIN" : "Websocket 连接已重新建立。如果问题仍然存在，请刷新页面。",
            "CHAT_CLOSED_BY_CUSTOMER": "客户已关闭对话 [Conversation ID]"
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout 訊息",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout 標題",
            "connect-softphone-error-invalid-number-message": "我們無法完成您撥打的通話。請再試一次，或請聯絡您的管理員。",
            "connect-softphone-error-invalid-number-title": "無效號碼",
            "connect-softphone-error-invalid-outbound-configuration-message": "在您能夠進行外撥通話前，您必須將電話號碼與此佇列進行關聯。請聯絡管理員以尋求進一步協助。",
            "connect-softphone-error-invalid-outbound-configuration-title": "無效的外撥組態",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "無法建立與媒體端點的連線。請檢查您的網路連線，聯絡您的管理員。",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "媒體端點連線檢查失敗",
            "connect-softphone-error-microphone-not-shared-message": "您的瀏覽器無權存取麥克風",
            "connect-softphone-error-microphone-not-shared-title": "麥克風無法存取",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "您已超過允許開啟的 CCP 視窗上限。請關閉過多的 CCP 視窗，然後重新整理頁面以繼續工作。",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "多個開啟的 CCP",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error 訊息",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "無法建立軟體電話連線。請再試一次，或聯絡您的管理員。",
            "connect-softphone-error-signalling-connection-failure-title": "軟體電話通話失敗",
            "connect-softphone-error-signalling-handshake-failure-message": "無法建立軟體電話連線。請再試一次，或聯絡您的管理員。",
            "connect-softphone-error-signalling-handshake-failure-title": "軟體電話通話失敗",
            "connect-softphone-error-unsupported-browser-message": "有些功能可能無法使用",
            "connect-softphone-error-unsupported-browser-title": "不支援此瀏覽器",
            "connect-softphone-error-unsupported-country-message": "只有 #{countries} 號碼才可外撥通話。請聯絡管理員以尋求進一步協助。",
            "connect-softphone-error-unsupported-country-title": "不支援的國家/地區",
            "connect-softphone-error-webrtc-error-message": "通話失敗，因為發生瀏覽器端的 WebRTC 問題。",
            "connect-softphone-error-webrtc-error-title": "WebRTC 問題"
          }
          
    },
    ko: {
        "MANAGER": "관리자",
        "NEW_NOTIFICATION_TITLE": "새로운 수신 대화",
        "LOGOUT_ALERT": "로그아웃하기 전에 활성 대화를 모두 종료하십시오",
        "QUEUE": "대기열",
        "SPECIALIST": "전문가",
        "TRANSFER_NOTIFICATION_TITLE": "대화가 다음으로 이전되었습니다.",
        "CONVERSATION_CLOSED": "대화 종료됨",
        "STATUS_CHANGE_FAILED": "상태를 변경하지 못했습니다. 진행 중인 대화가 있는지 확인하십시오.",
        "TRANSFER_FAILED": "전송에 실패했습니다 잠시 후 다시 시도하십시오",
        "TRANSFER_FAILED_AGENT": "전송에 실패했습니다 상담사가 온라인 상태인지 확인하십시오.",
        "TRANSFER_FAILED_QUEUE": "전송에 실패했습니다 대기열에 있는 상담사가 온라인 상태인지 확인하십시오",
        "BROWSER_NOTIFICATIONS": {
            "NEW_CONVERSATION_TITLE": "새로운 수신 대화",
            "NEW_CONVERSATION_MESSAGE": "옴니채널 상담사 콘솔(OAC)로 이동하려면 이 알림을 클릭하십시오",
            "NEW_MESSAGE_TITLE": "새 메시지 수신됨",
            "VISITOR": "방문자"
        },
        "TELEPHONY":{
            "CALL_MISSED":"부재 중 전화",
            "CALL_MISSED_SUBTITLE":"부재 중 전화가 있습니다. 이 연락을 닫으세요.",
            "CLOSE_CONTACT":"연락 닫기",
            "CUSTOMER_NAME":"고객 이름",
            "ORIGIN":"연락 구분",
            "QUEUE_JOURNEY":"대기열 경로",
            "INTENT":"의도",
            "SUB_INTENT":"하위 의도",
            "COUNTRY":"Country",
            "INCOMING_CALL":"수신 전화",
            "PREVIOUSLY_DISCONNECTED_CUSTOMER": "이전에 연결이 끊긴 고객",
            "INCOMING_WARM_TRANSFER":"전송 수신 - 웜 트랜스퍼",
            "INCOMING_COLD_TRANSFER":"전송 수신 - 콜드 트랜스퍼",
            "INCOMING_CALL_SUBTITLE":"새로운 수신 전화가 있습니다.",
            "ACCEPT":"수락",
            "REJECT":"거부",
            "REJECT_CONFIRMATION":"이 통화를 거부하시겠습니까?",
            "CALL_REJECTED":"거부된 통화",
            "AFTER_REJECT":"이 통화를 거부했습니다. 이 연락을 닫으세요.",
            "LEFT_PARTICIPANT":"Participant has left the call.",
            "CONTACT_ID": "대화 ID",
            "CONVERSATION_ID": "Conversation ID",
            "PHONE_NUMBER": "사례 관리"
        },
        "CONNECTION_LOST": "네트워크 연결이 끊어졌습니다. 연결 설정을 확인하십시오.",
        "TOASTS":{
            "CONNECTION_LOST" : "웹 소켓 연결 오류입니다.네트워크 연결을 확인하고 문제가 지속되면 페이지를 새로 고치십시오.",
            "CONNECTION_GAIN" : "웹 소켓 연결이 다시 설정되었습니다.문제가 지속되면 페이지를 새로 고치십시오.",
            "CHAT_CLOSED_BY_CUSTOMER": "고객이 [Conversation ID] 대화를 종료했습니다."
        },
        "SOFTPHONE_ERROR":{
            "connect-softphone-error-ice-collection-timeout-message": "ice_collection_timeout message",
            "connect-softphone-error-ice-collection-timeout-title": "ice_collection_timeout title",
            "connect-softphone-error-invalid-number-message": "전화가 걸려오면 호출을 완료할 수 없습니다. 다시 시도하거나 관리자에게 문의하십시오.",
            "connect-softphone-error-invalid-number-title": "유효하지 않은 번호",
            "connect-softphone-error-invalid-outbound-configuration-message": "아웃바운드 호출을 하기 전에 이 대기열과 전화 번호를 연결해야 합니다. 추가 지원이 필요한 경우 관리자에게 문의하십시오.",
            "connect-softphone-error-invalid-outbound-configuration-title": "유효하지 않은 아웃바운드 구성",
            "connect-softphone-error-media-endpoint-connection-check-failure-message": "미디어 엔드포인트와의 연결을 설정하지 못했습니다. 네트워크 연결을 확인하고 관리자에게 문의하세요.",
            "connect-softphone-error-media-endpoint-connection-check-failure-title": "미디어 엔드포인트 연결 확인 실패",
            "connect-softphone-error-microphone-not-shared-message": "브라우저에서 마이크에 액세스할 수 없음",
            "connect-softphone-error-microphone-not-shared-title": "마이크에 액세스할 수 없음",
            "connect-softphone-error-multiple_softphone_active_sessions-message": "열려 있는 CCP 창의 최대 허용 한도를 초과했습니다. 작업을 계속하려면 초과된 CCP 창을 닫고 페이지를 새로 고치십시오.",
            "connect-softphone-error-multiple_softphone_active_sessions-title": "여러 CCP가 열려 있음",
            "connect-softphone-error-realtime-communication-error-message": "realtime_communication_error msg",
            "connect-softphone-error-realtime-communication-error-title": "realtime_communication_error",
            "connect-softphone-error-signalling-connection-failure-message": "소프트폰 연결 설정에 실패했습니다. 다시 시도하거나 관리자에게 문의하십시오.",
            "connect-softphone-error-signalling-connection-failure-title": "소프트폰 호출 실패",
            "connect-softphone-error-signalling-handshake-failure-message": "소프트폰 연결 설정에 실패했습니다. 다시 시도하거나 관리자에게 문의하십시오.",
            "connect-softphone-error-signalling-handshake-failure-title": "소프트폰 호출 실패",
            "connect-softphone-error-unsupported-browser-message": "일부 기능을 사용하지 못할 수 있음",
            "connect-softphone-error-unsupported-browser-title": "이 브라우저는 지원되지 않음",
            "connect-softphone-error-unsupported-country-message": "아웃바운드 호출에 대해 #{countries} 번호만 유효합니다. 추가 지원이 필요한 경우 관리자에게 문의하십시오.",
            "connect-softphone-error-unsupported-country-title": "지원되지 않는 국가",
            "connect-softphone-error-webrtc-error-message": "브라우저 측 WebRTC 문제로 인해 호출에 실패했습니다.",
            "connect-softphone-error-webrtc-error-title": "WebRTC 문제"
          }
          
          
          
    },
}
export default localization;

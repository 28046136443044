import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LibraryModule as AgentToolBar } from "oc-agent-toolbar-module-client";
import "oc-agent-toolbar-module-client/dist/index.css";
import ContactCenter from "../../../../Apps/ContactCenter/Modules/ContactCenter";
import { ContactRecord, iBannerInfo, iTransferData } from "../../../../Apps/ContactCenter/Models";
import env_variables from '../../../../config/env_variables';
import { CALLEVENTS } from "../../../ContactCenter/Constants"
import { SET_PDC_MESSAGE } from '../../../../redux/constants/pdcConstants'
import Utility from "../../../ContactCenter/Modules/Utility";
import localization from "../../Components/lang/localization";

declare const window: any

interface State {
  preferences: {
    darkMode: boolean
    language: string
    isVpnConnected: boolean
  },
  changeCustomer: {
    phoneNumber: number
  }
}
interface State {
  contactCenter: {
    currentConversation: ContactRecord
    currentConversationAC: ContactRecord
    agentDetails: any
    currentView: string
    transferSelection: any
    agentID: string
    conversations: ContactRecord[]
    dilableCountries: string[]
    bannerInfo: iBannerInfo
    recordingDisabled: any
    transferData: iTransferData
    bargedContactIds: {
      [contactId: string] : {
        contactId?: string
        isBarged?: boolean
      }
    }
  };
}


const ToolBar = () => {
  const { currentConversation, currentConversationAC, currentView, transferSelection, agentID, dilableCountries, bannerInfo, conversations, recordingDisabled,
     transferData = {}, bargedContactIds = {} } = useSelector((state: State) => state.contactCenter);
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation;
  const agentDetails = useSelector((state: State) => state.contactCenter.agentDetails);
  const { darkMode, language, isVpnConnected } = useSelector((state: State) => state.preferences);
  const { phoneNumber } = useSelector((state: State) => state.changeCustomer);
  const [transferQueue, setTransferQueue] = useState<any>();
  const [quickConnectConfiguration, setQuickConnectConfiguration] = useState<any>({ Queue: false, SecureIVR: false, Manager: false, VoicemailMessage: false, DialExtension: false });

  const dispatch = useDispatch();
  let cipNumber = phoneNumber;

  function callBackEventListener(eventType: string, eventData: any): void {
    
    const currentContactId = currentConversation?.ocPlatformData?.chatInfo?.currentContactId;
    
    if (eventType === 'menuActions') {
      if (eventData.type === 'close') {
        if (conversation && conversation.ocPlatformData.chatInfo.currentContactId) ContactCenter.closeContact(conversation.ocPlatformData.chatInfo.currentContactId)
      }
      if (eventData.type === CALLEVENTS.RECORDING_DISABLED) {
        // Utility.recordDisable();
      }
    }
    if (eventType === 'transfer') {
      ContactCenter.transferConversation(eventData)
      window.sendCTRItemToAmazonConnect(currentContactId)
      dispatch({
        type: 'SET_TRANSFER_IN_PROGRESS_ID',
        payload: currentContactId
      })
      dispatch({ type: SET_PDC_MESSAGE, payload: '' })
    }
    if (eventType === 'SET_TRANFER_SELECTION') {
      dispatch({
        type: 'SET_TRANFER_SELECTION',
        payload: eventData
      })
    }
    if(eventType === 'SETNOTIFICATION'){
      const data = localization[language].NOTIFICATIONS.TRANSCRIPT_COPIED
        dispatch({ type: 'SETNOTIFICATION', payload: { show: eventData.show, type: eventData.type, data } })
    }
    if (eventType === CALLEVENTS.CHAT_TO_PHONE || eventType === CALLEVENTS.OUTBOUND_PHONE) {
      const timerInfo = Utility.getActiveTimerInfo();
      const contactId = currentConversation?.ocPlatformData?.chatInfo?.initialContactId || '';
      if (contactId && !timerInfo[contactId]) {
        timerInfo[contactId] = {
          chatAgentStartTime: new Date(),
          voiceAgentStartTime: '',
          voiceAgentEndTime: ''
        }
      } else if (contactId && timerInfo[contactId]?.chatAgentStartTime) {
        timerInfo[contactId]['voiceAgentStartTime'] = new Date();
      } else if (!contactId && eventType === CALLEVENTS.OUTBOUND_PHONE) {
        timerInfo[CALLEVENTS.OUTBOUND_PHONE] = {
          chatAgentStartTime: '',
          voiceAgentStartTime: '',
          voiceAgentEndTime: ''
        }
      }
      Utility.setActiveTimerInfo(timerInfo);

      const metadata = {
        "origin": eventType,
        "linkedConversationId": contactId,
        "languageCode": currentConversation?.jcAuthData?.language?.value,
        "countryCode": eventData?.countryCode,
        "env": currentConversation?.jcAuthData?.env?.value,
        "stackSide": currentConversation?.jcAuthData?.stackSide?.value,
      }

      const common = {
        "customerEndpoint": eventData?.customerEndpoint,
        "agentId": agentID || agentDetails?.ldap,
        "metadata": metadata
      }

      const {
        jcAuthData: {
          customerId: { value: customerIdData = '' } = {},
          queueName: { value: queueNameData = '' } = {},
          currentQueue: { value: currentQueueData = '' } = {},
          queuePath: { value: queuePathData = '' } = {},
          conversationStartDate: { value: conversationStartDateData = '' } = {},
          transferCount: { value: transferCountData = '' } = {},
          firstName: { value: firstNameData = '' } = {},
          lastName: { value: lastNameData = '' } = {},
          email: { value: emailData = '' } = {},
          entitlementsList: { value: entitlementsListData = '' } = {},
          issueType: { value: issueTypeData = '' } = {},
          subissueType: { value: subIssueTypeData = '' } = {},
          botIntent: { value: botIntentData = '' } = {},
          botSubintent: { value: botSubIntentData = '' } = {},
          productCode: { value: productCodeData = '' } = {},
          phone: { value: phoneData = '' } = {},
        } = {},
      } = currentConversation;

      const chatToPhoneApiData = {
        ...common,
        "metadata": {
          ...metadata,
          "customerId": customerIdData,
          "queueName": queueNameData,
          "currentQueue": currentQueueData,
          "queuePath": queuePathData,
          "conversationStartDate": conversationStartDateData,
          "transferCount": transferCountData,
          "firstName": firstNameData,
          "lastName": lastNameData,
          "email": emailData,
          "entitlementsList": entitlementsListData,
          "issueType": issueTypeData,
          "subissueType": subIssueTypeData,
          "botIntent": botIntentData,
          "botSubintent": botSubIntentData,
          "productCode": productCodeData,
          "phone": phoneData
        }
      }

      const outBoundApiData = {
        ...common,
        "metadata": {
          ...metadata,
          "customerId": eventData?.customerEndpoint,
        }
      }

      let data = {};
      if (eventType === CALLEVENTS.CHAT_TO_PHONE) {
        data = chatToPhoneApiData;
      } else if (eventType === CALLEVENTS.OUTBOUND_PHONE) {
        data = outBoundApiData;
      }

      const storeData = {
        ...eventData,
        customerId: eventData?.customerEndpoint,
        callStartTime: new Date().toISOString()
      }
      dispatch({ type: 'SET_TOOLBAR_CUSTOM_SELECTION', payload: storeData });
      dispatch({ type: 'INCOMING_CALL', payload: { openPopup: "no" } });
      ContactCenter.outboundCall(data, storeData)
    }
    if (eventType === CALLEVENTS.HOLD_CALL) {
      Utility.handleHoldResume({ isHold: eventData.hold || false })
    }
    if (eventType === CALLEVENTS.MUTE_CALL) {
      ContactCenter.muteCall(eventData)
    }
    if (eventType === CALLEVENTS.END_CALL) {
      ContactCenter.endCall(true);
    }
    if (eventType === CALLEVENTS.WARM_TRANSFER_CALL) {
      window.sendCTRItemToAmazonConnect(currentContactId)
      Utility.warmTransferVoiceCall(eventData)
    }
    if (eventType === CALLEVENTS.COLD_TRANSFER_CALL) {
      window.sendCTRItemToAmazonConnect(currentContactId)
      Utility.coldTransferVoiceCall(eventData)
    }
    if (eventType === CALLEVENTS.JOIN_CALL) {
      Utility.joinVoiceCall(eventData)
    }
    if (eventType === CALLEVENTS.DTMF_INPUT) {
      ContactCenter.dtmfInput(eventData.input)
    }
    if (eventType === CALLEVENTS.EMPTY_CIP_NUMBER) {
      cipNumber = 0
      dispatch({ type: "CLICK_PHONE_NUMBER", payload: 0 })
    }
    if (eventType === CALLEVENTS.SWAP_CALL) {
      Utility.swapCall();
    }
  }

  let isDialpadOpen = true;
  if (conversation?.ocPlatformData?.chatInfo?.currentContactId) {
    const activeCall = conversations.find((c: ContactRecord) => c?.ocPlatformData?.callInfo?.status === "Active");
    if (activeCall) {
      const activeCallContactId = activeCall?.ocPlatformData?.chatInfo?.currentContactId;
      const currentContactId = conversation?.ocPlatformData?.chatInfo?.currentContactId;
      isDialpadOpen = (activeCallContactId === currentContactId);
    }
  }

  useEffect(() => {
    const newQueueList = [...agentDetails.skills].filter((i) => (i?.name !== "Secure IVR" && i?.name !== "Voicemail Message" && i?.name !== "SLS-Agent-Transfer")).map((c: any) => ({ ...c, status: 'NA' }));
    const temp = {
      Queue: newQueueList,
      Manager: [...agentDetails.quickConnects].map((c: any) => ({ ...c, status: 'Online' })),
      Specialist: []
    }

    const Queue = !temp.Queue.length ? true : false;
    const SecureIVR = agentDetails?.skills?.find((q) => q?.type === 'queue' && q?.name === "Secure IVR") ? true : false;
    const VoicemailMessage = agentDetails?.skills?.find((q) => q?.type === 'queue' && q?.name === "Voicemail Message") ? true : false;
    const DialExtension = agentDetails?.skills?.find((q) => q?.type === 'queue' && q?.name === "SLS-Agent-Transfer") ? true : false;
    const Manager = !temp.Manager.length ? true : false;
    setQuickConnectConfiguration({ Queue, SecureIVR, Manager, VoicemailMessage, DialExtension });
    setTransferQueue(temp)
  }, [agentDetails?.quickConnects, agentDetails?.skills]);
  
  const outboundQueues = agentDetails?.outboundQueues || [];

  try {
    let customerId = currentConversation?.jcAuthData?.customerId?.value;
    const contactId = conversation?.ocPlatformData?.chatInfo?.currentContactId || '';
    let token: string = '';
    if (localStorage.getItem('ims-token')) {
      token = JSON.parse(localStorage.getItem('ims-token') || '')
    }
    let json: any = {
      lang: language, darkMode: darkMode, endpoint: env_variables.CCP_API_END_POINT, chatSelected: isDialpadOpen, transferQueue: transferQueue,
      agentId: agentDetails.ldap, customerId: customerId, currentConversation: conversation, token: token, transferSelection, apiKey: 'oac-connect-service', outboundQueues: outboundQueues,
      phoneNumber: cipNumber, dilableCountries: dilableCountries, bannerInfo, quickConnectConfiguration, isVpnConnected, recordingDisabledSuccess: recordingDisabled?.success,
      allActiveTransferData: transferData, isBarged: bargedContactIds[contactId]?.isBarged || false
    };

    return (
      <>
        <AgentToolBar contextData={json} callBackEvent={callBackEventListener} />
      </>
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default ToolBar
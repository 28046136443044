import React, { useState } from 'react'
import './NavigationMenu.scss'
import { LibraryModule as NavBar } from 'oc-navbar-module-client'
import 'oc-navbar-module-client/dist/index.css'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { useOktaAuth } from '@okta/okta-react'
import { triggerNavBar } from '../../redux/actions/navBarActions'
import localization from '../../Apps/Chat/Components/lang/localization'
import { DialogContainer, AlertDialog } from '@adobe/react-spectrum'
import {
  triggerDarkMode,
  triggerSound,
  changeLanguage,
  updateLastAgentStatusOnVpn,
  updateActiveContactAvailableStatus,
  showLoaderOnOAC
} from '../../redux/actions/preferencesActions'
import audiofile from '../../assets/alert.wav'
import ContactCenter from '../../Apps/ContactCenter/Modules/ContactCenter'
import { sendLogoutAnalytics, sendSoundAnalytics, sendThemeAnalytics, sendUserStatus } from '../../helpers/analytics-helpers'
import { getReleaseNotesPDF } from '../../Apps/ContactCenter/Api/api'
import env_variables from '../../config/env_variables'

const RINGER_DEVICE = 'ringerDevice'
const DOWNLOAD_CCP_LOGS = 'download-ccp-logs'
const CLOSED_STATUS = 'Closed'
interface State {
  navBar: {
    navBarToggles: { chatToggle: boolean; historyToggle: boolean }
  }
  preferences: {
    darkMode: boolean
    language: string
    sound: boolean
    isVpnConnected: boolean
    ringerDeviceDetails: {
      ringerDevice: string
      ringerDevices: any[]
    }
    releaseNotes: any
  }
  contactCenter: {
    CCPStatus: {
      isInit: boolean
      ccpState: string
    }
    agentDetails: {
      agentName: string
      routingProfile: string
      ldap: string
      email: string
      skills: string[]
      states: string[]
      quickConnects: any
      averageCsat: string
    }
    newMessageIndicator: boolean
    agentFeatureTags: any
    conversations: any
  }
}

const NavigationMenu = () => {
  const history = useHistory()
  const location = useLocation()
  const dispatch = useDispatch()
  const { navBarToggles } = useSelector((state: State) => state.navBar)
  const [isOpen, setIsOpen] = useState(false)
  const { oktaAuth } = useOktaAuth()
  const { darkMode, language, sound, isVpnConnected, ringerDeviceDetails, releaseNotes } = useSelector((state: State) => state.preferences)
  const { ccpState, isInit } = useSelector((state: State) => state.contactCenter.CCPStatus)
  const { agentName, routingProfile, states, averageCsat, ldap } = useSelector((state: State) => state.contactCenter.agentDetails)
  const { newMessageIndicator, agentFeatureTags, conversations } = useSelector((state: State) => state.contactCenter)
  const [activeConversationAvailable, setActiveConversationAvailable] = useState(false)
  const [releaseVersion, updateReleaseNoteVersion] = useState('')
  React.useEffect(() => {
    console.log('ReleaseVersion ', releaseNotes)
    updateReleaseNoteVersion(releaseNotes.version)
  }, [releaseNotes])

  React.useEffect(() => {
    if (location.pathname === '/history' && !navBarToggles.historyToggle) dispatch(triggerNavBar(navBarToggles, { history: true }))
    else if (location.pathname === '/chat') dispatch(triggerNavBar(navBarToggles, { chat: true }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  React.useEffect(() => {
    let activeConversationAvailable = false,
      activeChatAvailable = false,
      activeCallAvailable = false
    conversations.length &&
      conversations.forEach((eachConv) => {
        if (!activeChatAvailable) {
          activeChatAvailable = eachConv?.ocPlatformData?.chatInfo?.status ? (eachConv.ocPlatformData.chatInfo.status === CLOSED_STATUS ? false : true) : false
        }
        if (!activeCallAvailable) {
          activeCallAvailable = eachConv?.ocPlatformData?.callInfo?.status ? (eachConv.ocPlatformData.callInfo.status === CLOSED_STATUS ? false : true) : false
        }
        if (!activeConversationAvailable) {
          activeConversationAvailable = activeCallAvailable || activeChatAvailable ? true : false
        }
      })
    dispatch(updateActiveContactAvailableStatus({ voice: activeCallAvailable, chat: activeChatAvailable }))
    setActiveConversationAvailable(activeConversationAvailable)
  }, [conversations])

  const [audio] = useState(new Audio(audiofile))

  async function callBackEventListener(eventType: string, eventData: any): Promise<void> {
    try {
      if (eventType === 'changeMode') {
        // send theme analytics
        sendThemeAnalytics(eventData)
        dispatch(triggerDarkMode(darkMode, eventData))
      } else if (eventType === 'click') {
        if (eventData.help) {
          window.open('https://helpx-internal.corp.adobe.com/content/help/en/internal/individual/policy-process/omni-channel-support-guidelines.html', '_blank')
          return
        }
        if (eventData.translate) {
          window.open('https://console.aws.amazon.com/translate/home?region=us-east-1#translation', '_blank')
          return
        }
        if (eventData.translate) {
          window.open('https://console.aws.amazon.com/translate/home?region=us-east-1#translation', '_blank')
          return
        }
        if (eventData.openReleaseNotes) {
          dispatch(showLoaderOnOAC(true))
          const response: any = await getReleaseNotesPDF(releaseVersion)
          dispatch(showLoaderOnOAC(false))
          if (response?.success && response?.result?.data) {
            const pdfUrl = URL.createObjectURL(new Blob([response.result.data], { type: 'application/pdf' }))
            window.open(pdfUrl, '_blank')
          }
        }
        dispatch(triggerNavBar(navBarToggles, eventData))
        if (eventData.chat) {
          dispatch({ type: 'SETNEWMESSAGEINDICATOR', payload: false })
          history.push(`/chat${location.search}`)
        } else if (eventData.history) history.push(`/history${location.search}`)
      } else if (eventType === 'logout') {
        if (ContactCenter.isLogoutAllowed()) {
          await ContactCenter.logOut()
          // send logout analytics success event
          sendLogoutAnalytics({
            type: 'success'
          })
          oktaAuth.tokenManager.clear()
          // create a new item in localStorage to determine agent logout 
          localStorage.setItem('agent-logout-flag', 'true')
          // clear IMS token
          localStorage.removeItem('ims-token')
          localStorage.removeItem('thisAgentTimer')
          localStorage.removeItem('translation-enable-ids')
          localStorage.removeItem('conversationSummarySkills')
          localStorage.removeItem('conversationSummaries')
          localStorage.removeItem('adobeAnswersSkills')
          history.push('/logout')
        } else {
          // send logout analytics failed event
          sendLogoutAnalytics({
            type: 'failure'
          })
        }
        // eventData={status:AGENT_OFFLINE_STATUS}
        // dispatch(setCurrentStatus(eventData))
        // await oktaAuth.signOut();
      } else if (eventType === 'toggleSound') {
        // send sound analytics
        sendSoundAnalytics(eventData)
        dispatch(triggerSound(sound, eventData))
        if (eventData.sound) {
          audio.play()
        }
        //Block agent manually change the status if VPN is disconnected
      } else if (eventType === 'setStatus') {
        if (isVpnConnected) {
          dispatch(updateLastAgentStatusOnVpn(eventData.status))
          sendUserStatus(eventData)
          ContactCenter.setStatus(eventData.status)
        } else {
          setIsOpen(true)
        }
      } else if (eventType === 'changeLocale') {
        dispatch(changeLanguage(language, eventData))
      } else if (eventType === DOWNLOAD_CCP_LOGS) {
        ContactCenter.downloadCCPLogs()
      } else if (eventType === RINGER_DEVICE) {
        ContactCenter.setRingerDevicesDetails({ ringerDevice: eventData.deviceId })
      }
    } catch (error) {
      console.log(error)
    }
  }
  try {
    const data: any = {
      agentDetails: {
        agentName,
        nickName: routingProfile,
        agentId: ldap
      },
      currentStatus: ccpState,
      ccpInit: isInit,
      status: [
        {
          name: 'StartOfDay',
          value: 'Start of Day',
          languagList: 1
        },
        {
          name: 'Online',
          value: 'Available',
          languagList: 2
        }
      ],
      away: {
        name: 'Away',
        value: 'Away',
        // list: ["ACW AUX State", "ACW Time", "Case/Email", "Bomgar/Tech Support", "Break", "Coach/TRNBL", "EOS", "Lunch", "MEET/Non-Bill", "MENTOR/One UP", "OUTBOUND", "PRNSL", "Sales Wrap"],
        list: states
      },
      profileStatusData: {
        'Start of Day': 'neutral',
        Online: 'positive',
        Available: 'positive',
        Away: 'notice',
        Pending: 'notice',
        CallingCustomer: 'notice',
        Missed: 'negative',
        MissedCallAgent: 'negative',
        Default: 'negative',
        Busy: 'positive',
        AfterCallWork: 'positive'
      }
    }

    for (let x = 0; x < states.length; x++) {
      data.profileStatusData[states[x]] = 'notice'
    }

    return (
      <>
        <NavBar
          contextData={{
            data: 'Props from container APP',
            chatToggle: navBarToggles.chatToggle,
            historyToggle: navBarToggles.historyToggle,
            darkMode,
            lang: language,
            agentStatusData: data,
            csatScore: averageCsat,
            notificationBadge: newMessageIndicator,
            sound,
            agentFeatureTags,
            activeConversationAvailable,
            ringerDevice: ringerDeviceDetails?.ringerDevice || '',
            ringerDevices: ringerDeviceDetails?.ringerDevices || [],
            version: releaseVersion || '',
            agentConsoleServiceApiEndPoint: env_variables.CCP_API_END_POINT
          }}
          callBackEvent={callBackEventListener}
        />
        {
          <DialogContainer onDismiss={() => setIsOpen(false)} type='modal'>
            {isOpen && (
              <AlertDialog title='Warning' variant='error' primaryActionLabel='Close'>
                {localization[language].NOTIFICATIONS.BLOCK_MANUAL_STATUS_CHANGE}
              </AlertDialog>
            )}
          </DialogContainer>
        }
      </>
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default NavigationMenu

/*eslint-disable */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import analyticUser from './analytics-user'
import { sendOACWidgetToggleEvents } from './analytics-helpers';
import { getCurrentConvDetails, getInitialConvId } from '../utils/conversation-utils'
declare const window: any

window.onRenderCB = onRenderCB;

/**
 * To capture click event, this is for chat open upon clicking
 * @param id
 */
export function sendWidgetAnalyticEvent() {
  enableWidgetFlags()
  window.analytics = {}
}

/**
 * 
 * To Capture the page refresh event
 */
 window.agentPageRefresh = function (data) {
  sendNewrelicCustomEvent({
    loadTime: 0, // its not the widget load event
    url: window.location.href,
    type: 'AgentPageRefreshTrack',
    eventWorkflow: 'PageRefresh',
    ...data
  })
}

/**
 * To capture the time taken to login
 */
window.agentLoginTimeTracking = function (data) {
  sendNewrelicCustomEvent({
    loadTime: 0,  // its not the widget load event
    type: 'LoginTimeTrack',
    eventWorkflow: 'AgentLogin',
    ...data
  })
}

/**
 * To capture the time taken to login
 */
window.sendPDCEventsToNewrelic = function (data) {
  sendNewrelicCustomEvent({
    loadTime: 0,
    type: 'PDC events',
    eventWorkflow: 'Conversation',
    ...data
  })
}

/**
 * To capture the widget load time
 * @param id
 * @param phase
 * @param actualDuration
 * @param baseDuration
 */
window.trackWidgetPerformance = function (id: string, phase: string, actualDuration: number, baseDuration: number, convId: string, loadTime: number) {
  sendNewrelicCustomEvent({
    contentId: id,
    eventSubtype: phase,
    customConvId: convId,
    loadTime,
    type: 'OACWidgetLoadTime',
    eventWorkflow: 'WidgetPerformance',
  })
}

export function onRenderCB(id: string, phase: string, actualDuration: number, baseDuration: number) {
  const convId = getInitialConvId();

  if (id === 'CH' && window.enable_CH === false) return
  if (id === 'CCP' && window.enable_CCP === false) return
  if (id === 'CIP' && window.enable_CIP === false) return
  if (id === 'SCIP' && window.enable_SCIP === false) return
  if (id === 'CCM' && window.enable_CCM === false) return
  if (id === 'ECM' && window.enable_ECM === false) return
  if (id === 'PrevConvs' && window.enable_PrevConvs === false) return
  if (id === 'PDC' && window.enable_PDC === false) return
  if (id === 'TCP' && window.enable_TCP === false) return

  window.analytics[`${convId}`] = window.analytics[`${convId}`] || {}
  if (phase?.includes('mount')) {
    // send dunamis OAC widget render on load
    sendOACWidgetToggleEvents({
      value: id,
      initialConvId: convId
    })

    switch (id) {
      case 'CH':
        window.analytics[`${convId}`].CH_MOUNT_TIMESTAMP = new Date()
        break
      case 'CCP':
        window.analytics[`${convId}`].CCP_MOUNT_TIMESTAMP = new Date()
        break
      case 'CIP':
        window.analytics[`${convId}`].CIP_MOUNT_TIMESTAMP = new Date()
        break
      case 'SCIP':
        window.analytics[`${convId}`].SCIP_MOUNT_TIMESTAMP = new Date()
        break
      case 'CCM':
        window.analytics[`${convId}`].CCM_MOUNT_TIMESTAMP = new Date()
        break
      case 'ECM':
        window.analytics[`${convId}`].ECM_MOUNT_TIMESTAMP = new Date()
        break
      case 'PrevConvs':
        window.analytics[`${convId}`].PREVCONVS_MOUNT_TIMESTAMP = new Date()
        break
      case 'PDC':
        window.analytics[`${convId}`].PDC_MOUNT_TIMESTAMP = new Date()
        break
      case 'TCP':
        window.analytics[`${convId}`].TCP_MOUNT_TIMESTAMP = new Date()
        break
      default:
        break
    }
  }

  if (phase?.includes('update') && window.analytics[`${convId}`]) {
    switch (id) {
      case 'CH':
        window.analytics[`${convId}`].CH_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.CH_LOAD_TIME !== undefined) return

          window.enable_CH = false
          const CH_LOAD_TIME = window.analytics[`${convId}`]?.CH_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.CH_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, CH_LOAD_TIME)
          convId && (window.analytics[`${convId}`].CH_LOAD_TIME = CH_LOAD_TIME)
        }, 5000)
        break
      case 'CCP':
        window.analytics[`${convId}`].CCP_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.CCP_LOAD_TIME !== undefined) return

          window.enable_CCP = false
          const CCP_LOAD_TIME = window.analytics[`${convId}`]?.CCP_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.CCP_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, CCP_LOAD_TIME)
          convId && (window.analytics[`${convId}`].CCP_LOAD_TIME = CCP_LOAD_TIME)
        }, 5000)
        break
      case 'CIP':
        window.analytics[`${convId}`].CIP_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.CIP_LOAD_TIME !== undefined) return

          window.enable_CIP = false
          window.enable_SCIP = false
          const CIP_LOAD_TIME = window.analytics[`${convId}`]?.CIP_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.CIP_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, CIP_LOAD_TIME)
          convId && (window.analytics[`${convId}`].CIP_LOAD_TIME = CIP_LOAD_TIME)
        }, 5000)
        break
      case 'PrevConvs':
        window.analytics[`${convId}`].PREVCONVS_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.PREVCONVS_LOAD_TIME !== undefined) return

          window.enable_PrevConvs = false
          const PREVCONVS_LOAD_TIME = window.analytics[`${convId}`]?.PREVCONVS_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.PREVCONVS_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, PREVCONVS_LOAD_TIME)
          convId && (window.analytics[`${convId}`].PREVCONVS_LOAD_TIME = PREVCONVS_LOAD_TIME)
        }, 5000)
        break
      case 'PDC':
        window.analytics[`${convId}`].PDC_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.PDC_LOAD_TIME !== undefined) return

          window.enable_PDC = false
          const PDC_LOAD_TIME = window.analytics[`${convId}`]?.PDC_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.PDC_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, PDC_LOAD_TIME)
          convId && (window.analytics[`${convId}`].PDC_LOAD_TIME = PDC_LOAD_TIME)
        }, 5000)
        break
      case 'SCIP':
        window.analytics[`${convId}`].SCIP_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.SCIP_LOAD_TIME !== undefined) return

          window.enable_SCIP = false
          window.enable_CIP = false
          const SCIP_LOAD_TIME = window.analytics[`${convId}`]?.SCIP_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.SCIP_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, SCIP_LOAD_TIME)
          convId && (window.analytics[`${convId}`].SCIP_LOAD_TIME = SCIP_LOAD_TIME)
        }, 5000)
        break
      case 'CCM':
        window.analytics[`${convId}`].CCM_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.CCM_LOAD_TIME !== undefined) return

          window.enable_CCM = false
          const CCM_LOAD_TIME = window.analytics[`${convId}`]?.CCM_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.CCM_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, CCM_LOAD_TIME)
          convId && (window.analytics[`${convId}`].CCM_LOAD_TIME = CCM_LOAD_TIME)
        }, 5000)
        break
      case 'ECM':
        window.analytics[`${convId}`].ECM_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.ECM_LOAD_TIME !== undefined) return

          window.enable_ECM = false
          const ECM_LOAD_TIME = window.analytics[`${convId}`]?.ECM_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.ECM_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, ECM_LOAD_TIME)
          convId && (window.analytics[`${convId}`].ECM_LOAD_TIME = ECM_LOAD_TIME)
        }, 5000)
        break
      case 'TCP':
        window.analytics[`${convId}`].TCP_LAST_UPDATE_TIMESTAMP = new Date()
        setTimeout(() => {
          if (!window.analytics[`${convId}`]) return
          if (window.analytics[`${convId}`]?.TCP_LOAD_TIME !== undefined) return

          window.enable_TCP = false
          const TCP_LOAD_TIME = window.analytics[`${convId}`]?.TCP_LAST_UPDATE_TIMESTAMP - window.analytics[`${convId}`]?.TCP_MOUNT_TIMESTAMP
          window.trackWidgetPerformance(id, phase, actualDuration, baseDuration, convId, TCP_LOAD_TIME)
          convId && (window.analytics[`${convId}`].TCP_LOAD_TIME = TCP_LOAD_TIME)
        }, 5000)
        break
      default:
        break
    }
  }
}

function enableWidgetFlags() {
  window.enable_widget_analytics = true
  window.enable_PDC = true
  window.enable_ECM = true
  window.enable_CCM = true
  window.enable_CIP = true
  window.enable_SCIP = true
  window.enable_CH = true
  window.enable_CCP = true
  window.enable_TCP = true
  window.enable_PrevConvs = true
}

window.widgetLoadEvent = function (id: string) {
  return new CustomEvent('widget-load', { detail: { id } })
}

async function sendNewrelicCustomEvent(payload: any) {
  if (payload.loadTime === NaN || payload.loadTime === null) return;

  const { currentContactId, initialContactId, origin, queue, ldap, email } = getCurrentConvDetails();
  const defaultPayload = {
    origin,
    queue,
    ldap,
    email,
    initialContactId,
    currentContactId,
    eventTimeStamp: new Date().toISOString(),
    sourcePlatform: window.navigator.platform,
    eventUser_agent: window.navigator.userAgent,
    env: process.env.REACT_APP_NODE_ENV || 'local',
    eventLanguage: window.navigator.language || 'en-US',
    sessionId: ldap + "-" + analyticUser.sessionId,
  }

  window.newrelic && window.newrelic.addPageAction('OACPerformanceTracking', { ...payload, ...defaultPayload });
}

import { sendChatEvents } from '../helpers/analytics-helpers';
import { getCurrentQueue } from './conversation-utils'

declare const window: any;

export function getCopiedAdobeAnswer(conversationId) {
    // Read the adobe answers data from window object
    window.dunamis_analytics = window.dunamis_analytics || {};
    window.dunamis_analytics.adobeanswers = window.dunamis_analytics.adobeanswers || {};

    // answerObj format - { answer: answerForAnalytics, assistId: assistIdForAnalytics }
    const answerObj = window.dunamis_analytics.adobeanswers[conversationId] || null;

    delete window.dunamis_analytics.adobeanswers[conversationId];

    return answerObj;
}

export function compareAdobeAnswerAndCCPMessage(copiedAnswer, ccpMessage, assistId) {
    const trimedCopiedAdobeAnswer = copiedAnswer.trim();
    const trimedCCPMessage = ccpMessage.trim()
    const isCCPMsgEdited = !(trimedCopiedAdobeAnswer === trimedCCPMessage) || false;

    sendChatEvents({ 
        'content.name': assistId,
        'content.type': getCurrentQueue(),
        subCategory: 'Adobe Answers',
        subType: 'send',
        value: isCCPMsgEdited ? 'edit' : 'no-edit'
    })
}
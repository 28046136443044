/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AdobeAnswersPanel from '../AdobeAnswersPanel/AdobeAnswersPanel'
import CaseManagement from '../CaseManagement/CaseManagement'
import EnterpriseCaseManagement from '../EnterpriseCaseManagement/EnterpriseCaseManagement'
import RecommendedUpgradePanel from './panel/RecommendedUpgradePanel'
import './RUContainer.css'
import env from '../../../../config/env_variables'
import { URLS } from './RUModels'
import { RootState } from '../../../../redux/reducers/rootReducer'
import { CHANNEL_MESSAGING, CHANNEL_VOICE, CHAT_TO_PHONE, INBOUND_PHONE, OUTBOUND_PHONE, BOT_TO_PHONE, CLOSED } from './RUConstants'
import useContextData from '../../Hooks/useContextData'
import getCustomer from './api'
import placeholder from '../../../../Theme/Styles/Icons/placeholder.png'

const phoneOrigin = [INBOUND_PHONE, OUTBOUND_PHONE, BOT_TO_PHONE];

const RUContainer = () => {
  const contextData: any = useContextData()
  
  const [widgetBottom, setWidgetBottom] = useState(null)
  const [customerRUId, setCustomerRUId] = useState<{[convId: string]: string}>({})
  const [isMultipartyAssignedAgent, setIsMultipartyAssignedAgent] = useState(false)
  const [isVoiceContact, setIsVoiceContact] = useState(true);
  const [details, setDetails] = useState(true);
  
  const { currentConversation } = useSelector((state: RootState) => state.contactCenter)
  const { darkMode, language } = useSelector((state: RootState) => state.preferences)
  const { ruExpanded, ruWidgetBottom, ruWidgetBottomShow, collapseRUDetailsFromOAC } = useSelector((state: any) => state.widgetLayout)
  const { showAdobeAnswers } = useSelector((state: RootState) => state.contactCenter)
  const {changeCustomerToggle } =  useSelector((state: RootState) => state.changeCustomer)
  
  const {
    ocPlatformData: {
      chatInfo: { initialContactId = ''} = {},
      agentInfo: { agentLDAP = '' } = {}
    } = {},
    jcAuthData: {currentQueue: { value: currentQueue = '' } = {}, customerId: { value: customerId  = ''} = {}} = {}
  } = currentConversation
  
  const urls: URLS = {
    PERSONALIZATION_DOMAIN: env.PERSONALIZATION_DOMAIN,
    HENDRIX_DOMAIN: env.HENDRIX_DOMAIN
  }
  
  const ruManagementTopClass = darkMode ? `ru-management-top-dark` : `ru-management-top`
  const ruManagementBasementClass = darkMode ? `ru-management-basement-dark` : `ru-management-basement`

  const {
    jcAuthData: {
      channel: channelType = ''
    } = {},
    ocPlatformData: {
      transferInProgressId = '',
      callInfo: {
        status: callStatus = '',
        assignedAgent: multipartyAssignedAgent = undefined,
        multipleAgentsConnected = undefined,
        origin = '',
        currentContactId: callCurrentContactId = '',
      } = {},
      chatInfo: {
        currentContactId: chatCurrentContactId = '',
        status: chatStatus = '',
      } = {}
    } = {},
    ocAppData: {
      closedConversation = true,
      closedPhoneCall = true,
      originForCurrentConversation = '',
      modifiedCustomer: {
        rengaId = '',
      } = {},
    } = {}
  } = contextData
  const currentConvId = chatCurrentContactId || callCurrentContactId;

  const contextRUModuleData: any = {
    customerId: customerRUId[initialContactId],
    convId: initialContactId,
    currentConvId: fetchCurrentContactId(),
    queueName: currentQueue,
    agentDetails: { ldap: agentLDAP },
    darkMode,
    language,
    urls,
    channel: isVoiceContact ? CHANNEL_VOICE : CHANNEL_MESSAGING,
    assignedAgent: isMultipartyAssignedAgent,
    isVoiceContact,
    chatStatus,
    collapseRUDetailsFromOAC: collapseRUDetailsFromOAC,
    defaultImage: placeholder,
  }

  useEffect(() => {
    const fetchCustomerData = async () => {
      const rengaId = await getCustomer(initialContactId)
      const customerRengaId = rengaId || customerId;
      setCustomerRUId(prevCustomerRUId => ({
        ...prevCustomerRUId,
        [initialContactId]: customerRengaId
      }));
    }
    if(checkConversationOpen(closedConversation, closedPhoneCall)) fetchCustomerData()
  }, [initialContactId, changeCustomerToggle])

  // To determine if chat to phone flow, if yes then make experience as chat flow if both channels are active, else make it as voice flow
  useEffect(() => {
    const channel = checkChannelFlow();
    // Set state based on the channel type
    setIsVoiceContact(channel === CHANNEL_VOICE);
  }, [origin, channelType, chatStatus, chatCurrentContactId, transferInProgressId])

  // Check assigned agent in case of multiparty 
  useEffect(() => {
    const isConversationOpen = checkConversationOpen(closedConversation, closedPhoneCall)
    if (isConversationOpen) {
      if (multipleAgentsConnected !== undefined && multipartyAssignedAgent !== undefined) {
        setIsMultipartyAssignedAgent(multipartyAssignedAgent)
      }
      // Chat flow - When multipartyAssignedAgent is not populated, then set allowedAgent to true
      else setIsMultipartyAssignedAgent(true)
    }
    // If conversation is closed then make assigned agent as FALSE
    else setIsMultipartyAssignedAgent(false)
  },[closedConversation, closedPhoneCall, multipleAgentsConnected, multipartyAssignedAgent])
  
  useEffect(() => {
    !ruExpanded ? setWidgetBottomF(ruWidgetBottom) : setWidgetBottomF('')
  }, [ruExpanded, showAdobeAnswers])
  
  
  useEffect(() => {
    setWidgetBottomF(ruWidgetBottom)
  }, [ruWidgetBottom])
  
  const setWidgetBottomF = (title: string = '') => {
    setWidgetBottom(title === 'AA' ? showAdobeAnswers ? <AdobeAnswersPanel /> : null : title === 'CM' ? <CaseManagement /> : title === 'ECM' ? <EnterpriseCaseManagement /> : null)
  }

  const checkConversationOpen = (closedConversation: boolean, closedPhoneCall: boolean) => {
    return !(closedConversation && closedPhoneCall);
  }

  const checkChannelFlow = () => {
    const isVoiceFlow = phoneOrigin?.includes(origin) || (origin === CHAT_TO_PHONE && !channelType);
    if (!isVoiceFlow) {
      if (chatStatus && chatStatus !== CLOSED && transferInProgressId !== chatCurrentContactId) {
        return CHANNEL_MESSAGING;
      }
    }
    // Default to voice flow
    return CHANNEL_VOICE;  
  }

  function fetchCurrentContactId() {
    let currContactId = ''
     const origin = originForCurrentConversation;
     if(origin === CHAT_TO_PHONE) {
         if(chatStatus && callStatus && chatStatus !== 'Closed' && callStatus !== 'Closed') { 
            currContactId = chatCurrentContactId
         }
         else {
            currContactId = callCurrentContactId
         }
    } else {
        currContactId = chatCurrentContactId || callCurrentContactId;
    }
    return currContactId;
  }

  return (
    <div className='ru-management'>
      <div className={`${ruManagementTopClass} ${ruExpanded ? 'ru-management-top-expanded' : ''}`}>
        <RecommendedUpgradePanel contextRUModuleData={contextRUModuleData} />
      </div>
      {widgetBottom && !ruExpanded && ruWidgetBottomShow[currentConvId] && <div className={ruManagementBasementClass}>{widgetBottom}</div>}
    </div>
  )
}

export default RUContainer

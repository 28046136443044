export const CallIcon = (props: any) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="14.508" height="14.807" viewBox="0 0 14.508 14.807">
        <defs>
            <clipPath id="clipPath">
                <rect id="Rectangle_151009" data-name="Rectangle 151009" width="14.508" height="14.807" fill="#fff" />
            </clipPath>
        </defs>
        <g id="Group_161830" data-name="Group 161830" clipPath="url(#clip-path)">
            <g id="Group_162281" data-name="Group 162281">
                <path id="Path_84298" data-name="Path 84298" d="M201.417.419a.784.784,0,0,0,.133.879.912.912,0,0,0,.68.249,6.1,6.1,0,0,1,5.241,3.217,5.865,5.865,0,0,1,.763,2.886.75.75,0,0,0,.829.763.72.72,0,0,0,.73-.813,7.948,7.948,0,0,0-2.438-5.506c-.232-.182-.63-.531-1.061-.813A7.6,7.6,0,0,0,202.131,0a.641.641,0,0,0-.713.415Zm-.149,3.45a.739.739,0,0,0,.746.564,3.307,3.307,0,0,1,3.333,3.3.893.893,0,0,0,.166.5.79.79,0,0,0,.912.232.712.712,0,0,0,.464-.73,4.87,4.87,0,0,0-4.826-4.859,1.23,1.23,0,0,0-.415.083.807.807,0,0,0-.381.912Z" transform="translate(-195.285 0.002)" fill="#fff" />
                <path id="Path_84299" data-name="Path 84299" d="M3.925,72.361l.748-.748c.224-.224.037-.7-.185-.92L2.134,68.34c-.223-.223-.3-.017-.528.207l-.748.748Zm4.463,4.463.748-.748a.634.634,0,0,1,.849.114l2.358,2.358c.221.221.367.653.142.877l-.748.748ZM5.174,75.575c-2.159-2.145-1.833-2.629-1.6-2.86l.08-.08L.584,69.568l-.1.1c-.414.414-1.737,3.1,3.021,7.856s7.442,3.435,7.854,3.023l.1-.1L8.112,77.1l-.083.083c-.231.231-.7.543-2.861-1.6Z" transform="translate(0 -66.223)" fill="#fff" />
            </g>
        </g>
    </svg>
)

export const End = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="18.062" viewBox="0 0 20 18.062">
        <g id="Group_161327" data-name="Group 161327" transform="translate(0)">
            <path id="Icon_map-volume-control-telephone" data-name="Icon map-volume-control-telephone" d="M5.124,5.652H6.5c.412,0,.675-.606.675-1.015V.31c0-.409-.263-.3-.675-.3H5.124Zm0,8.2H6.5c.412,0,.675.476.675.886v4.326c0,.407-.263.938-.675.938H5.124ZM3.31,9.751c-.012-3.955.732-4.1,1.156-4.1h.146V.015H4.437C3.682.015,0,1.255,0,10.007S3.682,20,4.437,20h.175V13.851H4.466C4.042,13.851,3.322,13.7,3.31,9.751Z" transform="translate(20) rotate(90)" fill="#fff" />
            <path id="Polygon_3" data-name="Polygon 3" d="M5.428,0l5.428,6.031H0Z" transform="translate(15.682 18.062) rotate(180)" fill="#fff" />
        </g>
    </svg>
)
import React from 'react'
import { useSelector } from 'react-redux'
import { Grid, View } from '@adobe/react-spectrum'
import CustomerChatPanel from '../Modules/CustomerChatPanel/CustomerChatPanel'
import WidgetContainer from './WidgetContainer'
import ToolBar from '../Modules/ToolBar/ToolBar'
import TopBar from './TopBar'
import FlyoutPanel from '../Modules/FlyoutPanel/FlyoutPanel'
import { ContactRecord, iBannerInfo } from '../../ContactCenter/Models'
import '../styles.scss'
import { CALLEVENTS } from '../../../Apps/ContactCenter/Constants'

interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
  contactCenter: {
    currentConversation: ContactRecord
    transferInProgressId: string
    bannerInfo: iBannerInfo
  }
}

const Container = () => {
  const { darkMode: globalDarkMode } = useSelector((state: State) => state.preferences)
  const { currentConversation, transferInProgressId, bannerInfo } = useSelector((state: State) => state.contactCenter)
  const basicThemeClass = globalDarkMode ? 'basic-dark' : 'basic-light'
  const flyoutThemeClass = globalDarkMode ? 'flyout-dark' : 'flyout-light'
  const widgetBackground = globalDarkMode ? 'view-widget-container-dark' : 'view-widget-container'
  const {
    ocPlatformData: {
      chatInfo: { currentContactId = '', status = '' } = {},
      callInfo: { status: callStatus = '', origin } = {},
    } = {}
  } = currentConversation
  const toolbarDisabled = bannerInfo?.isAcw && ( origin === CALLEVENTS.OUTBOUND_PHONE 
                                              || origin === CALLEVENTS.INBOUND_PHONE 
                                              || origin === CALLEVENTS.CHAT_TO_PHONE
                                              || status === 'Closed')
  const toolbarEnable = (!toolbarDisabled && ((callStatus === "Active") || (currentContactId !== '' && status !== 'Closed' && currentContactId !== transferInProgressId))) || (bannerInfo?.outBoundCall);
  
  return (
    <>
      <Grid
        areas={['Flyout-panel  header header', 'Flyout-panel chat widget', 'Flyout-panel  toolbar widget']}
        columns={['100px', '425px', 'auto']}
        rows={['size-1000', `calc(100vh - ${!toolbarEnable ? 80 : 140}px)`, '60px']}
        height='100%'
      >
        <React.StrictMode>
          <View gridArea='header' UNSAFE_className='header-topbar'>
            <TopBar />
          </View>
          <View zIndex={1} gridArea='Flyout-panel' UNSAFE_className={flyoutThemeClass}>
            <FlyoutPanel />
          </View>
          <View UNSAFE_className={`${basicThemeClass} chat-container`} gridArea='chat'>
            <CustomerChatPanel />
          </View>
        </React.StrictMode>
        <View
          zIndex={0}
          gridArea='toolbar'
          UNSAFE_className={`${basicThemeClass} ${!toolbarEnable ? 'hide-toolbar' : ''}`}
        >
          {/* {currentContactId !== '' && status !== 'Closed' && currentContactId !== transferInProgressId && <ToolBar />} */}
          {toolbarEnable && <ToolBar />}
        </View>
        <React.StrictMode>
          <View gridArea='widget' UNSAFE_className={widgetBackground}>
            <WidgetContainer />
          </View>{' '}
        </React.StrictMode>
      </Grid>
    </>
  )
}

export default Container

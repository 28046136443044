export const AGENT_DEFAULT_STATUS = 'Start of Day'
export const AGENT_OFFLINE_STATUS = 'Offline'
export const AGENT_MISSED_STATUS = 'MissedCallAgent'
export const CONVERSATION_ACTIVE_STATUS = 'Active'
export const CONVERSATION_IDLE_STATUS = 'Idle'
export const CONVERSATION_OVERDUE_STATUS = 'Overdue'
export const CONVERSATION_CLOSED_STATUS = 'Closed'
export const CONVERSATION_ON_HOLD_TIME_THREE_MIN = 180000
export const CONVERSATION_ON_HOLD_TIME_ONE_MIN = 60000
export const CALLEVENTS = {
    CHAT_TO_PHONE: "chat-to-phone",
    OUTBOUND_PHONE: "outbound-phone",
    INBOUND_PHONE: "inbound-phone",
    BOT_TO_PHONE: 'bot-to-phone',
    MUTE_CALL: "mutecall",
    END_CALL: "endcall",
    HOLD_CALL: 'holdcall',
    CLEAR_CONTACT: 'clear-contact',
    WARM_TRANSFER_CALL: "WARM_TRANSFER_CALL",
    COLD_TRANSFER_CALL: "COLD_TRANSFER_CALL",
    JOIN_CALL: "JOIN_CALL",
    SWAP_CALL: "SWAP_CALL",
    TRANSFER_TYPE: {
        WARM: 'warm',
        COLD: 'cold'
    },
    DTMF_INPUT: "DTMF",
    EMPTY_CIP_NUMBER: "empty-cipNumber",
    REMOVE_PARTICIPANT:"remove-agent",
    TRANSCRIPT_PHONE: "phone-in-transcript",
    BANNER_ACTIVE_CALL_HOLD_RESUME: "BANNER_ACTIVE_CALL_HOLD_RESUME",
    BANNER_ACTIVE_CLOSE_CONNECTION: "BANNER_ACTIVE_CLOSE_CONNECTION",
    RECORDING_DISABLED:"record-disable"
}
export const FEATURE_FLAG_AA_Q = 'aaqcsffprmscrt'
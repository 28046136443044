import { ActionButton, Button, Content, Dialog, Divider, Flex, Footer, Heading, Text } from '@adobe/react-spectrum'
import './Offline/Offline.scss'
import ContactCenter from "../../../Apps/ContactCenter/Modules/ContactCenter";
import { useDispatch } from 'react-redux';
import { CallIcon } from './Offline/icons';
import React from 'react'
export const IncomingCall = (props: any) => {
    const { incomingCallData, darkMode } = props
    const dispatch = useDispatch()
    const acceptCall = () => {
        dispatch({ type: 'INCOMING_CALL', payload: { openPopup: "no" } })
        ContactCenter.acceptIncomingCall()
    }
    const data = incomingCallData.message.content;
    React.useEffect(() => {
        try {
            const logString1 = `INCOMING_CALL : Pop up Component Mounted in OAC`
            window.connect.getLog().info("%s", logString1)
        } catch (e: any) {
            console.log('ERROR on Incoming logging :', e)
        }
    }, [])
    return (
        <Dialog size='S' UNSAFE_className='offline-popover' width={600} height={400}>
            <Heading><ActionButton UNSAFE_className={'telephony-call'} right="10px" bottom="5px">
                <CallIcon />
            </ActionButton>{incomingCallData.message.title}</Heading>
            <Divider />
            <Content>
                <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-subhead"}`}>{incomingCallData.message.subtitle}</Text>
                <Flex UNSAFE_className='incoming-call-content' direction='column' marginTop={27} height={117} >
                    {
                        Object.keys(data).map((item: any, index: any) => {
                            return (
                                <Flex direction="row" key={index}>
                                    <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-text-key"} `} width={100} justifySelf="flex-start">{data[item].name}</Text>
                                    <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-text-value"} `} marginStart={36} >: {data[item].value}</Text>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </Content>
            <Footer UNSAFE_className='incoming-call-button-group'>
                <Button variant='cta' onPress={acceptCall}><CallIcon />{incomingCallData.message.btnValue}</Button>
            </Footer>
        </Dialog>
    )
}


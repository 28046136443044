import React, { useEffect } from 'react'
//import { LibraryModule as RULibrary } from './src/index'
import { LibraryModule as RULibrary } from 'oc-recommendedupgrade-module-client'

import { useDispatch, useSelector } from 'react-redux'
import { BENEFITS_COPY, CHANNEL_MESSAGING, COLLAPSE_RU_DETAILS_HANDLER, OFFER_ID_COPY, SEND_CONSENT, SEND_OFFER } from '../RUConstants'
import 'oc-recommendedupgrade-module-client/dist/index.css'
import { addMessage } from '../../../../../redux/actions/contactCenterActions'
import ContactCenter from '../../../../ContactCenter/Modules/ContactCenter'
import { SET_RU_EXPANDED, SET_RU_WIDGET_SHOW } from '../../../../../redux/constants/widgetLayoutConstants'
import { SET_PDC_MESSAGE } from '../../../../../redux/constants/pdcConstants'
import { RootState } from '../../../../../redux/reducers/rootReducer'

const RecommendedUpgradePanel = ({contextRUModuleData}) => {
  const dispatch = useDispatch()
  const {currentConvId, channel} = contextRUModuleData
  const { currentConversation, currentConversationAC } = useSelector((state: RootState) => state.contactCenter)

  const copyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Text copied to clipboard', text);
      })
      .catch(err => {
        console.error('Failed to copy text: ', err);
      });
  };

  function callBackEvent(eventType: string, eventData: any): void {
    console.log('RU Module callBackEvent', eventType, eventData)
    if (eventType === SET_RU_EXPANDED) {
      const { expanded = false } = eventData
      dispatch({ type: SET_RU_EXPANDED, payload: expanded})
    } else if (eventType === SET_RU_WIDGET_SHOW) {
      const { ruWidgetBottomShow = true, convId = ''} = eventData
      dispatch({ type: SET_RU_WIDGET_SHOW, payload: { convId, ruWidgetBottomShow } })
    }else if ((eventType === SEND_CONSENT || eventType === SEND_OFFER ) && channel === CHANNEL_MESSAGING) {
      const data: any = {
        ParticipantRole: 'AGENT',
        translationText: eventData?.message 
      };
     dispatch(addMessage(data));
     ContactCenter.sendMessage(currentConvId, eventData)
    } else if (eventType === BENEFITS_COPY  ) {
      const { message : { text ='' } = {} } = eventData
        if (currentConversation?.ocPlatformData?.chatInfo?.status !== 'Closed') {
          dispatch({ type: SET_PDC_MESSAGE, payload: text})
        }  
    } else if (eventType === OFFER_ID_COPY) {
      copyToClipboard(eventData?.offer_id)
    } else if (eventType === COLLAPSE_RU_DETAILS_HANDLER) {
      dispatch({ type: COLLAPSE_RU_DETAILS_HANDLER, payload: 'none'})
    }
  }
  useEffect(() => {
    dispatch({ type: SET_PDC_MESSAGE, payload: '' })
  }, [currentConversation, currentConversationAC, dispatch])

  return (
      <RULibrary 
        contextRUModuleData={contextRUModuleData}
        callBackEvent={callBackEvent}
      />
  )
}

export default RecommendedUpgradePanel;
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, Profiler } from 'react'
import { LibraryModule as CCMLibrary } from 'oc-ccm-module-client'
import { useDispatch, useSelector } from 'react-redux'
import useContextData from '../../Hooks/useContextData'
import { CHANGE_CUSTOMER, CHANGE_CUSTOMER_CONTACT, CHANGE_CUSTOMER_RENGA_ID } from '../../../../redux/constants/changeCustomerConstants'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import useAllowedAgent from '../../Hooks/useAllowedAgent'
import 'oc-ccm-module-client/dist/index.css'
import './caseManagement.scss'
import { isZendeskDecommissioned } from '../../../../utils/utils'
declare const window: any

let currentConversation: any = ''

interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
}

const CaseManagement = () => {
  const dispatch = useDispatch()

  const { darkMode: globalDarkMode, language: globalLanguage } = useSelector((state: State) => state.preferences)
  const [darkMode, setDarkMode] = useState(globalDarkMode)
  const [language, setLanguage] = useState(globalLanguage)
  const [newConversationLoader, setNewConversationLoader] = useState(true)
  
  const contextData = useContextData()
  const allowedAgent: any = useAllowedAgent('CCM');
  const { isSupportAgent = false } = allowedAgent
  contextData.ocPlatformData.allowedAgent = isSupportAgent

  function callBackEventListener(eventType: string, eventData: Record<string, any>): void {
    if (eventType === 'activeCustomer') {
      console.log('CCM Module: Dispatching change customer action with payload', eventData)
      dispatch({ type: CHANGE_CUSTOMER_RENGA_ID, payload: eventData})
      dispatch({ type: CHANGE_CUSTOMER, payload: eventData })
      dispatch({ type: CHANGE_CUSTOMER_CONTACT, payload: { data: eventData, contactId: contextData?.ocPlatformData?.chatInfo?.currentContactId } })
    }
    else if (eventType === 'CHANGE_CUSTOMER_TOGGLE') {
      dispatch({ type: 'CHANGE_CUSTOMER_TOGGLE'})
    }
  }

  useEffect(() => {
    setDarkMode(globalDarkMode)
    setLanguage(globalLanguage)
  }, [globalDarkMode, globalLanguage])

  const updateCOnversationId = async () => {
    window.UCIContactID = contextData?.ocPlatformData?.chatInfo?.contactId
    const getGenericTimeoutPromise = (timeout: any) =>
      new Promise((resolve) => {
        setTimeout(resolve, timeout, {
          data: {
            success: true
          }
        })
      })
    setNewConversationLoader(true)
    await getGenericTimeoutPromise(0)
    currentConversation = contextData?.ocPlatformData?.chatInfo?.contactId
    setNewConversationLoader(false)
  }

  useEffect(() => {
    if (contextData?.ocPlatformData?.chatInfo?.contactId !== contextData?.ocPlatformData?.chatInfo?.currentContactId) {
      updateCOnversationId()
    }
  }, [contextData?.ocPlatformData?.chatInfo?.currentContactId])

  useEffect(() => {
    if (contextData?.ocPlatformData?.chatInfo?.contactId) updateCOnversationId()
  }, [contextData?.ocPlatformData?.chatInfo?.contactId])

  const { jcAuthData: { currentQueue = '' } = {} } = contextData
  const startWith = currentQueue.substring(0, 4)
  let isCCMChat = startWith !== 'SLS-' && startWith !== 'ENT-'
  if(isZendeskDecommissioned()) isCCMChat = isCCMChat && startWith !== 'Sign'

  return (
    <div className='consumer-case-management'>
      {newConversationLoader ? (
        <p />
      ) : isCCMChat ? (
        window.enable_widget_analytics ? (
          <Profiler id='CCM' onRender={onRenderCB}>
            <CCMLibrary contextData={contextData} callBackEvent={callBackEventListener} />
          </Profiler>
        ) : (
          <CCMLibrary contextData={contextData} callBackEvent={callBackEventListener} />
        )
      ) : null}
    </div>
  )
}

export default CaseManagement

export interface Transcript {
  ContactId?: string
  InitialContactId?: string
  AbsoluteTime: string
  Attachments?: [
    {
      AttachmentId: string
      AttachmentName: string
      ContentType: string
      Status: string
    }
  ]
  Content: string
  ContentType: string
  DisplayName: string
  Id: string
  ParticipantId: string
  ParticipantRole: string
  Type: string
  isChatClosed?: boolean
}
// export interface ContactRecord{
//     status: string
//     rengaID: string;
//     conversationID: string;
//     CTR:any
//     InitialContactId: string,
//     NextToken: string,
//     CustomerName: string,
//     Transcript: Transcript[]
// }

// export interface ContactRecord{
//         jcAuthData?: {
//             surfaceAppId?: string,
//             surfaceAppVersion?: string,
//             surfaceUrl?: string,
//             surfaceRegion?: string,
//             surfaceIpRegion?: string,
//             deviceFamily?: string,
//             os?: string,
//             osVersion?: string,
//             osName?: string,
//             browser?: string,
//             browserVersion?: string,
//             deviceManufacture?: string,
//             ipAddress?: string,
//             firstName?: string,
//             lastName?: string,
//             email?: string,
//             country?: string,
//             phone?: string,
//             company?: string,
//             customerId?: string,
//             accountType?: string,
//             customerAuthId?: string,
//             queueName?: string,
//             customerType?: string,
//             orgId?: string,
//             vipRouting?: string,
//             language?: string,
//             region?: string,
//             timezone?: string,
//             issueType?: string,
//             issueTypeCode?: string,
//             subissueType?: string,
//             subissueTypeCode?: string,
//             entitlementsList?:string,
//             productCode?: string,
//             subProductName?: string,
//             subProductCode?: string,
//             botFlow?: boolean
//             returningCustomerType?: string,
//             returningCustomerCaseId?: string,
//             channel?: string,
//             prechatConnectUrl?: string,
//             prechatIssue?: string,
//             prechatCustomerType?: string
//         },
//         ocPlatformData: {
//             chatInfo: {
//                 initialContactId: string,
//                 currentContactId: string,
//                 contactIds?: string[],
//                 chatStartTime?: string,
//                 queueName?: string,
//                 status: string
//             },
//             chattingAgentInfo?: {
//                 agentID?: string,
//                 agentName?: string,
//                 agentLDAP?: string,
//             },
//             agentInfo?: {
//                 agentID?: string,
//                 agentName?: string,
//                 agentLDAP?: string
//             },
//             chatTranscript?: Transcript[]
//         }

// }
export interface ContactRecord {
  jcAuthData?: any
  ocPlatformData: {
    chatInfo: {
      initialContactId: string
      currentContactId: string
      contactIds?: string[]
      chatStartTime?: string
      queueName?: string
      status: string
      newCustomer?: boolean
      duration?: number
      updateDate?: string
      timer?: number
    },
    callInfo: any,
    chattingAgentInfo?: {
      agentID?: string
      agentName?: string
      agentLDAP?: string
    }
    agentInfo?: {
      agentID?: string
      agentName?: string
      agentLDAP?: string
    }
    chatTranscript?: Transcript[] | any
    translationTranscript?: Transcript[] | any
    messageDraft?: string
    translationDraft?: string
  }
}
export interface ProfileInfo {
  name: string
  email: string
  preferred_username: string
}

export interface ConversationACData {
  isListView: boolean
  isAppliedFilter: boolean
  isInit: boolean
  allConvData: any | []
  listWithPaginate: any | []
  isChangeList: number
  isDateChange: boolean
  sortDescriptor: any
  paginate: {
    offset: number
    limit: number
    totalItems: number
    firstPage: number
    totalPages: number
    lastPage: number
    active: string
  }
  filterBy: {
    isSearchByConversationId: boolean
    searchConversationId: string
    searchTranscripts: string
    csat: any | []
    isFilter: boolean
  }
  dateTime: {
    startDate: Date | any
    endDate: Date | any
  }
}

export interface ToolBarCallData {
  countryCode: string
  customerId: string
  callStartTime: string
  queueARN: string
  queueName: string
  origin: string
  status: string
}

const defaultStartDate = new Date()
defaultStartDate.setDate(defaultStartDate.getDate() - 6)

export function initConversationACData() {
  return {
    isInit: false,
    isAppliedFilter: false,
    isListView: true,
    allConvData: [],
    isChangeList: Math.floor(100000 + Math.random() * 900000),
    isDateChange: false,
    sortDescriptor: {
      column: 'updateDate',
      direction: 'descending'
    },
    listWithPaginate: [],
    paginate: {
      offset: 0,
      limit: 20,
      totalItems: 0,
      firstPage: 0,
      totalPages: 0,
      lastPage: 0,
      active: ''
    },
    filterBy: {
      isSearchByConversationId: true,
      searchConversationId: '',
      searchTranscripts: '',
      csat: [],
      isFilter: false
    },
    dateTime: {
      startDate: defaultStartDate,
      endDate: new Date()
    }
  }
}

export interface iCallConnection {
  contactId: string,
  connectionId: string,
  isHold: boolean,
  state: string,
  endPoint: string
  isClosedByAgent: boolean
}
export interface iBannerInfo {
  contactId: string,
  isAcw: boolean,
  outBoundCall: boolean,
  customerCount: number,
  isRinging: boolean,
  queueName: string,
  queueNames: any,
  callConnections: iCallConnection[],
  muteStartDate: Date | any
}

export function bannerInfoState() {
  return {
    contactId: '',
    isAcw: false,
    outBoundCall: false,
    customerCount: 0,
    isRinging: false,
    queueName: '',
    queueNames: {},
    callConnections: [],
    muteStartDate: ''
  }
}

export interface iBannerInfo {
  contactId: string,
  isAcw: boolean,
  outBoundCall: boolean,
  customerCount: number,
  isRinging: boolean,
  queueName: string,
  queueNames: any,
  callConnections: iCallConnection[],
  muteStartDate: Date | any
}

export interface iMessageMetaData {
  [contactId: string]: {
    contactId: string
    messageId: string
    isRead: boolean
    isDelivered: boolean
  }
}

export function messageMetaDataState() {
  return {
    contactId: '',
    messageId: '',
    isRead: false,
    isDelivered: false
  }
}

export interface iTransferData {
  [contactId: string]: {
    'chat'?: {
      tabId: number,
      transferTabActive: string
      transferToAssistance: any
      messageAssistance: string
      transferToDirect: any
      messageDirect: string
      resultQueue: any,
      resultQueueList: any
      overrideSuggestion: string
      nonEnglishQueue: boolean
      isSecureIVR: boolean
      isVoicemailMessage: boolean
      isDialExtension: boolean
    },
    'voice'?: {
      tabId: number,
      transferTabActive: string
      transferToAssistance: any
      messageAssistance: string
      transferToDirect: any
      messageDirect: string
      resultQueue: any,
      resultQueueList: any
      overrideSuggestion: string
      nonEnglishQueue: boolean
      isSecureIVR: boolean
      isVoicemailMessage: boolean
      isDialExtension: boolean
    }
  }
}

export function initTransferData() {
  return {
    tabId: 1,
    transferTabActive: 'Queue', // like queue or manager
    transferToAssistance: {}, // queue name or manager name 
    messageAssistance: '', // private notes
    transferToDirect: {}, // queue name or manager name 
    messageDirect: '', // private notes
    resultQueue: {}, // tap-tool-queue obj
    resultQueueList: [], // tap tool list
    overrideSuggestion: '', // tap tool override suggestion ie reason,
    nonEnglishQueue: false,
    isSecureIVR: false,
    isVoicemailMessage: false,
    isDialExtension: false
  }
}
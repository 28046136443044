import { CHANGE_CUSTOMER, CHANGE_CUSTOMER_CONTACT, CLICK_PHONE_NUMBER } from '../constants/changeCustomerConstants'

export interface IModifiedCustomer {
  rengaId: string,
  email: string,
  phone: string,
  firstName: string,
  lastName: string,
  contactId: string
}
export interface defaultStateInterface {
  selectedCustomer: IModifiedCustomer
  phoneNumber: number
  contactsModifiedCustomer: IModifiedCustomer[]
  changeCustomerToggle: boolean
}
const phoneNumber = 0
const defaultState: defaultStateInterface = {
  selectedCustomer: {
    rengaId: '',
    email: '',
    phone: '',
    firstName: '',
    lastName: '',
    contactId: ''
  },
  phoneNumber,
  contactsModifiedCustomer : [],
  changeCustomerToggle: false
}

const changeCustomerReducer = (state: defaultStateInterface = defaultState, action: any): defaultStateInterface => {
  const contactsCopy = { ...state.contactsModifiedCustomer }
  switch (action.type) {
    case CHANGE_CUSTOMER:
      const selectedCustomer = {
        rengaId: action.payload.rengaId,
        email: action.payload.email,
        phone: action.payload.phone,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        contactId: action.payload.contactId
      }
      return { ...state, selectedCustomer }
      case CHANGE_CUSTOMER_CONTACT:
        const { contactId, data } = action.payload
        if(!contactId || !action.payload) return state
        const newCustomer = {
          rengaId: data.rengaId,
          email: data.email,
          phone: data.phone,
          firstName: data.firstName,
          lastName: data.lastName,
          contactId: data.contactId
        }
        contactsCopy[contactId] = newCustomer
        return { ...state, contactsModifiedCustomer: contactsCopy }
    case CLICK_PHONE_NUMBER:
      console.log('CIP NEW NUMBER', state, action)
      return { ...state, phoneNumber: action.payload }
    case 'CHANGE_CUSTOMER_TOGGLE':
      return { ...state, changeCustomerToggle: !state.changeCustomerToggle }
    default:
      return state
  }
}

export default changeCustomerReducer

export interface defaultStateInterface {
  customerName: string
}

const defaultState: defaultStateInterface = {
  customerName: 'John Doe'
}

const customerInfoReducer = (state: defaultStateInterface = defaultState, action: any): defaultStateInterface => {
  switch (action.type) {
    default:
      return state
  }
}

export default customerInfoReducer

import { Button, Content, Dialog, Divider, Footer, Header, Heading, Text } from '@adobe/react-spectrum'
import { useDispatch } from 'react-redux';
import './Offline/Offline.scss'
import Alert from '@spectrum-icons/workflow/Alert'
import localization from './Offline/lang/localization'

export const FailurePopup = (props: any) => {
    const { darkMode, language } = props
    const lang: any = language || 'en'
    const dispatch = useDispatch()
    const closeContact = () => {
        dispatch({ type: 'RECORDING_DISABLED', payload: { failed: false } })
    }
    return (
        <Dialog size='S' UNSAFE_className='offline-popover' width={480}>
            <Heading>{localization[lang].DISABLE_CALL_RECORDING_FAILURE.TITLE}</Heading>
            <Header><Alert size="S" color="negative" /></Header>
            <Divider />
            <Content>
                <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-subhead"}`}>{localization[lang].DISABLE_CALL_RECORDING_FAILURE.MESSAGE}</Text>
            </Content>
            <Footer justifySelf="end" UNSAFE_className='call-fail-button-close'>
                <Button variant="secondary" onPress={closeContact} >{localization[lang].DISABLE_CALL_RECORDING_FAILURE.OK}</Button>
            </Footer>
        </Dialog>
    )
}

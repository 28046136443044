import { ActionButton, Button, Content, Dialog, Divider, Flex, Footer, Heading, Text } from '@adobe/react-spectrum'
import { useDispatch } from 'react-redux';
import { End } from './Offline/icons';
import './Offline/Offline.scss'
import ContactCenter from "../../../Apps/ContactCenter/Modules/ContactCenter";

export const MissedCall = (props: any) => {
    const { missedCallData, darkMode } = props
    const data = missedCallData.message.content;
    const dispatch = useDispatch()
    const closeContact = () => {
        dispatch({ type: 'MISSED_CALL', payload: { isMissed: false } })
        ContactCenter.clearContact();
    }

    return (
        <Dialog size='S' UNSAFE_className='offline-popover' width={600} height={400}>
            <Heading><ActionButton UNSAFE_className='missed-call' right="10px" bottom="5px">
                <End />
            </ActionButton>{missedCallData.message.title}</Heading>
            <Divider />
            <Content>
                <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-subhead"}`}>{missedCallData.message.subtitle}</Text>
                <Flex UNSAFE_className='incoming-call-content' direction='column' marginTop={27} height={117} >
                    {
                        Object.keys(data).map((item: any, index: any) => {
                            return (
                                <Flex direction="row" key={index}>
                                    <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-text-key"} `} width={100} justifySelf="flex-start">{data[item].name}</Text>
                                    <Text UNSAFE_className={`incoming-call-text ${darkMode ? "dark-text" : "incoming-call-text-value"} `} marginStart={36} >: {data[item].value}</Text>
                                </Flex>
                            )
                        })
                    }
                </Flex>
            </Content>
            <Footer UNSAFE_className='incoming-call-button-group'>
                <Button variant='cta' width="auto" onPress={closeContact} >{missedCallData.message.btnValue}</Button>
            </Footer>
        </Dialog>
    )
}

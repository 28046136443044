// @ts-nocheck
import React, { useEffect, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import CaseManagement from '../Modules/CaseManagement/CaseManagement'
import CustomerInfoPanel from '../Modules/CustomerInfoPanel/CustomerInfoPanel'
import ConversationHistory from '../Modules/ConversationHistory/ConversationHistory'
import AdobeAnswersPanel from '../Modules/AdobeAnswersPanel/AdobeAnswersPanel'
import RUContainer from '../Modules/RecomendedUpgradePanel/RUContainer'
import noWidget from '../../../Theme/Styles/Icons/no-widget.svg'
import EnterpriseCaseManagement from '../Modules/EnterpriseCaseManagement/EnterpriseCaseManagement'
import { handleScreenResize } from '../../../redux/actions/widgetLayoutAction'
import localization from './lang/localization'
import PredefinedContent from '../Modules/PredefinedContent'
import { RootState } from '../../../redux/reducers/rootReducer'

const WidgetContainer = () => {
  // Stack widget based on the Width of the screen
  const dispatch = useDispatch()
  const { widgets, widgetStack } = useSelector((state: RootState) => state.widgetLayout)
  const { darkMode, language } = useSelector((state: RootState) => state.preferences)
  const WidgetItemsWrapper: any = useRef(null)
  const updateDimensions = useCallback(() => {
    dispatch(handleScreenResize(widgets, widgetStack, WidgetItemsWrapper.current.clientWidth))
    console.log('WidgetItemsWrapper from container', WidgetItemsWrapper.current.clientWidth)
  }, [WidgetItemsWrapper, dispatch])

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    dispatch(handleScreenResize(widgets, widgetStack, WidgetItemsWrapper.current.clientWidth))
    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [updateDimensions, widgets])

  console.log(
    'activeWidget from Container',
    useSelector((state: RootState) => state.widgetLayout)
  )

  const widgetComponents: any = {
    PDC: <PredefinedContent />,
    CI: <CustomerInfoPanel CIP />,
    CM: <CaseManagement />,
    SC: <CustomerInfoPanel CIP={false} />,
    ECM: <EnterpriseCaseManagement />,
    CH: <ConversationHistory />,
    AA: <AdobeAnswersPanel />,
    RU: <RUContainer />,
  }

  let activeWidget = 0
  // WidgetItems: number = 0;
  widgets?.forEach((widget) => (widget.active ? activeWidget++ : null)) // NO SONAR
  const emptyWidget = localization[language].WIDGET_CONTAINER.EMPTY_WIDGET
  let isWidgetActiveClass = activeWidget > 0 ? 'widget-container' : 'no-widget-container'

  isWidgetActiveClass = darkMode ? `${isWidgetActiveClass} widget-container-dark` : `${isWidgetActiveClass} widget-container-light`
  const RUactive = widgets.some(widget => widget.title === 'RU' && widget.active)
  const { ruWidgetBottom } = useSelector((state: RootState) => state.widgetLayout)
  return (
    <div id='widgetWrapper' ref={WidgetItemsWrapper} className={isWidgetActiveClass}>
      {activeWidget > 0 ? (
        widgets.map((w, i) =>
          w.active ? (
            (RUactive && w.title === ruWidgetBottom) ? 
              null
            : 
              <div key={`${w.title}`} className={`${w.title !== 'RU'? "widget-items":""} ${w.title}-widget`} style={{ width: `${w.width}px` }}>
                {widgetComponents[w.title]}
              </div>
          ) : null
        )
      ) : (
        <div className='no-widget'>
          <img src={noWidget} alt='Add widgets' />
          <p style={{ maxWidth: '122px' }}>{emptyWidget}</p>
        </div>
      )}
    </div>
  )
}

export default WidgetContainer

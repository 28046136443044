/** eslint-disable react/no-unused-prop-types react/no-unused-prop-types */
import React from 'react'
import { Toast } from '@react/react-spectrum/Toast'
import WarnIcon from '@spectrum-icons/workflow/Alert'
import './Notification.scss'
import localization from './lang/localization'

interface IProps {
  setNetworkMode: () => void
  language: string
  networkMode: string
}

const NetworkNotification = (props: IProps) => {
  const { language, setNetworkMode } = props;
  const lang = language || 'en'
  React.useEffect(() => {
    const timer = setTimeout(() => {
      setNetworkMode();
    }, 5000);
    return () => {
      clearTimeout(timer)
    }
  }, []);

  return (
    <Toast className='notification notification-nw-mode warning-background'>
      <div className='notification-content' onClick={setNetworkMode}>
        <div className='notification-icon'>
          <WarnIcon />
        </div>
        <div className='notification-text'>
          <span className='notification-title'>{localization[lang].CONNECTION_LOST}</span>
        </div>
      </div>
    </Toast>
  )
}

export default NetworkNotification

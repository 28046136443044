import React, { useEffect, Profiler, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { LibraryModule as ChatPanel } from 'oc-ccp-module-client'
import { ContactRecord, iBannerInfo, iMessageMetaData } from '../../../ContactCenter/Models'
import ContactCenter from '../../../ContactCenter/Modules/ContactCenter'
import 'oc-ccp-module-client/dist/index.css'
import './CustomerChatPanel.scss'
import { SET_PDC_MESSAGE } from '../../../../redux/constants/pdcConstants'
import noConversations from '../../../../Theme/Styles/Icons/no-conversations.svg'
import { saveDraft, setAutoTranslation, setCurrentAC, setCurrentConversation, setOutBoundCall } from '../../../../redux/actions/contactCenterActions'
import localization from '../../Components/lang/localization'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import env_variables from '../../../../config/env_variables'
import { CALLEVENTS, CONVERSATION_CLOSED_STATUS, CONVERSATION_ACTIVE_STATUS } from '../../../ContactCenter/Constants'
import { CLICK_PHONE_NUMBER } from '../../../../redux/constants/changeCustomerConstants'
import Utility from '../../../ContactCenter/Modules/Utility'

declare const window: any

interface State {
  contactCenter: {
    CCPStatus: {
      isInit: boolean
      ccpState: string
    }
    currentConversation: ContactRecord
    currentConversationAC: ContactRecord
    toggleTranslationforCS: boolean
    currentView: string
    onTyping: {
      isTyping: boolean
      initialContactId: string
      participantRole: string
    }
    chatFileStatus: any
    autoTranslation: any
    transferInProgressId: string
    bannerInfo: iBannerInfo
    messageMetaData: iMessageMetaData
  }
  preferences: {
    darkMode: boolean
    language: string
  }
  pdc: {
    selectedMessage: string
  }
 
}

interface autoTranslationState {
  contactId: string,
  isInit: boolean,
  translation: boolean,
  isLoading: boolean,
  lastTranslateId: string,
  upsertTrMsg: any,
  isCustomerTranslatedMsg: boolean
}

const CustomerChatPanel = () => {
  const { currentConversation, currentConversationAC, currentView, onTyping, chatFileStatus, transferInProgressId, autoTranslation, bannerInfo, messageMetaData, toggleTranslationforCS } =
    useSelector((state: State) => state.contactCenter)
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const pdc = useSelector((state: State) => state.pdc)
  const { darkMode, language } = useSelector((state: State) => state.preferences)
  const dispatch = useDispatch()
  const emptyChat = localization[language].CCP_PANEL.EMPTY_CHAT
  const pdcUrl = env_variables.PDC_HOTKEYS_URL
  const history = useHistory();
  const location = useLocation()
  const [customerEndpoint, setCustomerEndpoint] = useState('');
  const {
    jcAuthData: { currentQueue: { value: queueName = '' } = {}, language: { value: conversationLanguage = '' } = {}, region: { value: conversationRegion = '' } = {} } = {},
    ocPlatformData: {
      chatInfo: { currentContactId: contactId = '', status: chatStatus = 'Active', initialContactId = '' } = {},
      callInfo: { status: callStatus = '' } = {},
      chatTranscript = [],
      translationTranscript = []
    } = {},
  } = conversation

  useEffect(() => {
    const autoTranslationData: autoTranslationState = autoTranslation[contactId] || { contactId: contactId, translation: false, isLoading: false, lastTranslateId: '', isInit: false, isCustomerTranslatedMsg: false };
    if ((!translationTranscript || translationTranscript.length === 0) && contactId) {
      autoTranslationData.isCustomerTranslatedMsg = false;
      dispatch({ type: 'SET_AUTO_TRANSLATION', payload: autoTranslationData })
    }
    dispatch({ type: SET_PDC_MESSAGE, payload: '' })
  }, [contactId, currentView, dispatch])

  useEffect(() => {
    const getCustEndpoint = async () => {
      if (initialContactId && chatStatus === CONVERSATION_CLOSED_STATUS) {
        const resp = await ContactCenter.getCustomerEndpoint(initialContactId);
        setCustomerEndpoint(resp);
      }
    }
    getCustEndpoint();
  }, [initialContactId, chatStatus])

  function callBackEventListener(eventType: string, eventData: any): void {
    try {
      if (eventType === 'sendMessage') {
        dispatch({ type: SET_PDC_MESSAGE, payload: '' })
        if (contactId) ContactCenter.sendMessage(contactId, eventData)
      } else if (eventType === 'uploadFile') {
        dispatch({ type: SET_PDC_MESSAGE, payload: '' })
        if (contactId) {
          dispatch({ type: 'SET_ON_UPLOADING', payload: { contactId, uploading: eventData.file ? true : false, apiUploadError: '' } })
          if (eventData.file) {
            ContactCenter.sendAttachment(contactId, eventData.file)
          }
        }
      } else if (eventType === 'downloadAttachment' && contactId) {
        ContactCenter.downloadAttachment(contactId, eventData.attachment)
      } else if (eventType === 'saveDraft') {
        dispatch(saveDraft(eventData))
      } else if (eventType === 'sendToAgentTypingEvent') {
        ContactCenter.sendToAgentTypingEvent()
      } else if (eventType === 'messageGotEmpty') {
        dispatch({ type: SET_PDC_MESSAGE, payload: '' })
      } else if (eventType === 'autoTranslation') {
        dispatch(setAutoTranslation(eventData))
      } else if (eventType === 'autoTranslationTranscript') {
        conversation.ocPlatformData.translationTranscript = eventData || [];
        currentView === 'AC_VIEW' ? dispatch(setCurrentAC(conversation)) : dispatch(setCurrentConversation(conversation))
      } else if (eventType === CALLEVENTS.CLEAR_CONTACT) {
        ContactCenter.clearContact()
      } else if (eventType === CALLEVENTS.BANNER_ACTIVE_CALL_HOLD_RESUME) {
        Utility.handleHoldResume(eventData)
      } else if (eventType === CALLEVENTS.BANNER_ACTIVE_CLOSE_CONNECTION) {
        Utility.closeActiveCallConnection(eventData)
      } else if (eventType === CALLEVENTS.TRANSCRIPT_PHONE) {
        dispatch({ type: CLICK_PHONE_NUMBER, payload: eventData.phoneNumber })
        if (location.pathname === '/history') history.push('/chat')
        if (!chatStatus || chatStatus === "Closed") dispatch(setOutBoundCall(true));
      } else if (eventType === 'SETNOTIFICATION') {
        const data = localization[language].NOTIFICATIONS.TRANSCRIPT_COPIED
        dispatch({ type: 'SETNOTIFICATION', payload: { show: true, type: 'connection-gain', data } })
      } else if (eventType === 'TranslateConversationSummary') {
        dispatch({ type: 'TOGGLE_TRANSLATION_CS', payload: !toggleTranslationforCS })
        console.log("TranslateConversationSummary", eventData, toggleTranslationforCS)
        
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getClassNameContainer = () => {
    let className = '';
    if (!contactId) return className;
    if (transferInProgressId === contactId || chatStatus === CONVERSATION_CLOSED_STATUS) {
      className = `chat-panel-closed-container ${transferInProgressId === contactId ? 'transfer-in-progress' : ''} ${callStatus === CONVERSATION_ACTIVE_STATUS ? 'voice-panel-closed-container' : ''}`
    } else if (chatStatus !== CONVERSATION_CLOSED_STATUS || callStatus === CONVERSATION_ACTIVE_STATUS) {
      className = 'chat-panel-container'
    }
    return className;
  }

  try {
    let sortedTranspcripts: any = []
    let transcripts: any
    let maxLimitAttachments = 0

    if (contactId && chatTranscript.length > 0) transcripts = chatTranscript
    else transcripts = undefined

    if (transcripts && Array.isArray(transcripts) && transcripts.length > 0) {
      sortedTranspcripts = transcripts.filter(
        (i) =>
          (i.Type === 'EVENT' && (!i.Content || !i.Content.includes('IGNORE_'))) ||
          (i.Content && i.Type === 'MESSAGE' && !i.Content.includes('IGNORE_')) ||
          (i.Attachments && i.Attachments.length > 0)
      )
      const attachments = sortedTranspcripts.filter((i: any) => i.Attachments && i.Attachments.length > 0)
      maxLimitAttachments = attachments.length
      const transferFailed = transcripts.filter((i) => i.Content && i.Type === 'EVENT' && i.ContentType === 'application/vnd.amazonaws.connect.event.transfer.failed')
      if (transferFailed?.length > 0) {
        dispatch({ type: 'SET_TRANSFER_IN_PROGRESS_ID', payload: '' })
      }
    }
    let json: any = {
      lang: language,
      darkMode: darkMode,
      predefinedMessage: pdc.selectedMessage,
      payload: {
        status: chatStatus,
        Transcript: sortedTranspcripts || '',
        jcAuthData: conversation?.jcAuthData,
        initialContactId: initialContactId
      },
      customerEndpoint,
      conversation: conversation,
      onTyping,
      chatFileStatus,
      transferInProgressId,
      maxLimitAttachments,
      chatLanguage: `${conversationLanguage}_${conversationRegion}`,
      queueName,
      pdcUrl: pdcUrl,
      autoTranslation,
      apiEndPoint: env_variables.CCP_API_END_POINT,
      sortedTranscript: sortedTranspcripts || [],
      sourceLang: conversationLanguage,
      translateAPI: env_variables.PDC_TRANSLATE_URL,
      bannerInfo,
      currentView,
      messageMetaData: messageMetaData
    }
    const view = () =>
      contactId ? (
        <div
          className={getClassNameContainer()}
        >
          {json.sortedTranscript && <ChatPanel callBackEvent={callBackEventListener} contextData={json} />}
        </div>
      ) : (bannerInfo?.outBoundCall) ? (
        <ChatPanel callBackEvent={callBackEventListener} contextData={json} />
      ) : (
        <div className='no-conv-container'>
          <div className='no-conversations'>
            <img src={noConversations} alt='no conversations' />
            <p style={{ maxWidth: '122px' }}>{emptyChat}</p>
          </div>
        </div>
      )
    return window.enable_widget_analytics ? (
      <Profiler id='CCP' onRender={onRenderCB}>
        {view()}
      </Profiler>
    ) : (
      view()
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default CustomerChatPanel

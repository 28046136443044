import { combineReducers } from 'redux'
import changeCustomerReducer from './changeCustomerReducer'
import { contactCenterReducer } from './contactCenterReducer'
import customerInfoReducer from './customerInfoReducer'
import flyoutPanelReducer from './flyoutPanelReducer'
import { navBarReducer } from './navBarReducer'
import pdcReducer from './pdcReducer'
import { preferencesReducer } from './preferencesReducer'
import widgetLayoutReducer from './widgetLayoutReducer'

const rootReducer = combineReducers({
  customerInfo: customerInfoReducer,
  widgetLayout: widgetLayoutReducer,
  navBar: navBarReducer,
  preferences: preferencesReducer,
  contactCenter: contactCenterReducer,
  pdc: pdcReducer,
  flyout: flyoutPanelReducer,
  changeCustomer: changeCustomerReducer
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer

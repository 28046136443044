import localization from './lang/localization'
import { useDispatch } from 'react-redux'
import './Notification.scss'
import { useEffect, useState } from 'react'
import { Toast } from '@react/react-spectrum/Toast'
import Close from '@spectrum-icons/workflow/Close'

const TOAST_TIME = 5000;

const RemoveParticipant = (props: any) => {
    const dispatch = useDispatch()
    const { lang } = props;
    useEffect(() => {
        setTimeout(() => {
            dispatch({ type: 'PARTICIPANT_LEFT_THE_CALL', payload: false })
        }, TOAST_TIME)
    });

    const [isToast, setToast] = useState(true)

    const closeToast = () => {
        setToast(false)
        dispatch({ type: 'PARTICIPANT_LEFT_THE_CALL', payload: false })
    }

    return (
        <div>{
            isToast ?
                <Toast open variant="info" className='remove-participant remove-participant-nw-mode closed-background remove-participant-icon'>
                    < div className='notification-content' >
                        <div className='remove-participant-text'>
                            <span className='remove-participant-title'>{localization[lang].TELEPHONY.LEFT_PARTICIPANT}</span>
                            <button onClick={closeToast} className='remove-participant-close'><Close UNSAFE_className='remove-participant-icon' size='S' /></button>
                        </div>
                    </div >

                </Toast >
                : <div />
        }
        </div>

    )
}

export default RemoveParticipant

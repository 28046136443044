import React, { useEffect, useState, Profiler } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryModule as PDCLibary } from 'oc-pdc-module-client'
import 'oc-pdc-module-client/dist/index.css'
import { SET_PDC_MESSAGE } from '../../../../redux/constants/pdcConstants'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import useContextData from '../../Hooks/useContextData'
import config from '../../../../config/env_variables'

declare const window: any
interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
}

const PredefinedContentBody = () => {
  const dispatch = useDispatch()
  const [pdcLoader, setPDCLoader] = useState(true);
  const { darkMode: globalDarkMode, language: globalLanguage } = useSelector((state: State) => state.preferences)

  const { currentConversation, currentConversationAC, currentView, autoTranslation, agentFeatureTags, agentDetails  } = useSelector((state: any) => state.contactCenter)

  const contactCenterData: any = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation

  const { ocAppData: { closedConversation = false } = {} } = useContextData()

  const { jcAuthData: { currentQueue: { value: pdcQueueName = '' } = {}, language: { value: pdcLanguage = '' } = {}, region: { value: pdcRegion = '' } = {} } = {},
    ocPlatformData: {
      chatInfo: { currentContactId: contactId = '' } = {},
    } = {}
  } = contactCenterData

  useEffect(() => {
    setDarkMode(globalDarkMode)
    setLanguage(globalLanguage)
  }, [globalDarkMode, globalLanguage])

  useEffect(() => {
    
    const updateWidget = async () => {
      const getGenericTimeoutPromise = (timeout: any) =>
        new Promise((resolve) => {
          setTimeout(resolve, timeout, {
            data: {
              success: true
            }
          })
        })
      setPDCLoader(true)
      await getGenericTimeoutPromise(0)
      setPDCLoader(false)
    }
    updateWidget();
  }, [contactId])

  let json = {}
  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'closePdc') {
      console.log('closePdc', eventData)
      const { message = '' } = eventData
      dispatch({ type: SET_PDC_MESSAGE, payload: message })
    }
  }
  const [darkMode, setDarkMode] = useState(globalDarkMode)
  const [language, setLanguage] = useState(globalLanguage)
  json = {
    darkMode,
    language,
    pdcQueueName,
    pdcLanguage: `${pdcLanguage}_${pdcRegion}`,
    pdcUrl: config.PDC_QUERY_URL,
    conversationClosed: closedConversation,
    agentId: agentDetails?.ldap,
    autoTranslation: autoTranslation,
    sourceLang: pdcLanguage,
    translateAPI: config.PDC_TRANSLATE_URL,
    currentContactId: contactId,
    pdcBaseURL: config.PDC_BASE_URL,
    agentFeatureTags: {
      pdcFavorites: "true"
    },
  }

  return pdcLoader ? <></> : window.enable_widget_analytics ? (
    <Profiler id='PDC' onRender={onRenderCB}>
      <PDCLibary contextData={json} callBackEvent={callBackEventListener} />
    </Profiler>
  ) : (
    <PDCLibary contextData={json} callBackEvent={callBackEventListener} />
  )
}

export default PredefinedContentBody

import CONFIG from './config/env_variables'

const REACT_APP_OKTA_TESTING = CONFIG.REACT_APP_OKTA_TESTING || false
const REDIRECT_URI = `${window.location.origin}/login/callback`
const ISSUER: string = CONFIG.REACT_APP_OKTA_ISSUER_ID || ''
const CLIENT_ID: string = CONFIG.REACT_APP_OKTA_CLIENT_ID || ''

// eslint-disable-next-line
export default {
  oidc: {
    clientId: CLIENT_ID,
    issuer: ISSUER,
    redirectUri: REDIRECT_URI,
    scopes: ['openid', 'profile', 'email'],
    pkce: true,
    disableHttpsCheck: REACT_APP_OKTA_TESTING
  }
}

import { LibraryModule as CHLibrary } from 'oc-conversation-history-module-client'
import 'oc-conversation-history-module-client/dist/index.css'
import { Profiler, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import env_variables from '../../../../config/env_variables'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import { triggerTransferCount } from '../../../../redux/actions/widgetLayoutAction'
import { CLICK_PHONE_NUMBER } from '../../../../redux/constants/changeCustomerConstants'
import { CALLEVENTS } from '../../../ContactCenter/Constants'
import { ContactRecord } from '../../../ContactCenter/Models'
import useContextData from '../../Hooks/useContextData'
import './ConversationHistory.scss'

declare const window: any
let currentConversationId: any = ''
interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
  contactCenter: {
    currentConversation: ContactRecord
    currentConversationAC: ContactRecord
    currentView: string
  }
}
const ConversationHistory = () => {
  const dispatch = useDispatch()
  const { darkMode, language } = useSelector((state: State) => state.preferences)
  const { currentConversation, currentConversationAC, currentView } = useSelector((state: State) => state.contactCenter)
  const conversation = currentView === 'AC_VIEW' ? currentConversationAC : currentConversation
  const { jcAuthData: { customerId: { value: customerIdVal = '' } = {} } = {} } = conversation
  const [newConversationLoader, setNewConversationLoader] = useState(true)
  const contextData = useContextData()
  const changeCustomerData = useSelector((state: any) => state.changeCustomer)
  const { selectedCustomer: { rengaId: ccRengId = '' } = {} } = changeCustomerData

  const updateCOnversationId = async () => {
    window.UCIContactID = contextData?.ocPlatformData?.chatInfo?.contactId
    const getGenericTimeoutPromise = (timeout: any) =>
      new Promise((resolve) => {
        setTimeout(resolve, timeout, {
          data: {
            success: true
          }
        })
      })
    setNewConversationLoader(true)
    await getGenericTimeoutPromise(0)
    currentConversationId = contextData?.ocPlatformData?.chatInfo?.contactId
    setNewConversationLoader(false)
  }

  useEffect(() => {
    if (contextData?.ocPlatformData?.chatInfo?.contactId) updateCOnversationId()
  }, [contextData?.ocPlatformData?.chatInfo?.contactId])

  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'COVERSATION_HISTORY_API' && eventData && eventData.message && eventData.message.length === 0) {
      dispatch(triggerTransferCount(1))
    } else if (eventType === CALLEVENTS.TRANSCRIPT_PHONE) {
      dispatch({ type: CLICK_PHONE_NUMBER, payload: eventData.phoneNumber })
    }
  }
  try {
    // const customerID = conversation.jcAuthData ? (conversation.jcAuthData.customerId ? conversation.jcAuthData.customerId.value : '') : ''
    const customerID = conversation.jcAuthData ? customerIdVal : ''
    let token
    let customPanelAccessToken
    if (localStorage.getItem('ims-token')) {
      token = JSON.parse(localStorage.getItem('ims-token') || '')
    }

    if (localStorage.getItem('customPanelAccessToken')) {
      customPanelAccessToken = JSON.parse(localStorage.getItem('customPanelAccessToken') || '')
    }

    const json = {
      lang: language,
      darkMode,
      customerId: ccRengId ? ccRengId : customerID,
      conversationID: customerID,
      endpoint: env_variables.CCP_API_END_POINT,
      token,
      apiKey: 'oac-connect-service',
      genAIEndpoint: env_variables.GENAI_MIDDLEWARE_URL,
      CUSTOM_PANEL_API_KEY: 'oac-custom-panel',
      customPanelAccessToken
    }
    const widgetBackground = darkMode ? 'conversation-history-dark' : 'conversation-history'
    return newConversationLoader ? (
      <p />
    ) : (
      <div className={widgetBackground}>
        {window.enable_widget_analytics ? (
          <Profiler id='CH' onRender={onRenderCB}>
            <CHLibrary contextData={json} callBackEvent={callBackEventListener} />
          </Profiler>
        ) : (
          <CHLibrary contextData={json} callBackEvent={callBackEventListener} />
        )}
      </div>
    )
  } catch (error) {
    console.log(error)
    return <></>
  }
}

export default ConversationHistory

import React from 'react'
import './Login.scss'
import { Button, Flex, darkTheme, lightTheme, Provider as V3Provider, Image } from '@adobe/react-spectrum'
import { BrowserRouter as Router, Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import logo from '../../Theme/Styles/Icons/adobe-logo.svg'
import agent from '../../Theme/Styles/Icons/agent-console-logo.svg'
import localization from './lang/localization'

const Login = () => {
  const { darkMode: globalDarkMode, language } = useSelector((state: any) => state.preferences)
  const V3Theme = globalDarkMode ? darkTheme : lightTheme
  console.log('Theme test', globalDarkMode)
  const lang = language || 'en'
  const message = localization[lang].LOGIN.MESSAGE
  const heading = localization[lang].LOGIN.HEADING
  const action = localization[lang].LOGIN.ACTION

  return (
    <V3Provider theme={V3Theme} colorScheme='light'>
      <div className='lgnpage'>
        <header>
          <div className='header'>
            <Flex height='30px'>
              <Image src={logo} alt='Adobe Inc.' />
            </Flex>
          </div>
        </header>
        <>
          <div className='login-group'>
            <body>
              <div className='image-container'>
                <Image src={agent} alt='OmniChannel Agent Console' />
              </div>
              <div className='text-container-agent'>
                <div className={`light-mode-text-color${globalDarkMode ? 'false' : ''}`}>
                  <p>{heading}</p>
                </div>
              </div>
              <div className='text-container-welcome'>
                <div className={`light-mode-welcome-text-color${globalDarkMode ? 'false' : ''}`}>
                  <p>{message}</p>
                </div>
              </div>
              <div className='login-btn'>
                <Router>
                  <Link className='Home' to='/chat'>
                    <Button variant='cta'>{action}</Button>
                  </Link>
                </Router>
              </div>
            </body>
          </div>
        </>
      </div>
    </V3Provider>
  )
}
export default Login

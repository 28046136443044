function getAuthHeaders() {
  if (localStorage.getItem('ims-token')) {
    return {
      'x-api-key': 'oac-connect-service',
      Authorization: JSON.parse(localStorage.getItem('ims-token') || '')
    }
  }
}

export default getAuthHeaders

export const agentLanguages: any = {
  en: 'English',
  ja: "日本語",
  fr: "français",
  de: "Deutsch",
  es: "español",
  it: "italiano",
  ko: "한국어",
  "pt-BR": "português",  
  "zh-TW": "中文 (漢語)",  
  "zh-CN": "中文 (汉语)"   
};
/* eslint-disable  @typescript-eslint/ban-types */
/* eslint-disable import/no-cycle */
import { DunamisAnalyics, EnvironmentType, TrackableEvent } from 'oc-analytics'
import analyticUser from '../helpers/analytics-user'
import { exportAnalyticMethodsToWindow } from '../helpers/analytics-helpers';

declare const window: any

/**
 * intiate the dunamis
 * @returns dunamis instance
 * dunamis instance can also be used from the window object
 * ex: (window as any).dunamis_analytics
 */
export function initDunamis(): void {
  const dunamis_env: EnvironmentType = process.env.REACT_APP_NODE_ENV !== 'prod' ? EnvironmentType.stage : EnvironmentType.prod
  const dunamis_headers: AnalyticsHeaders = {
    X_API_KEY: 'oac-web-service'
  }

  new DunamisAnalyics(dunamis_env, dunamis_headers)
  window.analytics = window.analytics || {};
  window.dunamis_analytics.sendEvents = false;
  window.dunamis_analytics.user = {};
  exportAnalyticMethodsToWindow();
}

/**
 * send the analytics
 * @param events events can be single or array of analytic events
 */
export function sendTrackMetric(events: any): void {
  if (Array.isArray(events)) {
    window.dunamis_analytics.trackMany(events)
  } else {
    window.dunamis_analytics.track(events)
  }
}

/**
 * define the event properties, make sure no properties is empty
 * @param data
 * @returns event
 */
export function defineEvent(data: any): TrackableEvent {
  const { workflow, category, subcategory, type, subtype, errorDesc, value, contentId, ...rest} = data;
  return {
    data: {
      'event.dts_start': new Date().toISOString(),
      'event.workflow': workflow,
      'event.category': category || 'WEB',
      'event.subcategory': subcategory,
      'event.type': type?.toLowerCase(),
      'event.subtype': subtype?.toLowerCase(),
      'event.url': window.location.href,
      "event.guid": "",
      'event.user_guid': "",
      'event.user_agent': window.navigator.userAgent,
      'event.language': window.navigator.language || 'en-US',
      'event.session_guid': analyticUser.sessionId,
      'event.error_desc': errorDesc,
      'event.value': value || undefined,
      'source.name': 'OAC',
      'source.version': data.appVersion || '1.0', // project version
      'source.platform': getOSVersion(),
      'source.os_version': window.navigator.platform,
      'content.id': contentId,
      ...rest
    },
    ingesttype: 'dunamis',
    project: 'oac-web-service',
    environment: '',
    time: '',
  }
}

/**
 *
 * @returns os version
 */
export function getOSVersion() {
  if (navigator.appVersion.indexOf('Win') !== -1) return 'Windows OS'
  if (navigator.appVersion.indexOf('Mac') !== -1) return 'MacOS'
  if (navigator.appVersion.indexOf('X11') !== -1) return 'UNIX OS'
  if (navigator.appVersion.indexOf('Linux') !== -1) return 'Linux OS'
  return 'UnKnown'
}

interface AnalyticsHeaders {
  IMS_TOKEN?: string
  X_API_KEY: string
  // X_FORWARDED_API_KEY?: string
  // CLIENT_ID: string;
}

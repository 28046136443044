import axios from 'axios'
import env_variables from '../../../../config/env_variables'
const AXIOS_API_TIMEOUT = 10000

const createApiClient = (() => {
  let apiClientInstance = null;

  return () => {
    if (!apiClientInstance) {
      let headers: any = {
        'x-api-key': 'oac-custom-panel'
      };

      const customPanelAccessToken = localStorage.getItem('customPanelAccessToken');

      if (customPanelAccessToken) {
        try {
          headers.Authorization = JSON.parse(customPanelAccessToken);
        } catch (e) {
          console.error('Error parsing customPanelAccessToken from localStorage', e);
        }
      }

      apiClientInstance = axios.create({
        timeout: AXIOS_API_TIMEOUT,
        headers: headers
      });
    }

    return apiClientInstance;
  };
})();

export default async function getCustomer(conversationId: string): Promise<any> {
  const apiClient = createApiClient();
  const domain = env_variables.REACT_APP_CCM_BASE_URL;
  return apiClient
    .get(`${domain}/api/v1/db/fetch?conversationId=${conversationId}`)
    .then(res => {
      const receivedRes: any = res.data || {};
      console.log('oc-ru-module, getCustomer from DB:', JSON.stringify(receivedRes));
      return receivedRes?.json?.linkedCustomer?.rengaId ?? '';
    })
    .catch(e => {
      console.log('oc-ru-module, getCustomerResponse: Exception Occurred', e);
      return '';
    });
}


import { CHANGE_MODE, CHANGE_SOUND, SET_PREFERENCES, CHANGE_LANGUAGE,SHOW_LOADER_ON_OAC, CHANGE_VPN_STATUS, UPDATE_LAST_AGENT_STATUS_ON_VPN, UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS, SET_RELEASE_NOTES, RINGER_DEVICE_DETAILS } from '../constants/preferencesConstants'

export const triggerDarkMode = (darkMode: boolean, eventData: any) => (dispatch: any) => {
  darkMode = false
  darkMode = eventData.darkMode
  dispatch({ type: CHANGE_MODE, payload: darkMode })
}

export const triggerSound = (sound: boolean, eventData: any) => (dispatch: any) => {
  sound = false
  sound = eventData.sound
  dispatch({ type: CHANGE_SOUND, payload: sound })
}

export const setPreferences = (email: string) => (dispatch: any) => {
  dispatch({ type: SET_PREFERENCES, payload: email })
}

export const changeLanguage = (language: string, eventData: any) => (dispatch: any) => {
  language = eventData.lang
  dispatch({ type: CHANGE_LANGUAGE, payload: language })
}

export const changeVpnStatus = (status: boolean) => (dispatch: any) => {
  dispatch({ type: CHANGE_VPN_STATUS, payload: status })
}
export const updateLastAgentStatusOnVpn = (status: string) => (dispatch: any) => {
  console.log("updateLastAgentStatusOnVpn", status)
  dispatch({ type: UPDATE_LAST_AGENT_STATUS_ON_VPN, payload: status })
}
export const updateActiveContactAvailableStatus = (status: any) => (dispatch: any) => {
  dispatch({ type: UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS, payload: status })
}
export const setReleaseNotes = (notes: any) => (dispatch: any) => {
  dispatch({ type: SET_RELEASE_NOTES, payload: notes })
}
export const showLoaderOnOAC = (isTrue: boolean) => (dispatch: any) => {
  dispatch({ type: SHOW_LOADER_ON_OAC, payload: isTrue })
}

export const setRingerDeviceDetails = (data: any) => (dispatch: any) => {
  dispatch({ type: RINGER_DEVICE_DETAILS, payload: data })
}

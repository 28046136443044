export const SET_RU_WIDGET = 'SET_RU_WIDGET'
export const SEND_CONSENT = 'SEND_CONSENT'
export const SEND_OFFER = 'SEND_OFFER'
export const BENEFITS_COPY = 'BENEFITS_COPY'
export const OFFER_ID_COPY = 'OFFER_ID_COPY'
export const CHANNEL_VOICE = 'voice'
export const CHANNEL_MESSAGING = 'messaging'
export const INBOUND_CHAT = 'inbound-chat';
export const INBOUND_PHONE = 'inbound-phone';
export const OUTBOUND_PHONE = 'outbound-phone';
export const BOT_TO_PHONE = 'bot-to-phone';
export const CHAT_TO_PHONE = 'chat-to-phone';
export const CLOSED = 'Closed';
export const COLLAPSE_RU_DETAILS_HANDLER = 'COLLAPSE_RU_DETAILS_HANDLER';
export const EXPAND = 'EXPAND';
export const COLLAPSE = 'COLLAPSE';
export const UNMOUNT_RU_WIDGET_BUTTOM = 'UNMOUNT_RU_WIDGET_BUTTOM'
export const MOUNT_RU_WIDGET_BUTTOM = 'MOUNT_RU_WIDGET_BUTTOM'
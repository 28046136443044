// @ts-nocheck
import store from '../../../redux/store'
import { setBannerInfo, setConversations, setOutBoundCall, IncomingCall, setCurrentConversation, setMessageMetaData } from '../../../redux/actions/contactCenterActions'
import { ContactRecord } from '../Models'
import { updateContactAttributes, stopRecording, updateVpnDisconnectedContactId } from '../Api/api'
import ContactCenter from './ContactCenter'
import { CALLEVENTS } from '../../../Apps/ContactCenter/Constants'
import localization from '../../../Layout/Notification/lang/localization'
import {getConversationIdIncaseOfChatToPhone} from '../../../utils/conversation-utils'

const SECURE_IVR = 'Secure IVR';

let isPrimaryAgentDisconnect: boolean = false;
export default class Utility {
    static setCallInfoData = (upsertCallData: any) => {
        const { conversations } = store.getState().contactCenter;
        const cloneConversations = [...conversations] || [];
        const findActiveCall = cloneConversations.findIndex((c: ContactRecord) => c?.ocPlatformData?.callInfo?.status === "Active");
        if (findActiveCall === -1) return;
        const oldCallInfo = { ...cloneConversations[findActiveCall].ocPlatformData.callInfo };
        cloneConversations[findActiveCall].ocPlatformData.callInfo = {
            ...oldCallInfo,
            ...upsertCallData
        }
        store.dispatch(setConversations(cloneConversations))
    }

    static updateContextClosedByAgent = async () => {
        try {
            const { currentConversation } = store.getState().contactCenter;
            const {
                ocPlatformData: { callInfo: { currentContactId = '' } = {} } = {}
            } = currentConversation || {};
            const payloadForAgentCloseConversation = {
                attributes: {
                    closedFrom: 'AgentConsole'
                },
                contactId: currentContactId
            }
            console.log(" Triggered ClosedFrom AgentConsole Block - started for Conversation", currentConversation)
            await updateContactAttributes(payloadForAgentCloseConversation);
            console.log(" Triggered ClosedFrom AgentConsole Block - Ended")
            return true;
        } catch (e) {
            // Handling catch block also as True as this should not affect any of exisiting functionality
            console.log(" Triggered ClosedFrom AgentConsole Block - Error", e)
            return true;
        }
    }

    static updateClosedDueToVpnDisconnect = async (contactId) => {
        try {
            await updateContactAttributes({
                attributes: {
                    vpnDisconnect: 'true',
                    transferType: CALLEVENTS.TRANSFER_TYPE.COLD
                },
                contactId
            })
            await this.updateVpnDisconnectedContactId(contactId);
            return true;
        } catch (e) {
            // Handling catch block also as True as this should not affect any of exisiting functionality
            console.log(" Triggered ClosedFrom AgentConsole Block - Error", e)
            return true;
        }
    }

 

    static updateVpnDisconnectedContactId = async (currentContactId) => {
        try {
            const { currentConversation } = store.getState().contactCenter;
            const {
                jcAuthData: { origin: { value: jcAuthOrigin = '' } = {}, linkedConversationId: { value: linkedConversationId = '' } = {}, channel: { value: channel = '' } = {} } = {},
                ocPlatformData: { chatInfo: { initialContactId = '' } = {}, callInfo: { origin = '' } = {} } = {}
            } = currentConversation || {};
            const origin_calculated = origin || jcAuthOrigin;
            console.log(" Triggered updateVpnDisconnectedContactId payload 1", origin_calculated, channel, initialContactId, linkedConversationId)
            const conversationId = getConversationIdIncaseOfChatToPhone(origin_calculated, channel, initialContactId, linkedConversationId);
            console.log(" Triggered updateVpnDisconnectedContactId payload final", conversationId, currentContactId)
            if (conversationId && currentContactId) {
                await updateVpnDisconnectedContactId({
                    conversationId,
                    currentContactId
                })
            }
            return true;
        } catch (e) {
            // Handling catch block also as True as this should not affect any of exisiting functionality
            console.log(" Triggered updateVpnDisconnectedContactId Block - Error", e)
            return true;
        }
    }

    static warmTransferVoiceCall = async (transferObj: any) => {
        try {
            const { currentConversation, agentDetails, bannerInfo } = store.getState().contactCenter;
            const { language = 'en' } = store.getState().preferences
            const {
                jcAuthData: { transferCount: { value: transCount = 0 } = {}, queuePath: { value: currentQueue = '' } = {} } = {},
                ocPlatformData: { callInfo: { currentContactId = '' } = {} } = {}
            } = currentConversation || {};
            const { isRinging = false } = bannerInfo;
            const { queue: { name: queueName = '' } = {}, overrideSuggestion = '' } = transferObj;

            let transferNote = transferObj?.message?.length > 1024 ? transferObj.message.slice(0, 1024) : transferObj.message;

            const payload: any = {
                attributes: {
                    transferCount: (Number(transCount) + 1).toString(),
                    transferNote,
                    transferNoteFound: transferNote ? 'true' : 'false',
                    queuePath: currentQueue ? `${currentQueue}, ${queueName}` : queueName,
                    // currentQueue: queueName,
                    transferType: CALLEVENTS.TRANSFER_TYPE.WARM,
                    overrideSuggestion: overrideSuggestion,
                    previousAgentId: agentDetails?.ldap || ''
                },
                contactId: currentContactId
            }

            if (isRinging) return;
            bannerInfo.queueName = queueName;
            store.dispatch(setBannerInfo(bannerInfo))

            if (queueName === SECURE_IVR) {
                delete payload.attributes.transferCount;
                delete payload.attributes.queuePath;
            }

            const updated = await updateContactAttributes(payload);
            if (updated) {
                connect?.agent(async (agent) => {
                    const contactToTransfer = Utility.getActiveConnection(agent, currentContactId);
                    if (!contactToTransfer) {
                        alert(localization[language].TRANSFER_FAILED)
                        return;
                    }
                    const findQueueForArn = agentDetails?.skills?.find((s: any) => s.name === queueName);
                    const transferObjNew = { ...transferObj.queue };
                    if (!transferObjNew.endpointARN && findQueueForArn?.endpointARN) {
                        transferObjNew['endpointARN'] = findQueueForArn?.endpointARN;
                    }
                    contactToTransfer?.addConnection(transferObjNew, {
                        async success() {
                            await new Promise((res) => setTimeout(res, 3000));
                            const updatePayload = {
                                multipleAgentsConnected: true,
                                assignedAgent: true,
                                isTransfer: !(transferObjNew?.name === SECURE_IVR) || false
                            }
                            Utility.setCallInfoData(updatePayload);
                            console.log('Transfer warm call success')
                        },
                        failure(err) {
                            console.log('Transfer warm call failed', err)
                        }
                    })
                })
            } else {
                alert(localization[language].TRANSFER_FAILED)
            }
        } catch (error) {
            console.log('Transfer call block failed', error)
        }
    }

    static joinVoiceCall = async (transferObj: any) => {
        const { currentConversation } = store.getState().contactCenter;
        const { ocPlatformData: { callInfo: { currentContactId = '' } = {} } = {} } = currentConversation || {};
        try {
            connect?.agent(async (agent) => {
                const contactToTransfer = Utility.getActiveConnection(agent, currentContactId);
                if (!contactToTransfer) return;
                contactToTransfer?.conferenceConnections({
                    async success() {
                        await new Promise((res) => setTimeout(res, 1000));
                        if (currentConversation?.ocPlatformData?.callInfo) {
                            const updatePayload = {
                                isJoin: !transferObj.isColdTransfer ? true : false
                            }
                            Utility.setCallInfoData(updatePayload);
                        }
                        console.log('Join call in conference success')
                    },
                    failure(err) {
                        console.log('Join call in conference failed', err)
                    }
                })
            })
        } catch (error) {
            console.log('Join call in conference block falied', error)
        }
    }

    static coldTransferVoiceCall = async (transferObj: any) => {
        try {
            const { currentConversation, agentDetails } = store.getState().contactCenter;
            const { language = 'en' } = store.getState().preferences
            const {
                jcAuthData: {
                    transferCount: { value: transCount = 0 } = {},
                    queuePath: { value: currentQueue = '' } = {},
                    handoffCount: { value: currentHandoffCount = 0 } = {}
                } = {},
                ocPlatformData: { callInfo: { currentContactId = '' } = {} } = {}
            } = currentConversation || {};
            const { queue: { name: queueName = '' } = {}, overrideSuggestion = '' } = transferObj;

            let transferNote = transferObj?.message?.length > 1024 ? transferObj.message.slice(0, 1024) : transferObj.message;

            const payload: any = {
                attributes: {
                    transferCount: (Number(transCount) + 1).toString(),
                    transferNote,
                    transferNoteFound: transferNote ? 'true' : 'false',
                    queuePath: currentQueue ? `${currentQueue}, ${queueName}` : queueName,
                    // currentQueue: queueName,
                    handoffCount: (Number(currentHandoffCount) + 1).toString(),
                    transferType: CALLEVENTS.TRANSFER_TYPE.COLD,
                    overrideSuggestion: overrideSuggestion,
                    previousAgentId: agentDetails?.ldap || ''
                },
                contactId: currentContactId
            }
            const updated = await updateContactAttributes(payload)
            if (updated) {
                connect?.agent(async (agent) => {
                    const contactToTransfer = Utility.getActiveConnection(agent, currentContactId);
                    if (!contactToTransfer) {
                        alert(localization[language].TRANSFER_FAILED)
                        return;
                    }
                    const findQueueForArn = agentDetails?.skills?.find((s: any) => s.name === queueName);
                    const transferObjNew = { ...transferObj.queue };
                    if (!transferObjNew.endpointARN && findQueueForArn?.endpointARN) {
                        transferObjNew['endpointARN'] = findQueueForArn?.endpointARN;
                    }
                    contactToTransfer?.addConnection(transferObjNew, {
                        async success() {
                            await new Promise((res) => setTimeout(res, 1000));
                            transferObj.isColdTransfer = true;
                            Utility.joinVoiceCall(transferObj);
                            ContactCenter.endCall();
                            console.log('Transfer cold call success')
                        },
                        failure(err) {
                            console.log('Transfer cold call failed', err)
                        }
                    })
                })
            } else {
                alert(localization[language].TRANSFER_FAILED)
            }
        } catch (error) {
            console.log('Transfer call block failed', error)
        }
    }

    static swapCall = () => {
        try {
            const { currentConversation } = store.getState().contactCenter;
            const {
                ocPlatformData: {
                    callInfo: {
                        currentContactId = ''
                    } = {}
                } = {}
            } = currentConversation || {};
            connect?.agent(async (agent) => {
                const contact = Utility.getActiveConnection(agent, currentContactId);
                if (!contact) return;
                contact?.toggleActiveConnections({
                    success() {
                        Utility.setCallInfoData({ isSwap: true });
                        console.log('Swap call success');
                    },
                    failure(err) {
                        Utility.setCallInfoData({ isSwap: false });
                        console.log('Swap call failed', err);
                    }
                })
            })
        }
        catch (e) {
            console.log('swapCall failed', e)
        }
    }

    static callInfoHandle = async (c: connect.Contact, event = 'onConnected') => {
        try {
            const activeLeg = Utility.getActiveCallLeg();
            const { bannerInfo, currentConversation } = store.getState().contactCenter;
            const { ocPlatformData: {
                chatInfo: { currentContactId: chatContactId = '' } = {},
                callInfo: { currentContactId = '', isTransfer = false, isHold = false, origin = '', assignedAgent } = {}
            } = {} } = activeLeg;
            const { ocPlatformData: {
                callInfo: { currentContactId: selectedLegCallContactId = '' } = {}
            } = {} } = currentConversation;
            const updateConv = { ...activeLeg };
            const updateBannerInfo = { ...bannerInfo } || {};
            let getConnections = c?.getConnections() || [];
            let getThirdPartyConnections = c?.getThirdPartyConnections() || '';
            let getSingleActiveThirdPartyConnection = c?.getSingleActiveThirdPartyConnection() || '';
            let getInitialConnection = c?.getInitialConnection() || '';
            let getActiveInitialConnection = c?.getActiveInitialConnection() || '';
            let getAgentConnection = c?.getAgentConnection() || {};

            console.log(`Voice Call ${event} getConnections`, getConnections);
            console.log(`Voice Call ${event} getInitialConnection`, getInitialConnection);
            console.log(`Voice Call ${event} getActiveInitialConnection`, getActiveInitialConnection);
            console.log(`Voice Call ${event} getThirdPartyConnections`, getThirdPartyConnections);
            console.log(`Voice Call ${event} getSingleActiveThirdPartyConnection`, getSingleActiveThirdPartyConnection);
            console.log(`Voice Call ${event} getAgentConnection`, getAgentConnection);

            const isTransferState = getSingleActiveThirdPartyConnection ? true : false;
            const isJoinState = isTransferState && assignedAgent ? false : true;
            const isHoldState = getAgentConnection?.isOnHold() || false;
            const contactId = origin === CALLEVENTS.CHAT_TO_PHONE ? chatContactId : (currentContactId || getAgentConnection?.contactId);
            const timerInfo = Utility.getActiveTimerInfo();
            const agentCallStatus = (getAgentConnection?.contactId && getAgentConnection?.isActive()) || false;

            if (isTransfer !== isTransferState) {
                Utility.setCallInfoData({ isTransfer: isTransferState, isJoin: isJoinState })
            }

            if (isHold !== isHoldState) {
                Utility.setCallInfoData({ isHold: isHoldState })
            }

            if (contactId && !timerInfo[contactId] && timerInfo[CALLEVENTS.OUTBOUND_PHONE]) {
                const data = { ...timerInfo[CALLEVENTS.OUTBOUND_PHONE] };
                delete timerInfo[CALLEVENTS.OUTBOUND_PHONE];
                // data['voiceAgentStartTime'] = new Date();
                timerInfo[contactId] = data;
                Utility.setActiveTimerInfo(timerInfo);
            }
            const oldCallConnections = [...bannerInfo.callConnections] || [];
            const refreshConnections = getConnections || [];
            let customerCount = 0;
            refreshConnections.shift();

            updateBannerInfo.queueNames = { ...updateBannerInfo.queueNames } || {};
            updateBannerInfo.contactId = (currentContactId || getAgentConnection?.contactId) || '';
            updateBannerInfo.callConnections = [];
            updateBannerInfo.isRinging = false;
            refreshConnections.forEach((contact) => {
                const endPoint = contact?.getEndpoint()?.phoneNumber || '';
                const contactId = contact?.contactId || '';
                const connectionId = contact?.connectionId || '';
                const isHold = contact?.isOnHold() || false;
                let state = '';

                if (contact?.isActive() && !contact?.isConnecting()) {
                    state = 'ACTIVE';
                }
                if (contact?.isConnecting()) {
                    state = 'CONNECTING';
                    updateBannerInfo.isRinging = true;
                }
                if (contact?.isConnected()) {
                    state = 'CONNECTED';
                }
                if (endPoint !== "INTERNAL-TRANSFER") {
                    customerCount = customerCount + 1;
                }
                if (endPoint === "INTERNAL-TRANSFER" && contact?.isConnecting() && !updateBannerInfo.queueNames[connectionId] && updateBannerInfo.queueName) {
                    updateBannerInfo.queueNames[connectionId] = updateBannerInfo.queueName;
                }
                const isActiveAgent = updateBannerInfo.queueNames[connectionId] ? !(updateBannerInfo.queueNames[connectionId] === SECURE_IVR) : false;
                const connection = { contactId, connectionId, isHold, state, endPoint, isActiveAgent, isClosedByAgent: false };
                (agentCallStatus && state) && updateBannerInfo.callConnections.push(connection);
            });
            Utility.checkParticipantLeft(oldCallConnections, updateBannerInfo.callConnections, getAgentConnection);
            console.log('updateBannerInfo', updateBannerInfo);
            updateBannerInfo.customerCount = customerCount;
            store.dispatch(setBannerInfo(updateBannerInfo));
            if (updateBannerInfo.callConnections.length === 1 && updateBannerInfo.callConnections[0]?.isActiveAgent && updateBannerInfo.queueName) {
                const removeAgentConnId = updateBannerInfo.callConnections[0]?.connectionId || '';
                const removeAgentConn = getConnections.find((a) => a.connectionId === removeAgentConnId);
                if (removeAgentConn) removeAgentConn?.destroy();
                if (removeAgentConn && timerInfo[contactId]?.voiceAgentStartTime) {
                    timerInfo[contactId]['voiceAgentEndTime'] = new Date();
                    Utility.setActiveTimerInfo(timerInfo);
                }
            }

            if (currentContactId && currentContactId === selectedLegCallContactId) {
                if (getThirdPartyConnections.length > 0 && !getSingleActiveThirdPartyConnection && isPrimaryAgentDisconnect) {
                    const { jcAuthData: { handoffCount: { value: _handoffCount = 0 } = {} } = {} } = updateConv;
                    const newHandOffCount = (Number(_handoffCount) + 1).toString();
                    updateConv.ocPlatformData.callInfo['assignedAgent'] = true;
                    updateConv.ocPlatformData.callInfo['handoffCount'] = newHandOffCount;
                    updateConv.ocPlatformData.callInfo['multipleAgentsConnected'] = false;
                    updateConv.jcAuthData['handoffCount'] = { name: 'handoffCount', value: newHandOffCount };
                    isPrimaryAgentDisconnect = false;
                }
                if (getThirdPartyConnections.length > 0 && getSingleActiveThirdPartyConnection) {
                    isPrimaryAgentDisconnect = true;
                }
                updateConv.ocPlatformData.callInfo['singleActiveThirdPartyConnection'] = getSingleActiveThirdPartyConnection;
                updateConv.ocPlatformData.callInfo['connections'] = updateBannerInfo?.callConnections?.length || 0;
                store.dispatch(setCurrentConversation(updateConv))
            }
        }
        catch (e) {
            console.log('callInfoHandle failed', e);
        }
    }

    static async handleHoldResume(eventData: any) {
        try {
            const connectionId = eventData?.connectionId || '';
            const result = Utility.getConnectionHoldResume(connectionId) || {};
            const conn = result?.conn || '';
            const isAgent = result?.isAgent || false;
            if (!conn) return;
            const event = eventData.isHold ? 'hold' : 'resume';
            conn[event]({
                success: () => {
                    isAgent && Utility.setCallInfoData({ isHold: eventData.isHold || false });
                },
                failure: (err: any) => {
                    console.log(`Err in handle ${event}`, err);
                }
            })
        } catch (e) {
            console.log('handleHoldResume failed', e);
        }
    }

    static closeActiveCallConnection = async (eventData: { connectionId: string }) => {
        try {
            store.dispatch(IncomingCall({ openPopup: "" }));
            const contact = ContactCenter.voiceOrCallBack();
            const allConnections = contact[0]?.getConnections() as connect.VoiceConnection || [];
            const conn = allConnections?.find((i: any) => i.connectionId === eventData.connectionId);
            const initialConnection = contact[0]?.getInitialConnection() as connect.VoiceConnection || '';
            const { currentConversation } = store.getState().contactCenter;
            const conv = { ...currentConversation } || {};
            const {
                jcAuthData: { handoffCount: { value: handoffCount = 0 } = {} } = {},
                ocPlatformData: { callInfo: { connections = 0, multipleAgentsConnected = false, currentContactId = '', singleActiveThirdPartyConnection = '', origin = '', linkedConversationId = '' } = {} } = {}
            } = currentConversation;
            const closeCallId = conn?.connectionId || '';
            const initialConnId = initialConnection?.connectionId || '';
            const contactId = (origin === CALLEVENTS.CHAT_TO_PHONE) ? linkedConversationId : currentContactId;
            const timerInfo = Utility.getActiveTimerInfo();
            let contextClosedByAgentTriggered = false

            if (!closeCallId) return;
            if (closeCallId === initialConnId) {
                contextClosedByAgentTriggered = true;
                await Utility.updateContextClosedByAgent();
                if (timerInfo[contactId]?.voiceAgentStartTime) {
                    timerInfo[contactId]['voiceAgentEndTime'] = new Date();
                    Utility.setActiveTimerInfo(timerInfo);
                    console.log('timerInfo CloseActive', timerInfo[contactId])
                }
            }
            //Update to Context data regarding the info where conversation is closed by agent
            if (!multipleAgentsConnected && !contextClosedByAgentTriggered) {
                await Utility.updateContextClosedByAgent();
            }

            conn?.destroy();
            store.dispatch(setOutBoundCall(false));

            if (!currentContactId) return;
            if (multipleAgentsConnected) conv.ocPlatformData.callInfo['multipleAgentsConnected'] = false;
            store.dispatch(setCurrentConversation(conv));
            if (connections > 1 && singleActiveThirdPartyConnection && closeCallId === initialConnId) {
                Utility.upsertHandOffCount({ handoffCount, currentContactId })
            }

        } catch (e) {
            console.log('closeActiveCallConnection failed', e);
        }
    }

    static addMultipartyNewConnection = (endpoint: connect.Endpoint, contact: connect.Contact) => {
        try {
            if (!endpoint) return;
            contact?.addConnection(endpoint, {
                async success() {
                    console.log('Multiparty connections success');
                    Utility.setCallInfoData({ isJoin: false });
                },
                failure(err) {
                    console.log('Multiparty connections failed', err)
                }
            })
        } catch (e) {
            console.log('addMultipartyNewConnection failed', e);
        }
    }

    static upsertHandOffCount = async (data: { currentContactId: string, handoffCount: number | string }) => {
        try {
            const { handoffCount = 0, currentContactId = '' } = data;
            if (!currentContactId) return;

            const payload: any = {
                attributes: {
                    "handoffCount": (Number(handoffCount) + 1).toString()
                },
                contactId: currentContactId
            }
            await updateContactAttributes(payload);
        }
        catch (e) {
            console.log('upsertHandOffCount failed', e);
        }
    }

    static getActiveConnection(agent: connect.Agent, currentContactId: string) {
        let activeConn = agent?.getContacts(connect.ContactType.VOICE) || [];
        if (!activeConn.length) activeConn = agent?.getContacts(connect.ContactType.QUEUE_CALLBACK) || [];
        if (!activeConn.length) return '';
        return activeConn.find((a) => a.contactId === currentContactId);
    }

    static getConnectionHoldResume(connectionId?: string) {
        try {
            const contact = ContactCenter.voiceOrCallBack();
            const { bannerInfo } = store.getState().contactCenter;
            const { callConnections = [] } = bannerInfo;
            if (!callConnections.length) return;
            if (!contact.length) return;
            if (callConnections.length === 1) return { conn: contact[0]?.getInitialConnection() || '', isAgent: true };
            if (connectionId) {
                const allConnections = contact[0]?.getConnections() as connect.VoiceConnection || [];
                return { conn: allConnections?.find((i: any) => i.connectionId === connectionId) || '', isAgent: false };
            }
            return { conn: contact[0]?.getAgentConnection() || '', isAgent: true };
        } catch (e) {
            console.log('getConnectionHoldResume failed', e);
        }
    }

    static getOctAndThisAgentTimer = (params: any) => {
        try {
            const { contactId, convStatus, callStatus, origin, convDuration, octDuration, chartStartTime, channel, updateDate, isAcw } = params || {};
            const timerInfo = Utility.getActiveTimerInfo();
            const activeTimerInfo = timerInfo[contactId] || {};
            const voiceAgent2EndTime = (activeTimerInfo?.voiceAgentStartTime && !activeTimerInfo.voiceAgentEndTime) ? new Date() : '';
            const chatAgentStartTime = origin === CALLEVENTS.OUTBOUND_PHONE ? chartStartTime : (activeTimerInfo?.chatAgentStartTime || new Date());
            const voiceAgentStartTime = origin === CALLEVENTS.OUTBOUND_PHONE ? chartStartTime : (activeTimerInfo?.voiceAgentStartTime || new Date());
            const voiceAgentEndTime = activeTimerInfo?.voiceAgentEndTime || voiceAgent2EndTime;
            const showTimerActiveChatOrCallStatus = (convStatus && ((callStatus && callStatus === 'Active') || (convStatus !== 'Closed'))) || false;
            const phoneOrigin = [CALLEVENTS.BOT_TO_PHONE, CALLEVENTS.INBOUND_PHONE, CALLEVENTS.OUTBOUND_PHONE]
            let octTime: number = 0, agentTime: number = 0;
            if (!contactId) { return { octTime, agentTime } }
            if (origin === CALLEVENTS.OUTBOUND_PHONE && !activeTimerInfo?.chatAgentStartTime && !activeTimerInfo?.voiceAgentStartTime && chartStartTime) {
                timerInfo[contactId]['chatAgentStartTime'] = chartStartTime;
                timerInfo[contactId]['voiceAgentStartTime'] = chartStartTime;
                Utility.setActiveTimerInfo(timerInfo);
            }
            if (!origin) { // chat leg
                const newConvDuration = convDuration || Math.round((new Date(updateDate).getTime() - new Date(chatAgentStartTime).getTime()) / 1000);
                octTime = (convStatus === 'Closed' && octDuration >= 0) ? octDuration : (chartStartTime) ? Math.round((new Date().getTime() - new Date(chartStartTime).getTime()) / 1000) : 0;
                agentTime = (convStatus === 'Closed' && convDuration >= 0) ? newConvDuration : (chartStartTime) ? Math.round((new Date().getTime() - new Date(chatAgentStartTime).getTime()) / 1000) : 0;
            } else if (phoneOrigin.includes(origin)) { // call leg
                const newOctDuration = (voiceAgentEndTime && convStatus === 'Active') ? (Math.round((new Date(voiceAgentEndTime).getTime() - new Date(chartStartTime).getTime()) / 1000) || 0) : octDuration;
                const newConvDuration = (voiceAgentEndTime && convStatus === 'Active') ? (Math.round((new Date(voiceAgentEndTime).getTime() - new Date(voiceAgentStartTime).getTime()) / 1000) || 0) : convDuration;
                octTime = ((convStatus === 'Closed' && octDuration >= 0) || isAcw) ? newOctDuration : (chartStartTime) ? Math.round((new Date().getTime() - new Date(chartStartTime).getTime()) / 1000) : 0;
                agentTime = ((convStatus === 'Closed' && convDuration >= 0) || isAcw) ? newConvDuration : (chartStartTime) ? Math.round((new Date().getTime() - new Date(voiceAgentStartTime).getTime()) / 1000) : 0;
            } else if (origin === CALLEVENTS.CHAT_TO_PHONE && channel) { // call and chat leg 
                if (showTimerActiveChatOrCallStatus) {
                    octTime = Math.round((new Date().getTime() - new Date(chartStartTime).getTime()) / 1000) || 0;
                    agentTime = Math.round((new Date().getTime() - new Date(chatAgentStartTime).getTime()) / 1000) || 0;
                } else {
                    octTime = Math.round((new Date(updateDate).getTime() - new Date(chartStartTime).getTime()) / 1000) || octDuration;
                    agentTime = Math.round((new Date(updateDate).getTime() - new Date(chatAgentStartTime).getTime()) / 1000) || convDuration;
                }
            } else if (origin === CALLEVENTS.CHAT_TO_PHONE && !channel) { // call leg (after refresh) 
                octTime = (chartStartTime && voiceAgentEndTime) ? (Math.round((new Date(voiceAgentEndTime).getTime() - new Date(chartStartTime).getTime()) / 1000) || 0) : octDuration;
                agentTime = (voiceAgentEndTime && voiceAgentStartTime) ? (Math.round((new Date(voiceAgentEndTime).getTime() - new Date(voiceAgentStartTime).getTime()) / 1000) || 0) : convDuration;
            }
            return { octTime, agentTime }
        }
        catch (e) {
            console.log('getOctAndThisAgentTimer failed', e);
            return { octTime: 0, agentTime: 0 }
        }
    }

    static getActiveTimerInfo = () => {
        return JSON.parse(localStorage.getItem('thisAgentTimer')) || {};
    }

    static setActiveTimerInfo = (timerInfo: any) => {
        timerInfo && localStorage.setItem('thisAgentTimer', JSON.stringify(timerInfo));
    }

    static onAcwAgentTimer = () => {
        const timerInfo = Utility.getActiveTimerInfo();
        const { currentConversation } = store.getState().contactCenter
        const {
            ocPlatformData: {
                callInfo: { currentContactId = '', origin = '', linkedConversationId = '' } = {},
            } = {}
        } = currentConversation;
        const contactId = (origin === CALLEVENTS.CHAT_TO_PHONE) ? linkedConversationId : currentContactId;
        if (timerInfo[contactId]?.voiceAgentStartTime && !timerInfo[contactId]?.voiceAgentEndTime) {
            timerInfo[contactId]['voiceAgentEndTime'] = new Date();
            Utility.setActiveTimerInfo(timerInfo);
        }
    }

    static getActiveCallLeg = () => {
        const { conversations } = store.getState().contactCenter;
        const cloneConversations = [...conversations] || [];
        const findActiveCall = cloneConversations.findIndex((c: ContactRecord) => c?.ocPlatformData?.callInfo?.status === "Active");
        if (findActiveCall === -1) return {};
        const activeCallLeg = { ...cloneConversations[findActiveCall] };
        return activeCallLeg;
    }

    static checkParticipantLeft(oldCallConnections: any, newCallConnections: any, getAgentConnection: any) {
        if (oldCallConnections?.length > newCallConnections?.length) {
            const reomvedParticipant = oldCallConnections?.filter((obj1: any) => {
                let indexFound = newCallConnections.findIndex((obj2: any) => (obj1.connectionId === obj2.connectionId));
                return indexFound === -1;
            });
            if (!reomvedParticipant.length || !getAgentConnection) return;
            const agentState = getAgentConnection?.isActive();
            reomvedParticipant.forEach((participant: any) => {
                agentState && store.dispatch({ type: 'PARTICIPANT_LEFT_THE_CALL', payload: true });
            })
        }
    }

    static recordDisable = async () => {
        try {
            const { agentDetails, currentConversation } = store.getState().contactCenter;
            const {
                ocPlatformData: { callInfo: { initialContactId = '', currentContactId = '' } = {} } = {}
            } = currentConversation || {};
            const stopPayload = {
                "initialContactId": initialContactId,
                "contactId": currentContactId,
                "action": "stop"
            }
            const res = await stopRecording(stopPayload);
            if (res) {
                const { ldap = "" } = agentDetails
                const payload: any = {
                    attributes: { "recordingDisabledByAgent": ldap }
                }
                await updateContactAttributes(payload);
                store.dispatch({ type: 'RECORDING_DISABLED', payload: { success: true } })
            } else {
                store.dispatch({ type: 'RECORDING_DISABLED', payload: { failed: true } })
            }
        } catch (error) {
            console.log(error);
        }
    }

    static deliveredEventToCustomer = async (params: any, agentChatSession: any) => {
        try {
            const { Id } = params;
            const content = JSON.stringify({ messageId: Id });
            await agentChatSession?.sendEvent({
                contentType: 'application/vnd.amazonaws.connect.event.message.delivered',
                content: content
            })
        } catch (e) {
            console.log('Error handle in deliveredEvent', e);
        }
    }

    static onReadDeliveredReceipt = (cbData: any, isRead?: boolean = false) => {
        try {
            const { chatDetails: { contactId = '' } = {}, data: { MessageMetadata: { MessageId = '' } = {} } = {} } = cbData;

            let { conversations, messageMetaData, autoTranslation } = store.getState().contactCenter;
            const convAutoTranslation = autoTranslation[contactId] || {};

            if (!conversations.length) return;

            const convIndex = conversations.findIndex((x: ContactRecord) => x.ocPlatformData.chatInfo.currentContactId === contactId)
            if (convIndex === -1) return;

            const msgIndex = conversations[convIndex]?.ocPlatformData?.chatTranscript?.findIndex((t) => t.Id === MessageId)
            if (msgIndex === -1) return;

            const MsgData = conversations[convIndex]?.ocPlatformData?.chatTranscript[msgIndex];
            isRead ? MsgData.isRead = true : MsgData.isDelivered = true;
            conversations[convIndex].ocPlatformData.chatTranscript[msgIndex] = MsgData;

            if (convAutoTranslation?.translation) {
                const msgIndexTranslation = conversations[convIndex]?.ocPlatformData?.translationTranscript?.findIndex((t) => t.Id === MessageId);
                if (msgIndexTranslation > 0) {
                    const MsgDataTranslation = conversations[convIndex]?.ocPlatformData?.translationTranscript[msgIndexTranslation];
                    isRead ? MsgDataTranslation.isRead = true : MsgDataTranslation.isDelivered = true;
                    conversations[convIndex].ocPlatformData.translationTranscript[msgIndexTranslation] = MsgDataTranslation;
                }
            }

            store.dispatch(setConversations(conversations));

            if (!messageMetaData[contactId]) return;

            let upsertMessageMetaData = messageMetaData[contactId];

            if (!upsertMessageMetaData?.messageId || !MessageId || upsertMessageMetaData.messageId !== MessageId) return;

            isRead ? upsertMessageMetaData.isRead = true : upsertMessageMetaData.isDelivered = true;

            store.dispatch(setMessageMetaData(upsertMessageMetaData));
        } catch (e) {
            console.log('Error handle in onReadDeliveredReceipt', e);
        }
    }

    static getPathFromTranscript(transcript = []): string {
        if (!transcript.length) return ''
        transcript.sort((message1: Message, message2: Message) => {
            const ts1 = Date.parse(message1.timeStamp)
            const ts2 = Date.parse(message2.timeStamp)
            return ts1 - ts2
        })
        const agentsNames = transcript.reduce((agents: any[], message: Message) => {
            const { ParticipantRole, DisplayName, ParticipantId, Type } = message
            const agentsLength = agents.length
            if ((ParticipantRole === 'AGENT' || ParticipantRole === 'CUSTOM_BOT' || ParticipantRole === 'SYSTEM') && Type === 'MESSAGE')
                if (ParticipantId !== agents?.[agentsLength - 1]?.ParticipantId) {
                    if (ParticipantRole === 'CUSTOM_BOT' || (ParticipantRole === 'SYSTEM' && DisplayName !== 'SYSTEM_MESSAGE')) {
                        agents.push({ DisplayName: 'BOT', ParticipantId })
                    } else if (ParticipantRole === 'AGENT') {
                        agents.push({ DisplayName, ParticipantId })
                    }
                }
            return agents
        }, []).map(agent => agent.DisplayName)

        return Array.from(agentsNames).join(' -> ')
    }
}

/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable array-callback-return */
import {
  OPEN_WIDGETS,
  UDATE_WIDGETWIDTH,
  TRANSFER_COUNT,
  SET_RU_WIDGET
  // ,
  // SET_USER_WIDGETS
} from '../constants/widgetLayoutConstants'

export const triggerWidget = (widgets: any, title: string, widgetWrapperWidth: number, widgetStack: string[]) => (dispatch: any, getState: any) => {
  const {
    widgetLayout: { ruExpanded = '', ruWidgetBottom = '', ruWidgetBottomShow= {} } = {},
    contactCenter: {currentConversation: {ocPlatformData: {chatInfo: {currentContactId = ''} = {}} = {} } = {}} = {}
  } = getState()
  const extraMargin = 30 + 30
  let widthUsed = 0

  const RUWidget = widgets.find((widget: any) => widget.title === 'RU')

  // eslint-disable-next-line
  widgets.map((widget: any) => {
    // Add widget to check if width is available
    if (widget.title === title) {
      widget.active = !widget.active
    }
    // calculate used width
    widthUsed = widget.active && 
      (widget.title !== RUWidget.title || 
      (widget.title === RUWidget.title && !ruWidgetBottomShow[currentContactId])) ? 
        widthUsed + widget.width + 10 : widthUsed
    // Remove widget statue after checking
    if (widget.title === title) {
      widget.active = !widget.active
    }
  })

  // check if width is available
  if (widthUsed + extraMargin < widgetWrapperWidth) {
    // eslint-disable-next-line
    widgets.map((widget: any) => {
      // Add widget if width is available
      if (widget.title === title && !widget.active) {
        widget.active = !widget.active
        widget.position = 1
      } else if (widget.title === title && widget.active) {
        widget.active = !widget.active
        widget.position = -1
      }
      if (widget.title !== title && widget.position === 1) {
        widget.position = 0
      } else if (widget.title !== title && widget.position === 0) {
        widget.position = -1
      }
      if(widget.title === RUWidget.title) {
          widget.position = -1
      }
    })
  } else {
    widgets.map((widget: any) => {
      // remove widget if width is available not available
      if (widget.title !== title && widget.position === 1) {
        widget.active = !widget.active
        // widget.position = 0;
      }
      if (widget.title === title) {
        widget.active = !widget.active
        // widget.position = 1;
      }
    })
    widthUsed = 0
    // eslint-disable-next-line
    widgets.map((widget: any) => {
      // calculate used width
      widthUsed = widget.active && 
      (widget.title !== RUWidget.title || 
      (widget.title === RUWidget.title && !ruWidgetBottomShow[currentContactId])) ? 
        widthUsed + widget.width + 10 : widthUsed
    })
    if (widthUsed + extraMargin > widgetWrapperWidth) {
      widgets.map((widget: any) => {
        // remove widget if width is available not available
        if (widget.title !== title && widget.position === 0) {
          widget.active = !widget.active
          // widget.position = 0;
        }
      })
    }

    if (widthUsed + extraMargin < widgetWrapperWidth) {
      // eslint-disable-next-line
      widgets.map((widget: any) => {
        if (widget.title !== title && widget.position === 1) {
          widget.position = 0
        } else if (widget.title !== title && widget.position === 0) {
          widget.position = -1
        }
        if (widget.title === title) {
          widget.position = 1
        }
        if(widget.title === RUWidget.title) {
          widget.position = -1
        }
      })
    } else {
      // eslint-disable-next-line
      widgets.map((widget: any) => {
        if (widget.title !== title && widget.position === 1) {
          // widget.active = !widget.active;
          widget.position = -1
        }
        if (widget.title !== title && widget.position === 0) {
          // widget.active = !widget.active;
          widget.position = -1
        }
        if (widget.title === title) {
          // widget.active = !widget.active;
          widget.position = 1
        }
        if(widget.title === RUWidget.title) {
          widget.position = -1
        }
      })
    }
  }

  widgets.map((widget: any) => {
    // When RU widget is active, make the bottom widget active if RU is not expanded and ruWidgetBottomShow is true for convId
    if (widget.title === ruWidgetBottom && RUWidget?.active) {
      widget.active = !ruExpanded && (ruWidgetBottomShow[currentContactId])
    }
  })

  return dispatch({ type: OPEN_WIDGETS, payload: widgets })
}

export const closeWidgets = (widgets: any) => (dispatch: any) => {
  console.log(
    'close Widget',
    widgets,
    [...widgets].map((a) => {
      a.active = false
      a.position = -1
      return a
    })
  )
  return dispatch({
    type: OPEN_WIDGETS,
    payload: [...widgets].map((a) => {
      a.active = false
      a.position = -1
      return a
    })
  })
}
export const handleScreenResize = (widgets: any, widgetStack: any, widgetWrapperWidth: any) => (dispatch: any, getState: any) => {
  const {
    widgetLayout: { ruWidgetBottom }
  } = getState()

  const RUWidget = widgets.find((widget: any) => widget.title === 'RU')
  const extraMargin = 30 + 35

  let widthUsed = 0
  let lastActiveWidget = ''
  // eslint-disable-next-line
  widgets.map((widget: any) => {
    // calculate used width
    widthUsed = widget.active && ((RUWidget.active && widget.title !== ruWidgetBottom) || !RUWidget.active) ? widthUsed + widget.width + 10 : widthUsed
    lastActiveWidget = widget.active ? widget.title : lastActiveWidget
  })
  if (widthUsed + extraMargin > widgetWrapperWidth && lastActiveWidget !== '') {
    dispatch({
      type: OPEN_WIDGETS,
      payload: [...widgets].map((a) => {
        if (a.title === lastActiveWidget) {
          a.active = false
          a.position = -1
        }
        return a
      })
    })
  }

  return dispatch({ type: UDATE_WIDGETWIDTH, payload: widgetWrapperWidth })
}

export const triggerMultipleWidget = (newWidgets: any) => (dispatch: any) => {
  dispatch({ type: OPEN_WIDGETS, payload: newWidgets })
}

export const triggerTransferCount = (transferCount: number) => (dispatch: any) => {
  dispatch({ type: TRANSFER_COUNT, payload: transferCount })
}

export const setRuWidget = (widget: string = '') => (dispatch: any) => {
  dispatch({ type: SET_RU_WIDGET, payload: widget })
}

// export const setUserWidgets = (conversation: any) => (dispatch: any) => {
//   dispatch({ type: SET_USER_WIDGETS, payload: conversation });
// }

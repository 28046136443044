import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './reducers/rootReducer'

const middleWare = [thunk]

export type RootStore = ReturnType<typeof rootReducer>

const store = createStore(rootReducer, compose(applyMiddleware(...middleWare)))
export default store

import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { LibraryModule as AALibrary } from 'oc-aa-module-client'
import env from '../../../../config/env_variables'
import { SET_PDC_MESSAGE } from '../../../../redux/constants/pdcConstants'
import './AdobeAnswersPanel.scss'
import 'oc-aa-module-client/dist/index.css'
import { RootState } from '../../../../redux/reducers/rootReducer'

interface URLS {
  GENAI_MIDDLEWARE_URL: string,
  FG_ENDPOINT_AAFeedback: string
}

const AdobeAnswersPanel = (question) => {
  const dispatch = useDispatch()

 const { darkMode, language } = useSelector((state: RootState) => state.preferences)
 const {agentDetails = {}, currentConversation, currentConversationAC, conversationSummaries } = useSelector((state: RootState) => state.contactCenter)
 const conversationId = currentConversation?.ocPlatformData?.chatInfo?.currentContactId

  function callBackEventListener(eventType: string, eventData: any): void {
    if (eventType === 'COPY_PASTE_MESSAGE') {
      const { message : {text =''} = {} } = eventData
      if (currentConversation?.ocPlatformData?.chatInfo?.status !== 'Closed') dispatch({ type: SET_PDC_MESSAGE, payload: text})
    } 
  }

  useEffect(() => {
    dispatch({ type: SET_PDC_MESSAGE, payload: '' })
  }, [currentConversationAC, currentConversation, dispatch])

  
  const urls: URLS = {
    GENAI_MIDDLEWARE_URL: env.GENAI_MIDDLEWARE_URL,
    FG_ENDPOINT_AAFeedback: env.FG_ENDPOINT_AAFeedback
  }

  return (
    <div className='adobe-answers-management'>
      <AALibrary
        conversationSummary={conversationSummaries[conversationId]}
        conversationId={conversationId}
        agentDetails={agentDetails}
        contextData={question}
        callBackEvent={callBackEventListener}
        darkMode={darkMode}
        language={language}
        urls={urls}
      />
    </div>
  )
    
  
}

export default AdobeAnswersPanel
import { SET_FILTERS, SET_SCROLL_INTO_VIEW } from '../constants/flyoutConstants'

interface State {
  filterStates: any
  scroll: boolean
}

const initState: State = {
  filterStates: {
    active: true,
    overdue: true,
    idle: true,
    closed: true
  },
  scroll: false
}

const flyoutPanelReducer = (state: State = initState, action: any) => {
  const filterdata: { filter: string; selected: boolean } = action.payload
  const filters = { ...state.filterStates }

  switch (action.type) {
    case SET_FILTERS:
      filters[filterdata.filter] = action.payload.selected
      return { ...state, filterStates: filters }
    case SET_SCROLL_INTO_VIEW:
      return { ...state, scroll: action.payload }

    default:
      return state
  }
}

export default flyoutPanelReducer

// @ts-nocheck
import React from 'react'
import { LibraryModule as AllConversations } from 'oc-all-conversation-module-client'
import 'oc-all-conversation-module-client/dist/index.css'
import { useSelector } from 'react-redux'
import { ContactRecord, ConversationACData } from '../../ContactCenter/Models'
import env_variables from '../../../config/env_variables'
import { setACData } from '../../../redux/actions/contactCenterActions'
import store from '../../../redux/store'

interface State {
  contactCenter: {
    conversations: ContactRecord[]
    agentDetails: any
    ACData: ConversationACData
  }
  preferences: {
    darkMode: boolean
    language: string
  }
}

const Container = (props: any) => {
  const { darkMode, language } = useSelector((state: State) => state.preferences)

  const { agentDetails, ACData } = useSelector((state: State) => state.contactCenter)

  function callBackEventListener(eventType: string, eventData: any): void {
    const { cbEventListener } = props
    if (eventType === 'listView' || eventType === 'conversationView' || eventType === 'phone-in-transcript') {
      cbEventListener(eventType, eventData)
    } else if (eventType === 'upsert' && eventData) {
      const isNewApiCall = eventData.isNewApiCall || false
      delete eventData.isNewApiCall
      store.dispatch(setACData(eventData))
      if (isNewApiCall && eventData.isAppliedFilter) {
        cbEventListener('resetCurrentConversationAC')
      }
    }
  }

  let token
  if (localStorage.getItem('ims-token')) {
    token = JSON.parse(localStorage.getItem('ims-token') || '')
  }
  const json = {
    darkMode,
    lang: language,
    agentId: agentDetails.ldap,
    apiEndPoint: env_variables.CCP_API_END_POINT,
    acData: ACData,
    token,
    apiKey: 'oac-connect-service'
  }

  return <AllConversations contextData={json} callBackEvent={callBackEventListener} />
}

export default Container

/* eslint-disable import/prefer-default-export */
// If this is not using anywhere we can remove this file.
import axios from 'axios'

const AXIOS_API_TIMEOUT = 10000

const apiClient = axios.create({
  timeout: AXIOS_API_TIMEOUT,
  headers: { 'Content-Type': 'application/json' }
})
export { apiClient }

const localization: any = {
  en: {
    OFFLINE: {
      HEADING: 'You have been moved to offline status. If this is incorrect, please update your Agent Status.',
      ACTION: 'OK'
    },
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Disable Call Recording",
      MESSAGE:"The request to disable call recording failed due to technical difficulty.",
      OK:"OK"
    }
  },
  ja: {
    OFFLINE: {
      HEADING: 'オフライン状態に移行しました。間違っている場合は、担当者のステータスを更新してください。',
      ACTION: 'OK'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"通話録音を無効にする",
      MESSAGE:"通話録音を無効にするリクエストは、技術的な問題により失敗しました。",
      OK:"OK"
    }
  },
  fr: {
    OFFLINE: {
      HEADING: 'Votre statut a été défini comme hors ligne. Si cela est incorrect, veuillez mettre à jour votre statut d’agent.',
      ACTION: 'OK'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Désactiver l'enregistrement des appels",
      MESSAGE:"La demande de désactivation de l'enregistrement des appels a échoué en raison d'un problème technique.",
      OK:"OK"
    }
  },
  de: {
    OFFLINE: {
      HEADING: 'Sie wurden in den Offline-Status versetzt. Wenn dies nicht korrekt ist, aktualisieren Sie bitte den Agentenstatus .',
      ACTION: 'OK'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Anrufaufzeichnung deaktivieren",
      MESSAGE:"Die Anfrage, die Anrufaufzeichnung zu deaktivieren, ist aufgrund eines technischen Problems fehlgeschlagen.",
      OK:"OK"
    }
  },
  es: {
    OFFLINE: {
      HEADING: 'Ha sido trasladado al estado offline. Si esto es incorrecto, por favor actualice su estado de agente.',
      ACTION: 'Aceptar'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'Aceptar'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'Aceptar'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Inhabilitar la grabación de llamadas",
      MESSAGE:"La solicitud para deshabilitar la grabación de llamadas falló debido a una dificultad técnica.",
      OK:"OK"
    }
  },
  it: {
    OFFLINE: {
      HEADING: 'Il tuo stato è ora offline. Se non è corretto, aggiorna il tuo Stato Agente.',
      ACTION: 'OK'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Desactivar la grabación de llamadas",
      MESSAGE:"La richiesta di disabilitare la registrazione delle chiamate non è riuscita a causa di difficoltà tecniche.",
      OK:"OK"
    }
  },
  'pt-BR': {
    OFFLINE: {
      HEADING: 'Você foi movido ao status offline. Se isto estiver incorreto, atualize seu Status de Agente.',
      ACTION: 'OK'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: 'OK'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: 'OK'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"Desativar gravação de chamadas",
      MESSAGE:"A solicitação para desativar a gravação de chamadas falhou devido a dificuldades técnicas.",
      OK:"OK"
    }
  },
  'zh-TW': {
    OFFLINE: {
      HEADING: '您已進入離線狀態。如果狀態錯誤，請更新您的客服專員狀態。',
      ACTION: '確定'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: '確定'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: '確定'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"停用通話錄音",
      MESSAGE:"由於技術困難，禁用通話錄音的請求失敗。",
      OK:"OK"
    }
  },
  'zh-CN': {
    OFFLINE: {
      HEADING: '您已被移至离线状态。如果这不正确，请更新您的客服专员状态。',
      ACTION: '确定'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: '確定'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: '確定'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"禁用通话录音",
      MESSAGE:"由于技术困难，禁用通话录音的请求失败.",
      OK:"OK"
    }
  },
  ko: {
    OFFLINE: {
      HEADING: '오프라인 상태로 전환되었습니다. 올바르지 않은 경우 상담사 상태를 업데이트하십시오.',
      ACTION: '확인'
    },
    // Not available
    ACCESS_DENIED: {
      HEADING: 'Please clear your browser cache and cookies.  Contact your System Administrator if you are still encountering issues logging into the Omnichannel Agent Console',
      ACTION: '확인'
    },
    AWS_TOKEN_EXPIRY: {
      HEADING: 'Your session authentication has expired.  Please relogin.',
      ACTION: '확인'
    },
    DISABLE_CALL_RECORDING_FAILURE:{
      TITLE:"통화 녹음 비활성화",
      MESSAGE:"통화 녹음 비활성화 요청이 기술적인 문제 때문에 실패했어요.",
      OK:"OK"
    }
  },
}
export default localization

/* eslint-disable import/no-cycle */
import { ContactRecord, Transcript, ConversationACData } from '../../Apps/ContactCenter/Models'
import { SET_AGENT_DETAILS } from '../constants/preferencesConstants'
import ContactCenter from '../../Apps/ContactCenter/Modules/ContactCenter'
import { updateConversationSummaryFeedbackAPI, getClosedConversationSummaryAPI } from '../../Apps/ContactCenter/Api/transcripts'
import { enableConvSummaryByQueue } from '../../utils/fg-utils'

export const initCCP = () => (dispatch: any) => {
  dispatch({ type: 'INITCCP', payload: true })
}

export const setCurrentStatus = (newState: string, updatedBy: string) => (dispatch: any) => {
  console.log(`CCP Status set dispatch is triggered with new status : ${newState}`)
  dispatch({ type: 'SETCCPSTATE', payload: newState, updatedBy })
}

export const setCurrentConversation = (conversation: ContactRecord) => async (dispatch: any) => {
  const transcript = conversation.ocPlatformData.chatTranscript
  if (!transcript || transcript.length === 0) {
    const res = await ContactCenter.getTranscript(conversation.ocPlatformData.chatInfo.initialContactId)
    conversation.ocPlatformData.chatTranscript = res?.data || []
  }
  const newCus = conversation.ocPlatformData.chatInfo.newCustomer
  if (typeof newCus == 'undefined') {
    conversation.ocPlatformData.chatInfo.newCustomer = await ContactCenter.isNewCustomer(conversation.jcAuthData?.customerId?.value)
  }
  dispatch({ type: 'SETCURRENTCONVERSATION', payload: conversation })
}

export const setConversations = (conversations: ContactRecord[]) => (dispatch: any) => {
  console.trace('Tracing set conversations');
  dispatch({ type: 'SETCONVERSATIONS', payload: conversations })
}

export const addMessage = (message: Transcript) => (dispatch: any) => {
  dispatch({ type: 'ADDMESSAGE', payload: message })
}

export const setAgentDetails = (agentDetails: any) => (dispatch: any) => {
  dispatch({ type: 'SETAGENTDETAILS', payload: agentDetails })
  dispatch({ type: SET_AGENT_DETAILS, payload: agentDetails })
}

export const setCurrentAC = (currentConversationAC: ContactRecord) => async (dispatch: any) => {
  const transcript = currentConversationAC?.ocPlatformData?.chatTranscript || []
  if ((!transcript || transcript.length === 0) && currentConversationAC?.ocPlatformData?.chatInfo?.currentContactId) {
    const resp = await ContactCenter.getTranscript(currentConversationAC.ocPlatformData.chatInfo.initialContactId)
    currentConversationAC.ocPlatformData.chatTranscript = resp?.data || []
  }
  dispatch({ type: 'SETCURRENT_AC', payload: currentConversationAC })
}

export const setAllAC = (conversationsAC: ContactRecord[]) => (dispatch: any) => {
  dispatch({ type: 'SETALL_AC', payload: conversationsAC })
}

export const setCurrentView = (currentView: string) => (dispatch: any) => {
  dispatch({ type: 'SET_CURRENT_VIEW', payload: currentView })
}

export const setACData = (ACData: ConversationACData) => (dispatch: any) => {
  dispatch({ type: 'SET_AC_DATA', payload: ACData })
}

export const saveDraft = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SAVE_DRAFT', payload: data })
}

export const setOnTyping = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ON_TYPING', payload: data })
}

export const setUpload = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ON_UPLOADING', payload: data })
}

export const endCall = () => (dispatch: any) => {
  dispatch({ type: 'END_CALL' })
}

export const IncomingCall = (data: any) => (dispatch: any) => {
  dispatch({ type: 'INCOMING_CALL', payload: data })
}

export const setAutoTranslation = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AUTO_TRANSLATION', payload: data })
}

export const setConversationSummary = (data: { contactId: any, summary: any, final: boolean }) => (dispatch: any) => {
  dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: data})
}

export const updateConversationSummaryFeedback = (data:  { contactId: any, feedback: any, summary: any, path: string }) => async (dispatch: any) => {
  const { contactId, feedback, summary, path } = data
  const params = {
    "SequenceId": summary?.SequenceId,
    "SummaryUseful": feedback ? 1: 0,
    "AgentFeedbackCategory": path,
  }
  const response = await updateConversationSummaryFeedbackAPI(params, contactId)
  if(response) {
    dispatch({ type: 'CONVERSATION_SUMMARY_FEEDBACK', payload: { contactId, feedback, summary, path }})
    
  }
}

export const setBannerInfo = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_BANNER_INFO', payload: data })
}

export const setOutBoundCall = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_OUTBOUND_CALL', payload: data })
}

export const setAcw = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_ACW', payload: data })
}

export const setAgentFeatureTags = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AGENT_FEATURE_TAGS', payload: data })
}

export const setAuthFail = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_AUTH_FAIL', payload: data })
}

export const setMessageMetaData = (data: any) => (dispatch: any) =>  {
  dispatch({ type: 'SET_MESSAGE_META_DATA', payload: data })
}

export const getClosedConversationSummary = (conversationId: any, queue: any) => async (dispatch: any) => {
  if(!conversationId) return
  let isEnabledByQueue = false;
  const nodata = {
    contactId: conversationId,
    feedback: undefined,
    ldap: undefined,
    path:  'No data',
    summary: {
      main_issue: 'Summary is not available for this conversation',
      conversation_points:[],
      links:[]
    }
  } 
  try{
    isEnabledByQueue = await enableConvSummaryByQueue(queue);
  }catch(e){
    console.error('Error with checking summary eligibility, Error: ', e)
  }
  if(!isEnabledByQueue) {
    return;
  }
  const params = { conversationId }
  const loadingPath = 'Loading data...'
  const loadingSummary = {
    main_issue: 'Loading data...',
    conversation_points: ['Loading data...'],
    links: ['Loading data...'],
  }
  try{
    dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: { contactId: conversationId, feedback: undefined, ldap: undefined, summary: loadingSummary, path: loadingPath }})
    const result: any = await getClosedConversationSummaryAPI(params)
    const { Summary: summary} = result

    //if final === true but in the table only 1 summary -> that is mean
    //that final summary was not generated and reaponse will contain empty Summary: {}
    if(summary && Object.keys(summary).length > 0) {
      dispatch({ type: 'SETCONVERSATIONSUMMARY',
        payload: {
          contactId: conversationId,
          feedback: result?.SummaryUseful,
          ldap: result?.AgentLdap,
          summary: summary,
          path: result?.TransferCategory,
          final: result?.final
        }
      })
    } else {
      //in case if final summary was not generated yet, will show empty summary
      dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: nodata })
    }
  }catch (e) {
    console.error('Error for fetching existing conversation summaries getClosedConversationSummary, Error: ', e)
    dispatch({ type: 'SETCONVERSATIONSUMMARY', payload: nodata })
  }
}

export const toggleTranslationforCS = (data: any) => (dispatch: any) => {
  dispatch({ type: 'TOGGLE_TRANSLATION_FOR_CS', payload: data })
}

export const setIsBarged = (data: any) => (dispatch: any) => {
  dispatch({ type: 'SET_IS_BARGED', payload: data })
}

import { OPEN_PAGE } from '../constants/navBarConstants'

export interface State {
  navBar: {
    navBarToggles: { chatToggle: boolean; historyToggle: boolean }
    header: string
  }
}

export const navBarReducer = (
  state = {
    navBarToggles: { chatToggle: true, historyToggle: false },
    header: 'Test Customer'
  },
  action: any
) => {

  switch (action.type) {
    case OPEN_PAGE:
      const { navBarToggles = {}, header } = action.payload
      return { ...state, navBarToggles, header }
    default:
      return state
  }
}

/* eslint-disable no-shadow */
// @ts-nocheck
import React, { useEffect, Profiler } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Grid, View } from '@adobe/react-spectrum'
import CustomerChatPanel from '../CustomerChatPanel/CustomerChatPanel'
import WidgetContainer from '../../Components/WidgetContainer'
import ToolBar from '../ToolBar/ToolBar'
import TopBar from '../../Components/TopBar'
import FlyoutPanel from '../FlyoutPanel/FlyoutPanel'
import { ContactRecord, ConversationACData } from '../../../ContactCenter/Models'
import ContactCenter from "../../../ContactCenter/Modules/ContactCenter";
import AllConversations from '../../../AllConversations/AllConversations'
import { setCurrentAC, setAllAC, setCurrentView, setACData, setOutBoundCall} from '../../../../redux/actions/contactCenterActions'
import { SET_SCROLL_INTO_VIEW } from '../../../../redux/constants/flyoutConstants'
import { onRenderCB } from '../../../../helpers/widget-performance-analytics'
import store from '../../../../redux/store'
import '../../styles.scss'
import { useHistory, useLocation } from 'react-router-dom'
import { CALLEVENTS } from '../../../ContactCenter/Constants'
import { CLICK_PHONE_NUMBER } from '../../../../redux/constants/changeCustomerConstants'

declare const window: any

interface State {
  preferences: {
    darkMode: boolean
    language: string
  }
  contactCenter: {
    currentConversationAC: ContactRecord
    ACData: ConversationACData
  }
}
const AllConversationsContainer = () => {
  const { currentConversationAC, ACData } = useSelector((state: State) => state.contactCenter)
  const { isListView } = ACData
  const { darkMode: globalDarkMode } = useSelector((state: State) => state.preferences)
  const history = useHistory();
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    if (ACData && !ACData.isDateChange) {
      const defaultStartDate = new Date()
      defaultStartDate.setDate(defaultStartDate.getDate() - 6)
      ACData.dateTime.startDate = defaultStartDate
      ACData.dateTime.endDate = new Date()
      store.dispatch(setACData(ACData))
    }
    store.dispatch(setCurrentView('AC_VIEW'))
    return () => {
      store.dispatch(setCurrentView(''))
    }
  }, [ACData])

  const basicThemeClass = globalDarkMode ? 'basic-dark' : 'basic-light'
  const flyoutThemeClass = globalDarkMode ? 'flyout-dark' : 'flyout-light'
  const widgetBackground = globalDarkMode ? 'view-widget-container-dark' : 'view-widget-container'

  const girdData = {
    isConView: {
      areas: ['Flyout-panel  header header', 'Flyout-panel chat widget', 'Flyout-panel  toolbar widget'],
      columns: ['100px', '425px', 'auto'],
      rows: ['140px', 'calc(100vh - 200px)', '60px']
    },
    isListView: {
      areas: ['header'],
      columns: ['auto'],
      rows: ['auto']
    }
  }

  function cbEventListener(eventType: string, eventData: any): void {
    if (eventType === 'listView' || eventType === 'conversationView') {
      ACData.isListView = eventType === 'listView'
      store.dispatch(setACData(ACData))
      if (eventType === 'conversationView' && isListView === true) {
        setAllConversationsData(eventData)
      }
    } else if (eventType === 'resetCurrentConversationAC') {
      setAllConversationsData({ resetCurrentConversationAC: true })
    }
    else if (eventType === CALLEVENTS.TRANSCRIPT_PHONE) {
      dispatch({ type: CLICK_PHONE_NUMBER, payload: eventData })
      if (location.pathname === '/history') history.push('/chat')
      dispatch(setOutBoundCall(true));
    }
  }

  function setAllConversationsData(eventData: any) {
    const allConvData: any = eventData.allConvData || []
    const conversationsAC: any = []
    const currentConversationAC: any = eventData.currentAllConv || {
      ocPlatformData: {
        chatInfo: {
          initialContactId: '',
          currentContactId: '',
          status: ''
        },
        chatTranscript: []
      }
    }

    if (eventData?.resetCurrentConversationAC) {
      store.dispatch(setCurrentAC(currentConversationAC))
      return
    }

    allConvData.forEach((convObj: any) => {
      const conv: any = ContactCenter.serializeAttributes(convObj);
      conversationsAC.push(conv)
    })
    store.dispatch(setAllAC(conversationsAC))
    if (currentConversationAC && currentConversationAC.contactId && Object.keys(currentConversationAC).length > 0) {
      const conv: any = ContactCenter.serializeAttributes(currentConversationAC);
      store.dispatch({ type: SET_SCROLL_INTO_VIEW, payload: true })
      store.dispatch(setCurrentAC(conv))
    }
  }

  return (
    <div className={isListView ? 'list-view-grid' : 'conversation-view-grid'}>
      <Grid
        areas={isListView ? girdData.isListView.areas : girdData.isConView.areas}
        columns={isListView ? girdData.isListView.columns : girdData.isConView.columns}
        rows={isListView ? girdData.isListView.rows : girdData.isConView.rows}
        height='100%'
      >
        <View gridArea='header' UNSAFE_className='header-topbar'>
          {window.enable_widget_analytics ? (
            <Profiler id='PrevConvs' onRender={onRenderCB}>
              <AllConversations isListView={isListView} cbEventListener={cbEventListener} />
            </Profiler>
          ) : (
            <AllConversations isListView={isListView} cbEventListener={cbEventListener} />
          )}
          {!isListView && <TopBar />}
        </View>
        <View zIndex={2} gridArea='Flyout-panel' UNSAFE_className={flyoutThemeClass}>
          {!isListView && <FlyoutPanel />}
        </View>
        <View zIndex={1} UNSAFE_className={`${basicThemeClass} chat-container`} gridArea='chat'>
          <CustomerChatPanel />
        </View>
        <View zIndex={0} gridArea='toolbar' UNSAFE_className={`${basicThemeClass} hide-toolbar`}>
          {currentConversationAC &&
            currentConversationAC.ocPlatformData &&
            currentConversationAC.ocPlatformData.chatInfo &&
            currentConversationAC.ocPlatformData.chatInfo.currentContactId &&
            currentConversationAC.ocPlatformData.chatInfo.currentContactId !== '' &&
            currentConversationAC.ocPlatformData.chatInfo?.status !== 'Closed' && <ToolBar />}
        </View>
        {!isListView && (
          <View gridArea='widget' UNSAFE_className={widgetBackground}>
            <WidgetContainer />
          </View>
        )}
      </Grid>
    </div>
  )
}

export default AllConversationsContainer

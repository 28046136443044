/* eslint-disable no-unused-vars */
import { COLLAPSE_RU_DETAILS_HANDLER } from '../../Apps/Chat/Modules/RecomendedUpgradePanel/RUConstants'
import {
  OPEN_WIDGETS,
  UDATE_WIDGETWIDTH,
  TRANSFER_COUNT,
  SALES_CIP,
  SET_RU_WIDGET,
  SET_RU_EXPANDED,
  SET_RU_WIDGET_SHOW,
  // ,
  // SET_USER_WIDGETS
} from '../constants/widgetLayoutConstants'

const widgetIcons: any = [
  {
    title: 'PDC',
    name: 'Predefined Content',
    active: false,
    position: -1,
    width: 300
  },
  {
    title: 'CI',
    name: 'Consumer Information',
    active: false,
    position: -1,
    width: 300
  },
  {
    title: 'SC',
    name: 'Sales CIP',
    active: false,
    position: -1,
    width: 515
  },
  {
    title: 'CM',
    name: 'Case Management',
    active: false,
    position: -1,
    width: 515
  },
  {
    title: 'ECM',
    name: 'Consumer Management',
    active: false,
    position: -1,
    width: 515
  },
  {
    title: 'RU',
    name: 'Recommended Upgrades',
    active: false,
    position: -1,
    width: 515
  },
  {
    title: 'AA',
    name: 'Adobe Answers',
    active: false,
    position: -1,
    width: 417
  },
  {
    title: 'CH',
    name: 'Conversation History',
    active: false,
    position: -1,
    width: 300
  }
]
const widgetStack: string[] = []
const widgetWrapperWidth = 1200

const transferCountStack = 0

// const userWidgets: any = [];

const widgetLayoutReducer = (
  state = {
    widgets: widgetIcons,
    widgetWrapperWidth,
    widgetStack,
    transferCount: transferCountStack,
    ruWidgetBottom: null,
    ruWidgetBottomShow: {},
    ruExpanded: false,
    collapseRUDetailsFromOAC: 'none'
  },
  action: any
) => {
  console.log('activeWidget from reducer', action, state)
  const widgetsCopy = [...state.widgets]
  switch (action.type) {
    case OPEN_WIDGETS:
      return { ...state, widgets: action.payload }
    case UDATE_WIDGETWIDTH:
      return { ...state, widgetWrapperWidth: action.payload }
    case TRANSFER_COUNT:
      return { ...state, transferCount: action.payload }
    case SALES_CIP:
      widgetsCopy.forEach(widget => {
        console.log('widget', widget)
        if(widget.title === 'RU'){
          widget.width = action.payload || 515
        }
        return widget
      })
      return {...state, widgets: widgetsCopy}
    case SET_RU_WIDGET:
    return {...state, ruWidgetBottom: action.payload}
    case SET_RU_WIDGET_SHOW:
      const ruWidgetBottomShowCopy = {...state.ruWidgetBottomShow}
      const {convId, ruWidgetBottomShow } = action.payload
      ruWidgetBottomShowCopy[convId] = ruWidgetBottomShow
      return {...state, ruWidgetBottomShow: ruWidgetBottomShowCopy}
    case SET_RU_EXPANDED:
    return {...state, ruExpanded: action.payload}
    case COLLAPSE_RU_DETAILS_HANDLER: 
    return {...state, collapseRUDetailsFromOAC: action.payload}
    // case SET_USER_WIDGETS:
    //     const queue = action.payload.ocPlatformData.chatInfo.queueName
    //     let temp: any = [];
    //     const element = queue ? queue.substring(0,4) : '';
    //     if (action.payload.ocPlatformData.chatInfo.currentContactId !=='') {
    //       if (element === 'SLS-') {
    //         temp = [...widgetIcons].filter((w: any) => (w.title === 'SC' || w.title === 'CH')).sort((a,b)=>a.title==='CH'?-1:1);
    //       } else if (element === 'ENT-') {
    //         temp = [...widgetIcons].filter((w: any) => (w.title === 'CI' || w.title === 'ECM' || w.title === 'CH')).sort((a,b)=>a.title==='CH'?-1:a.title==='CI'?1:0);
    //       } else {
    //         temp = [...widgetIcons].filter((w: any) => (w.title === 'CI' || w.title === 'CM' || w.title === 'CH')).sort((a,b)=>a.title==='CH'?-1:a.title==='CI'?1:0);
    //       }
    //     }
    //     console.log("widgetSort: ",temp)
    //     return { ...state, userWidgets: temp };
    default:
      return state
  }
}

export default widgetLayoutReducer

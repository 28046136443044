/* eslint-disable no-case-declarations */
/* eslint-disable valid-typeof */
/* esling-disable no-case-declarations */
import { CHANGE_MODE, CHANGE_SOUND, SET_AGENT_DETAILS, SET_PREFERENCES, CHANGE_LANGUAGE,SHOW_LOADER_ON_OAC, UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS, CHANGE_VPN_STATUS, UPDATE_LAST_AGENT_STATUS_ON_VPN, SET_RELEASE_NOTES, RINGER_DEVICE_DETAILS } from '../constants/preferencesConstants'

export interface State {
  darkMode: boolean
  language: string
  sound: boolean,
  showLoaderOnOAC : boolean,
  isVpnConnected: boolean,
  lastAgentStatusWhenVpnIsOn: string,
  isAnyActiveContactAvailable: {
    voice: boolean,
    chat: boolean
  },
  agentDetails: {
    agentName: string
    routingProfile: string
    ldap: string
    email: string
    skills: string[]
    states: string[]
    quickConnects: []
  },
  releaseNotes: any,
  ringerDeviceDetails: {
    ringerDevice: string,
    ringerDevices: any[]
  }
}

let user: any
const settingLocalKey = 'settings'

function getDefaultSettings(option: string) {
  if (settingLocalKey) {
    user = JSON.parse(localStorage.getItem(settingLocalKey))
  }
  switch (option) {
    case 'darkMode':
      const browserDarkmode = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
      return user && user.settings && user.settings.darkMode != typeof undefined ? user.settings.darkMode : browserDarkmode

    case 'language':
      // let lang = user && user.settings && user.settings.language ? user.settings.language : window.navigator.language.split('-')[0]=="zh"?window.navigator.language:window.navigator.language.split('-')[0];
      let lang = 'en'
      if (user && user.settings && user.settings.language) {
        lang = user.settings.language
      } else {
        const languages = ['en', 'ja', 'fr', 'de', 'es', 'it', 'pt-BR', 'ko', 'zh-TW', 'zh-CN']
        const local = window.navigator.language.split('-')
        if (window.navigator.language === 'zh-TW') {
          lang = 'zh-TW'
        } else if (window.navigator.language === 'zh-CN') {
          lang = 'zh-CN'
        } else if (window.navigator.language === 'pt-BR') {
          lang = 'pt-BR'
        } else {
          lang = languages.indexOf(local[0]) >= 0 ? local[0] : 'en'
        }
      }

      return lang

    case 'sound':
      return user && user !== null ? (user.settings && user.settings.sound !== undefined ? user.settings.sound : true) : true
  }
}

export const preferencesReducer = (
  state: State = {
    darkMode: getDefaultSettings('darkMode'),
    language: getDefaultSettings('language'),
    sound: getDefaultSettings('sound'),
    isVpnConnected: true,
    showLoaderOnOAC : false,
    isAnyActiveContactAvailable: {
      voice: false,
      chat: false
    },
    lastAgentStatusWhenVpnIsOn: '',
    agentDetails: {
      agentName: '',
      routingProfile: '',
      ldap: '',
      email: '',
      skills: [],
      states: [],
      quickConnects: []
    },
    releaseNotes: {},
    ringerDeviceDetails: {
      ringerDevice: '',
      ringerDevices: []
    }
  },
  action: any
) => {
  const agentDetails = action.payload
  const darkMode = getDefaultSettings('darkMode')
  const sound = getDefaultSettings('sound')
  const language = getDefaultSettings('language')

  switch (action.type) {
    case CHANGE_MODE:
      user = JSON.parse(localStorage.getItem(settingLocalKey))
      if (user) {
        user.settings.darkMode = action.payload
      } else {
        user = {
          settings: { darkMode: action.payload }
        }
      }
      localStorage.setItem(settingLocalKey, JSON.stringify(user))
      return { ...state, darkMode: action.payload }

    case CHANGE_SOUND:
      user = JSON.parse(localStorage.getItem(settingLocalKey))
      if (user) {
        user.settings.sound = action.payload
      } else {
        user = {
          settings: { sound: action.payload }
        }
      }
      localStorage.setItem(settingLocalKey, JSON.stringify(user))
      return { ...state, sound: action.payload }

    case SET_AGENT_DETAILS:
      return { ...state, agentDetails: { ...state.agentDetails, ...agentDetails } }

    case SET_PREFERENCES:
      return { ...state, darkMode, sound, language }

    case CHANGE_LANGUAGE:
      user = JSON.parse(localStorage.getItem(settingLocalKey))
      if (user) {
        user.settings.language = action.payload
      } else {
        user = {
          settings: { language: action.payload }
        }
      }
      localStorage.setItem(settingLocalKey, JSON.stringify(user))
      return { ...state, language: action.payload }
    case UPDATE_ACTIVE_CONTACT_AVAIALBLE_STATUS:
      return { ...state, isAnyActiveContactAvailable: action.payload }
    case CHANGE_VPN_STATUS:
      return { ...state, isVpnConnected: action.payload }
    case UPDATE_LAST_AGENT_STATUS_ON_VPN:
      return { ...state, lastAgentStatusWhenVpnIsOn: action.payload }
    case SET_RELEASE_NOTES:
      return { ...state, releaseNotes: action.payload }
    case RINGER_DEVICE_DETAILS:
      const updatedDeviceDetails = {...state.ringerDeviceDetails, ...action.payload}
      localStorage.setItem('ringerDevice', JSON.stringify(updatedDeviceDetails))
      return { ...state, ringerDeviceDetails: updatedDeviceDetails }
    case SHOW_LOADER_ON_OAC:
      return { ...state, showLoaderOnOAC: action.payload }
    default:
      return state
  }
}

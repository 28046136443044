import React from 'react'
import './Logout.scss'
import { Flex, darkTheme, lightTheme, Provider as V3Provider, Image, Link } from '@adobe/react-spectrum'
import { useSelector } from 'react-redux'
import { Link as RouterLink } from 'react-router-dom'
import logo from '../../Theme/Styles/Icons/adobe-logo.svg'
import agent from '../../Theme/Styles/Icons/agent-console-logo.svg'
import localization from './lang/localization'

const Login = () => {
  const { darkMode: globalDarkMode, language } = useSelector((state: any) => state.preferences)
  const V3Theme = globalDarkMode ? darkTheme : lightTheme
  const lang = language || 'en'
  console.log('Theme test', globalDarkMode)
  const message = localization[lang].LOGOUT.MESSAGE
  const heading = localization[lang].LOGOUT.HEADING
  const action = localization[lang].LOGOUT.ACTION

  return (
    <V3Provider theme={V3Theme} colorScheme='light'>
      <div className='lgnpage'>
        <header>
          <div className='header'>
            <Flex height='30px'>
              <Image src={logo} alt='Adobe Inc.' />
            </Flex>
          </div>
        </header>
        <>
          <div className='login-group'>
            <body>
              <div className='image-container'>
                <Image src={agent} alt='OmniChannel Agent Console' />
              </div>
              <div className='text-container-agent'>
                <div className={`light-mode-text-color${globalDarkMode ? 'false' : ''}`}>
                  <p>{heading}</p>
                </div>
              </div>
              <div className='text-container-welcome'>
                <div className={`light-mode-welcome-text-color${globalDarkMode ? 'false' : ''}`}>
                  <p>
                    {message}
                    <Link isQuiet>
                      <RouterLink to='/'>{` ${action}`}.</RouterLink>
                    </Link>
                  </p>
                </div>
              </div>
            </body>
          </div>
        </>
      </div>
    </V3Provider>
  )
}
export default Login

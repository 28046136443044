const localization: any = {
  en: {
    LOGIN: {
      HEADING: 'OmniChannel Agent Console',
      MESSAGE: 'Welcome!',
      ACTION: 'Login'
    },
    LOGOUT: {
      HEADING: 'You have been logged out',
      MESSAGE: 'This window can now be closed.  If you wish to log in once again,',
      ACTION: 'click here'
    }
  },
  ja: {
    LOGIN: {
      HEADING: 'オムニチャネル エージェント コンソール',
      MESSAGE: 'ようこそ',
      ACTION: 'ログイン'
    },
    LOGOUT: {
      HEADING: 'ログアウトされました',
      MESSAGE: 'このウィンドウを閉じることができます。もう一度ログインする場合は、',
      ACTION: 'こちらをクリックしてください'
    }
  },
  fr: {
    LOGIN: {
      HEADING: 'Console d’agent omnicanal',
      MESSAGE: 'Bienvenue !!',
      ACTION: 'Connexion'
    },
    LOGOUT: {
      HEADING: 'Vous avez été déconnecté',
      MESSAGE: 'Cette fenêtre peut maintenant être fermée.  Si vous souhaitez vous connecter à nouveau, cliquez ici.',
      ACTION: 'click here' // Not available
    }
  },
  de: {
    LOGIN: {
      HEADING: 'OmniChannel Agenten-Konsole',
      MESSAGE: 'Willkommen!',
      ACTION: 'Anmelden'
    },
    LOGOUT: {
      HEADING: 'Sie wurden abgemeldet',
      MESSAGE: 'Dieses Fenster kann jetzt geschlossen werden.  Wenn Sie sich erneut anmelden möchten, klicken Sie hier.',
      ACTION: 'click here' // Not available
    }
  },
  es: {
    LOGIN: {
      HEADING: 'Consola de agente OmniChannel',
      MESSAGE: '¡Bienvenido!',
      ACTION: 'Iniciar sesión'
    },
    LOGOUT: {
      HEADING: 'Se ha cerrado la sesión',
      MESSAGE: 'Ya se puede cerrar esta ventana.  Si desea volver a conectarse, haga clic aquí.',
      ACTION: 'click here' // Not available
    }
  },
  it: {
    LOGIN: {
      HEADING: 'Console agente OmniChannel',
      MESSAGE: 'Benvenuti!',
      ACTION: 'Accedi'
    },
    LOGOUT: {
      HEADING: 'Sei stato disconnesso',
      MESSAGE: "Ora puoi chiudere questa finestra.  Se desideri effettuare nuovamente l'accesso, fai clic qui.",
      ACTION: 'click here' // Not available
    }
  },
  'pt-BR': {
    LOGIN: {
      HEADING: 'Console de Agente Omnicanal',
      MESSAGE: 'Boas-vindas!',
      ACTION: 'Entrar'
    },
    LOGOUT: {
      HEADING: 'Você foi desconectado',
      MESSAGE: 'Esta janela agora pode ser fechada.  Se desejar fazer logon novamente, clique aqui.',
      ACTION: 'click here' // Not available
    }
  },
  ko: {
    LOGIN: {
      HEADING: '옴니채널 상담사 콘솔',
      MESSAGE: '환영합니다!',
      ACTION: '로그인'
    },
    LOGOUT: {
      HEADING: '로그아웃되었습니다',
      MESSAGE: '이제 이 창을 닫을 수 있습니다.  다시 로그인하려면 여기를 클릭하십시오.',
      ACTION: 'click here' // Not available
    }
  },
  'zh-TW': {
    LOGIN: {
      HEADING: 'OmniChannel Agent Console', // Not available.
      MESSAGE: '歡迎！',
      ACTION: '登入'
    },
    LOGOUT: {
      HEADING: '您已登出',
      MESSAGE: '現在可以關閉此視窗。如果您想再次登入，請按一下此處。',
      ACTION: 'click here' // Not available
    }
  },
  'zh-CN': {
    LOGIN: {
      HEADING: 'OmniChannel Agent Console', // Not available
      MESSAGE: '欢迎！',
      ACTION: '登录'
    },
    LOGOUT: {
      HEADING: '您已注销',
      MESSAGE: '现可关闭此窗口。如果您想再次登录，请单击此处。',
      ACTION: 'click here' // Not available
    }
  }
}
export default localization

import React, { useEffect } from 'react'
import Container from './Components/Container'
import { sendChatPageRenderAnalytics } from '../../helpers/analytics-helpers'
// import { useLocation } from 'react-router-dom';
// import { useSelector, useDispatch } from "react-redux";
// import { triggerNavBar } from '../../Store/actions/navBarActions';
// interface State {
//   navBar: {
//     navBarToggles: { chatToggle: Boolean, historyToggle: Boolean }
//   };
// }
const Chat = () => {
  // let location = useLocation()
  // const dispatch = useDispatch();
  // const { navBarToggles } = useSelector(
  //   (state: State) => state.navBar
  // );
  // React.useEffect(
  //   () => {

  //      console.log(location.pathname);
  //      console.log(navBarToggles);

  //   },
  //   [location]
  // )

  useEffect(() => {
    sendChatPageRenderAnalytics({
      subType: 'agent-console',
      type: 'render'
    })
  }, [])
  return <Container />
}

export default Chat

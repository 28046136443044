/* eslint-disable  no-use-before-define */
import React, { useState, useEffect } from 'react'
import { useOktaAuth } from '@okta/okta-react'
import { Header, Icon, Table } from 'semantic-ui-react'

const Profile = () => {
  const { authState, oktaAuth } = useOktaAuth()
  const [userInfo, setUserInfo] = useState(null)

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      // When user isn't authenticated, forget any user info
      setUserInfo(null)
    } else {
      oktaAuth.getUser().then((info) => {
        setUserInfo(info)
        console.log(info)
      })
    }
  }, [authState, oktaAuth]) // Update if authState changes

  if (!userInfo) {
    return (
      <div>
        <p>Fetching user profile...</p>
      </div>
    )
  }

  return (
    <div>
      <div>
        <Header as='h1'>
          <Icon name='profile' /> User Profile{' '}
        </Header>
        <Table>
          <thead>
            <tr>
              <th>Claim</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(userInfo).map((claimEntry) => {
              const claimName = claimEntry[0]
              const claimValue = claimEntry[1]
              const claimId = `claim-${claimName}`
              return (
                <tr key={claimName}>
                  <td>{claimName}</td>
                  <td id={claimId}>{claimValue.toString()}</td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </div>
    </div>
  )
}

export default Profile
